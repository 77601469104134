import React, {PropsWithChildren} from 'react';
import {useMediaQuery} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {DndProvider} from 'react-dnd';
import {TouchBackend} from 'react-dnd-touch-backend';

export interface DragItem {
    index: number;
    id: string;
    type: string;
}

/**
 * QualitizeDnDProvider is a functional component in the React application
 * that wraps the children components with the drag and drop provider.
 * It determines the backend based on the screen width using the useMediaQuery hook and determines whether to use
 * the touch backend (mobile devices) or the HTML5 backend.
 *
 * @param children - The children rendered within the drag n drop provider.
 * @returns The wrapped children components with the drag and drop provider.
 */
const QualitizeDnDProvider: React.FC<PropsWithChildren> = ({children}) => {

    const isWidthDownSM = useMediaQuery<Theme>((theme) => theme.breakpoints.down('lg'));

    return (
        <DndProvider backend={isWidthDownSM ? TouchBackend : HTML5Backend}>
            {children}
        </DndProvider>
    );
}

export default QualitizeDnDProvider;