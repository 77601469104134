import { FetchStatus } from '@software/reactcommons';
import {PaginationState} from '../../../types/Types';
import {LoginUser} from '../user/types';



export enum AllowedVideoType {
    MP4 = 'video/mp4',
    QUICKTIME = 'video/quicktime'
}

export enum AllowPlaylistImageType {
    JPG = 'image/jpg',
    JPEG = 'image/jpeg',
    PNG = 'image/png',
}


/***************************************************************
 * Video service responses
 **************************************************************/

export interface VideoCategoryResponse {
    categories: {
        category: VideoCategory;
        count: number;
    }[];
    totalCount: number;
}

export interface DashboardResponse {
    videos: Video[];
    totalCount: number;
}

export interface VideoHighlight {
    creator: VideoUserDTO;
    video: Video;
    createdTimestamp: number;
    deletedBy?: VideoUserDTO;
    deletedTimestamp?: number;
}

export interface AdminAreaVideo {
    id: number;
    videoID: string;
    author: string;
    creator: string;
    title: string;
    views: number;
    enabled: boolean;
    highlighted: boolean;
    reactionsPerType: Record<string, VideoReaction>;
    createdTimestamp: number;
    updatedTimestamp: number;
    previewImagePaths: PreviewImagePath[];
    numberOfComments: number;
}

export interface VideoComment {
    id: number;
    videoID: string;
    commenter: VideoUserDTO;
    comment: string;
    createdTimestamp: number;
    parentID?: number;
    deletedBy?: VideoUserDTO;
    deletedTimestamp?: number;
    children?: VideoComment[];
    deleteFetchStatus: FetchStatus;
}

export interface VideoCreatedComment {
    parentID?: number;
    comment: string;
    deletedTimestamp?: number;
}

export interface VideoTagsResponse {
    tagsWithCounts: VideoTagResponse[];
    totalCount: number;
}


export interface VideoTagResponse {
    id?: number;
    tag: VideoTag;
    count: number;
}


export interface VideoCategory {
    id: number;
    name: string;
    numberOfVideos: number;
    count: number;
}

export interface VideoTagType {
    id: number;
    name: string;
}

export interface VideoTag {
    id: number;
    name: string;
    type?: VideoTagType;
    count: number;
}

export interface VideoCreatedTag {
    id: number | string;
    name: string;
}

export interface VideoPath {
    path: string;
    resolutionWidth?: number;
    originalUpload: boolean;
}


export interface PreviewImagePath {
    resolutionWidth?: number;
    path: string;
}

export interface VideoWishVote {
    id: number;
    wishID: number;
    voter: VideoUserDTO;
    createdTimestamp: number;
    deletedTimestamp?: number;
}

export interface VideoWish {
    id: number;
    title: string;
    description: string;
    creator: VideoUserDTO;
    createdTimestamp: number;
    enabled: boolean;
    deletedBy?: VideoUserDTO;
    deletedTimestamp?: number;
    videos: Video[];
    votes: VideoWishVote[];
    upvoteFetchStatus: FetchStatus;
}

export interface Video {
    id: number;
    videoID: string;
    title: string;
    description: string;
    author: string;
    store: string;
    creator: LoginUser;
    creatorID: number;
    videoPaths?: VideoPath[];
    previewImagePaths: PreviewImagePath[];
    lengthMillis: number;
    views: number;
    enabled: boolean;
    categories: VideoCategory[];
    tags: VideoTag[];
    createdTimestamp: number;
    updatedTimestamp: number;
    edit?: boolean;
    reactionsPerType: Record<string, VideoReaction>;
    numberOfComments: number;
    comments?: Record<string, VideoComment>;
    latestCreatedComment?: number;
    enabledFetchStatus: FetchStatus;
    deleteFetchStatus: FetchStatus;
    commentFetchStatus: FetchStatus;
    submitCommentFetchStatus: FetchStatus;
    highlightFetchStatus: FetchStatus;
    highlighted: boolean;
}

export interface UploadFile {
    fileName: string;
    path: string;
}

export interface UploadVideo {
    title: string;
    author: string;
    description: string;
    categories: VideoCategory[];
    tags: VideoTag[];
    createdTags: VideoCreatedTag[];
    selectedTags: (number | string)[];
    selectedTagsByTagType: Record<string, VideoTag[]>;
    fetchStatus: number;
    progress: number;
    previewImagePath?: UploadFile;
    previewVideoPath?: UploadFile;
    saveFetchStatus: FetchStatus;
    videoID?: string;
    wishIds: number[];
}

export interface EditedVideo {
    title: string;
    author: string;
    description: string;
    categories: VideoCategory[];
    tags: VideoTag[];
    createdTags: VideoCreatedTag[];
    selectedTags: (number | string)[];
    fetchStatus: number;
    id: number;
}

export enum VideoReactionType {
    HEART = 'Heart',
    SMILEY = 'Smiley',
    LIKE = 'Like',
    CLAP = 'Clap'
}

export interface Reaction {
    id: number;
    name: VideoReactionType;
}

export interface VideoUserDTO {
    id: number;
    firstName: string;
    lastName: string;
}

export interface VideoReaction {
    id: number;
    reactionType: Reaction;
    count: number;
    fetchStatus?: FetchStatus;
    users: VideoUserDTO[];
}

export interface VideoUploadResponse {
    videoPath: UploadFile;
    previewImagePath: UploadFile;
}

export interface VideoStatisticResponse {
    videos: AdminAreaVideo[];
    totalCount: number;
    lastID: number;
    totalViews: number;
}

export interface LoadVideoWishesResponse {
    all: VideoWish[];
    newestWishes: number[];
    popularWishIds: number[];
    finishedWishIds: number[];
}


export interface SearchResponse {
    videos: Video[];
    videoCount: number;
}

export interface VideoPlaylistSectionVideo {
    id?: number;
    videoId: string;
    title: string;
    position: number;
    lengthMillis: number;
    creator: LoginUser;
    createdTimestamp: number;
}

export interface VideoPlaylistSection {
    id: number;
    title?: string;
    position: number;
    creator?: LoginUser;
    createdTimestamp?: number;
    videos: VideoPlaylistSectionVideo[];
}

export interface VideoPlaylistImagePathPath {
    path: string;
    fileName?: string;
    resolutionWidth: number;
}

export interface VideoPlaylist {
    id: number;
    playlistId: string;
    title: string;
    subTitle: string;
    description: string;
    creator?: LoginUser;
    createdTimestamp?: number;
    updatedTimestamp?: number;
    enabled: boolean;
    sections: VideoPlaylistSection[];
    previewImages: VideoPlaylistImagePathPath[];
    tags: VideoTag[];
    views: number;
    uploadImageFetchStatus?: FetchStatus;
    uploadImageProgress?: number;
    saveFetchStatus?: FetchStatus;
    activeSection?: number;
    activeVideo?: string;
    highlighted: boolean;
    highlightFetchStatus?: FetchStatus;
    deleteFetchStatus?: FetchStatus;
    enableFetchStatus?: FetchStatus;
}


/***************************************************************
 * Base video redux state
 **************************************************************/

export interface VideoState {
    videos: {
        fetchStatus: FetchStatus;
        dashboardFetchStatus: FetchStatus;
        elements: Record<string, Video | undefined>;
        count: number;
    };
    // Map, containing the id of the category as key and an array of video ids as value
    latest: {
        fetchStatus: FetchStatus;
        videos: string[];
    };
    // Map, containing the id of the category as key and an array of video ids as value
    mostViewed: {
        fetchStatus: FetchStatus;
        videos: string[];
    };
    popular: {
        fetchStatus: FetchStatus;
        videos: string[];
    };
    highlights: {
        fetchStatus: FetchStatus;
        videos: string[];
        activeItem: number;
    };
    categories: {
        fetchStatus: FetchStatus;
        elements: Record<string, VideoCategory>;
    };
    tags: {
        fetchStatus: FetchStatus;
        elements: Record<string, VideoTag | undefined>;
    };
    tagTypes: {
        fetchStatus: FetchStatus;
        elements: Record<string, VideoTagType>;
    };
    tagsByCategories: Record<string, VideoTagResponse[]>;
    selectedTagsByCategory: Record<string, number[]>;
    selectedCategory: number;
    search: {
        text: string;
        elements: string[];
        fetchStatus: number;
        expandBar: boolean;
        showSearch: boolean;
        selectedTags: number[];
        textSearch: boolean;
        count: number;
    };
    videosByCategory: Record<string, string[] | undefined>;
    upload: UploadVideo;
    tagsByTagType: Record<string, number[]>;
    statistics: {
        videos: PaginationState<AdminAreaVideo>;
        totalViews: number;
        fetchStatus: {
            deleteFetchStatus: FetchStatus;
            enabledFetchStatus: FetchStatus;
            highlightFetchStatus: FetchStatus;
        }
    };
    wishes: {
        fetchStatus: FetchStatus;
        createFetchStatus: FetchStatus;
        elements: Record<string, VideoWish | undefined>;
        latest: number[];
        popular: number[];
        finished: number[];
    };
    playlists: {
        fetchStatus: FetchStatus;
        createFetchStatus: FetchStatus;
        highlightFetchStatus: FetchStatus;
        elements: Record<string, VideoPlaylist>;
        originalElements: Record<string, VideoPlaylist>;
        highlights: string[];
        count: number;
    }
}