import {
    CompiledMessage,
    createFetchAction,
    getFilenameFromResponseHeaders,
    getRequest,
    LocalizedCompiledMessage,
    MessageType,
    postRequest,
    putRequest,
    SagaHandling
} from '@software/reactcommons';
import {
    ConfigurationLandingPage,
    LandingPageCdnFileTypeSpecificInformation,
    LandingPageColor,
    LandingPageColorType,
    LandingPageConfig,
    LandingPageExplicitLink,
    LandingPageFont,
    LandingPageFontFamilyType,
    LandingPageLayoutBackgroundColor,
    LandingPageLayoutBackgroundType,
    LandingPageLayoutConfig,
    LandingPageLinkType,
    LandingPagePresetFontFamily,
    LandingPageQRCodeFileType,
    LandingPageRedirectConfig,
    LandingPageReleaseState
} from './types';
import {createAuthenticatedSagaFetchAction, SecurityErrorCode} from '@software/reactcommons-security';
import {Route} from '../../../api/Api';
import {IdParams, OpaqueIdProps, ServiceSearchResponse} from '../../../types/Types';
import {put, PutEffect} from 'redux-saga/effects';
import {
    setCloneLandingPageFetchStatusSuccess,
    setDownloadQRCodeFetchStatusSuccess,
    setLandingPageLayoutDisplayPreview
} from './landingPages';
import {saveAs} from 'file-saver';
import {LandingPage, LandingPageConfigType} from '@software/landingpageui';

export const DefaultLandingPageLayoutConfig: LandingPageLayoutConfig = {
    type: LandingPageConfigType.Layout,
    background: {
        type: LandingPageLayoutBackgroundType.BackgroundColor,
        color: {
            type: LandingPageColorType.Custom,
            hexColor: '#FFFFFF'
        } as LandingPageColor
    } as LandingPageLayoutBackgroundColor,
    rows: [],
    primaryColor: '#16405c',
    secondaryColor: '#FFFFFF',
    primaryContrastColor: '#FFFFFF',
    secondaryContrastColor: '#16405c',
    contentTextColor: '#000000',
    displayLanguageSelectionIfPossible: false,
    fontFamily: {
        type: LandingPageFontFamilyType.Preset,
        presetFontFamily: LandingPageFont.Barlow
    } as LandingPagePresetFontFamily,
    locales: []
}

export const getDefaultLandingPageRedirectConfig = (locale: string): LandingPageRedirectConfig => ({
    type: LandingPageConfigType.Redirect,
    link: {
        type: LandingPageLinkType.Explicit,
        uri: {
            type: MessageType.LocalizedCompiledMessage,
            localizations: [{
                locale,
                text: ''
            }]
        } as LocalizedCompiledMessage
    } as LandingPageExplicitLink
});

export interface LoadLandingPagesPayload {
    limit?: number;
    offset?: number;
}

export const LoadLandingPagesActionAndSaga = createAuthenticatedSagaFetchAction<LoadLandingPagesPayload, ServiceSearchResponse<ConfigurationLandingPage>, SecurityErrorCode>({
    actionGroup: 'landingPages',
    actionName: 'loadLandingPages',
    networkCall: (jwt, selectedLanguage) => {
        return postRequest(Route.LandingPage.PostLoad, {jwt, selectedLanguage});
    }
});

export const LoadLandingPageActionAndSaga = createAuthenticatedSagaFetchAction<{
    opaqueId: string;
}, ConfigurationLandingPage, SecurityErrorCode>({
    actionGroup: 'landingPages',
    actionName: 'loadLandingPage',
    networkCall: (jwt, selectedLanguage, requestBody) => {
        return getRequest(Route.LandingPage.GetLoadSingleLandingPage(requestBody!.opaqueId), {jwt, selectedLanguage});
    }
});

export interface CreateLandingPageDTO {
    hierarchyNode: number;
    name: CompiledMessage;
    description: CompiledMessage;
    displayText: CompiledMessage;
    config: LandingPageConfig;
}

export const CreateLandingPageActionAndSaga = createAuthenticatedSagaFetchAction<CreateLandingPageDTO, ConfigurationLandingPage, SecurityErrorCode>({
    actionGroup: 'landingPages',
    actionName: 'createLandingPage',
    networkCall: (jwt, selectedLanguage, requestBody) => {
        return postRequest(Route.LandingPage.PostCreate, {
            jwt, selectedLanguage,
            body: {
                ...requestBody,
                // Users cannot create QR Codes for testing in their login
                forTesting: false
            }
        });
    }
});

export interface UpdateLandingPageDTO extends CreateLandingPageDTO {
    opaqueId: string;
    state: LandingPageReleaseState;
}

export const UpdateLandingPageActionAndSaga = createAuthenticatedSagaFetchAction<UpdateLandingPageDTO, ConfigurationLandingPage, SecurityErrorCode>({
    actionGroup: 'landingPages',
    actionName: 'updateLandingPage',
    networkCall: (jwt, selectedLanguage, requestBody) => {
        return putRequest(Route.LandingPage.PutUpdate(requestBody!.opaqueId), {
            jwt, selectedLanguage,
            body: {
                hierarchyNode: requestBody!.hierarchyNode,
                name: requestBody!.name,
                description: requestBody!.description,
                displayText: requestBody!.displayText,
                config: requestBody!.config,
                state: requestBody!.state,
                // Users cannot create QR Codes for testing in their login
                forTesting: false
            }
        });
    }
});

export interface DownloadLandingPageQRCodePayload {
    opaqueIds: string[];
    includeDisplayText: boolean;
    fileTypes: LandingPageQRCodeFileType[];
    locales: string[];
    color?: string;
}

export const DownloadLandingPageQRCodeActionAndSaga = createAuthenticatedSagaFetchAction<DownloadLandingPageQRCodePayload, File, SecurityErrorCode>({
    actionGroup: 'landingPages',
    actionName: 'downloadLandingPageQRCode',
    networkCall: (jwt, selectedLanguage, requestBody) => {
        return postRequest(Route.LandingPage.PostDownloadQRCodes, {
            jwt, selectedLanguage,
            body: requestBody
        });
    },
    successGenerator: [function* (request, response, responseHeaders): Generator<PutEffect, any, any> {
        yield put(setDownloadQRCodeFetchStatusSuccess(request.opaqueIds));
        // Use file save to download the response
        saveAs(response, getFilenameFromResponseHeaders(responseHeaders));
    }],
    timeoutMillis: 600000
});

export interface LoadLandingPageCdnFilesPayload {
    offset?: number;
    limit: number;
    // comma separated string of file extensions
    fileExtensions?: string[];
    ids?: number[];
}

export interface LandingPageCdnFileDTO<Type extends LandingPageCdnFileTypeSpecificInformation = LandingPageCdnFileTypeSpecificInformation> {
    id: number;
    createdTimestamp: string;
    modifiedTimestamp: string;
    fileName: string;
    clientFileName: string;
    description: CompiledMessage;
    typeSpecificInformation: Type;
}

export const LoadLandingPageCdnFilesActionAndSaga = createAuthenticatedSagaFetchAction<LoadLandingPageCdnFilesPayload, ServiceSearchResponse<LandingPageCdnFileDTO>, SecurityErrorCode>({
    actionGroup: 'landingPages',
    actionName: 'loadLandingPageCdnFiles',
    networkCall: (jwt, selectedLanguage, body) => {
        return postRequest(Route.LandingPage.PostCdnFiles, {
            jwt, selectedLanguage, body
        });
    }
});

export interface UploadLandingPageFilesPayload {
    files: File[];
}

export const uploadLandingPageFilesAction = createFetchAction<UploadLandingPageFilesPayload, LandingPageCdnFileDTO, never>('landingPages', 'uploadLandingPageFiles');


export const LoadLandingPageCdnFileActionAndSaga = createAuthenticatedSagaFetchAction<IdParams, ServiceSearchResponse<LandingPageCdnFileDTO>, SecurityErrorCode>({
    actionGroup: 'landingPages',
    actionName: 'loadLandingPageCdnFile',
    networkCall: (jwt, selectedLanguage, params) => {
        return getRequest(Route.LandingPage.GetCdnFile(params!.id), {
            jwt, selectedLanguage
        });
    }
});

export interface LoadLandingPagePreviewPayload {
    opaqueId: string;
    config: LandingPageConfig;
}

export const LoadLandingPagePreviewActionAndSaga = createAuthenticatedSagaFetchAction<LoadLandingPagePreviewPayload, LandingPage, SecurityErrorCode>({
    actionGroup: 'landingPages',
    actionName: 'loadLandingPagePreview',
    networkCall: (jwt, selectedLanguage, requestBody) => {
        return postRequest(Route.LandingPage.PostLandingPagePreview(requestBody!.opaqueId), {
            jwt, selectedLanguage,
            body: requestBody?.config
        });
    },
    successGenerator: [function* (request, response): Generator<any, any, any> {
        yield put(setLandingPageLayoutDisplayPreview({
            landingPage: response,
            opaqueId: request.opaqueId
        }));
    }],
    debounceDelay: 500
});

export const CloneLandingPageActionAndSaga = createAuthenticatedSagaFetchAction<OpaqueIdProps, ConfigurationLandingPage, SecurityErrorCode>({
    actionGroup: 'landingPages',
    actionName: 'cloneLandingPage',
    networkCall: (jwt, selectedLanguage, body) => {
        return postRequest(Route.LandingPage.PostCloneLandingPage(body!.opaqueId), {
            jwt, selectedLanguage
        });
    },
    successGenerator: [function* (request): Generator<any, any, any> {
        yield put(setCloneLandingPageFetchStatusSuccess([request.opaqueId]));
    }],
    sagaHandling: SagaHandling.Every
});