import BarlowThinTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-100.ttf';
import BarlowThinWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-100.woff2';
import BarlowThinWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-100.woff';
import BarlowThinItalicTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-100italic.ttf';
import BarlowThinItalicWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-100italic.woff2';
import BarlowThinItalicWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-100italic.woff';
import BarlowExtraLightTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-200.ttf';
import BarlowExtraLightWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-200.woff2';
import BarlowExtraLightWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-200.woff';
import BarlowExtraLightItalicTTG from '../../fonts/Barlow/barlow-v11-latin-ext_latin-200italic.ttf';
import BarlowExtraLightItalicWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-200italic.woff2';
import BarlowExtraLightItalicWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-200italic.woff';
import BarlowLightTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-300.ttf';
import BarlowLightWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-300.woff2';
import BarlowLightWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-300.woff';
import BarlowLightItalicTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-300italic.ttf';
import BarlowLightItalicWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-300italic.woff2';
import BarlowLightItalicWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-300italic.woff';
import BarlowTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-regular.ttf';
import BarlowWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-regular.woff2';
import BarlowWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-regular.woff';
import BarlowItalicTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-italic.ttf';
import BarlowItalicWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-italic.woff2';
import BarlowItalicWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-italic.woff';
import BarlowMediumTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-500.ttf';
import BarlowMediumWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-500.woff2';
import BarlowMediumWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-500.woff';
import BarlowMediumItalicTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-500italic.ttf';
import BarlowMediumItalicWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-500italic.woff2';
import BarlowMediumItalicWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-500italic.woff';
import BarlowSemiBoldTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-600.ttf';
import BarlowSemiBoldWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-600.woff2';
import BarlowSemiBoldWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-600.woff';
import BarlowSemiBoldItalicTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-600italic.ttf';
import BarlowSemiBoldItalicWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-600italic.woff2';
import BarlowSemiBoldItalicWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-600italic.woff';
import BarlowBoldTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-700.ttf';
import BarlowBoldWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-700.woff2';
import BarlowBoldWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-700.woff';
import BarlowBoldItalicTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-700italic.ttf';
import BarlowBoldItalicWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-700italic.woff2';
import BarlowBoldItalicWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-700italic.woff';
import BarlowExtraBoldTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-800.ttf';
import BarlowExtraBoldWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-800.woff2';
import BarlowExtraBoldWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-800.woff';
import BarlowExtraBoldItalicTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-800italic.ttf';
import BarlowExtraBoldItalicWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-800italic.woff2';
import BarlowExtraBoldItalicWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-800italic.woff';
import BarlowBlackTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-900.ttf';
import BarlowBlackWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-900.woff2';
import BarlowBlackWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-900.woff';
import BarlowBlackItalicTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-900italic.ttf';
import BarlowBlackItalicWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-900italic.woff2';
import BarlowBlackItalicWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-900italic.woff';
import BarlowCondensedThinTTF from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-100.ttf';
import BarlowCondensedThinWoff2 from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-100.woff2';
import BarlowCondensedThinWoff from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-100.woff';
import BarlowCondensedThinItalicTTF
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-100italic.ttf';
import BarlowCondensedThinItalicWoff2
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-100italic.woff2';
import BarlowCondensedThinItalicWoff
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-100italic.woff';
import BarlowCondensedExtraLightTTF from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-200.ttf';
import BarlowCondensedExtraLightWoff2 from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-200.woff2';
import BarlowCondensedExtraLightWoff from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-200.woff';
import BarlowCondensedExtraLightItalicTTG
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-200italic.ttf';
import BarlowCondensedExtraLightItalicWoff2
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-200italic.woff2';
import BarlowCondensedExtraLightItalicWoff
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-200italic.woff';
import BarlowCondensedLightTTF from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-300.ttf';
import BarlowCondensedLightWoff2 from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-300.woff2';
import BarlowCondensedLightWoff from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-300.woff';
import BarlowCondensedLightItalicTTF
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-300italic.ttf';
import BarlowCondensedLightItalicWoff2
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-300italic.woff2';
import BarlowCondensedLightItalicWoff
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-300italic.woff';
import BarlowCondensedTTF from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-regular.ttf';
import BarlowCondensedWoff2 from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-regular.woff2';
import BarlowCondensedWoff from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-regular.woff';
import BarlowCondensedItalicTTF from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-italic.ttf';
import BarlowCondensedItalicWoff2 from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-italic.woff2';
import BarlowCondensedItalicWoff from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-italic.woff';
import BarlowCondensedMediumTTF from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-500.ttf';
import BarlowCondensedMediumWoff2 from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-500.woff2';
import BarlowCondensedMediumWoff from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-500.woff';
import BarlowCondensedMediumItalicTTF
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-500italic.ttf';
import BarlowCondensedMediumItalicWoff2
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-500italic.woff2';
import BarlowCondensedMediumItalicWoff
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-500italic.woff';
import BarlowCondensedSemiBoldTTF from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-600.ttf';
import BarlowCondensedSemiBoldWoff2 from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-600.woff2';
import BarlowCondensedSemiBoldWoff from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-600.woff';
import BarlowCondensedSemiBoldItalicTTF
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-600italic.ttf';
import BarlowCondensedSemiBoldItalicWoff2
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-600italic.woff2';
import BarlowCondensedSemiBoldItalicWoff
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-600italic.woff';
import BarlowCondensedBoldTTF from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-700.ttf';
import BarlowCondensedBoldWoff2 from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-700.woff2';
import BarlowCondensedBoldWoff from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-700.woff';
import BarlowCondensedBoldItalicTTF
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-700italic.ttf';
import BarlowCondensedBoldItalicWoff2
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-700italic.woff2';
import BarlowCondensedBoldItalicWoff
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-700italic.woff';
import BarlowCondensedExtraBoldTTF from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-800.ttf';
import BarlowCondensedExtraBoldWoff2 from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-800.woff2';
import BarlowCondensedExtraBoldWoff from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-800.woff';
import BarlowCondensedExtraBoldItalicTTF
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-800italic.ttf';
import BarlowCondensedExtraBoldItalicWoff2
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-800italic.woff2';
import BarlowCondensedExtraBoldItalicWoff
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-800italic.woff';
import BarlowCondensedBlackTTF from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-900.ttf';
import BarlowCondensedBlackWoff2 from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-900.woff2';
import BarlowCondensedBlackWoff from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-900.woff';
import BarlowCondensedBlackItalicTTF
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-900italic.ttf';
import BarlowCondensedBlackItalicWoff2
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-900italic.woff2';
import BarlowCondensedBlackItalicWoff
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-900italic.woff';
import MyWritingTTF from '../../fonts/MyWriting/Mywriting.ttf';
import MyWritingWoff from '../../fonts/MyWriting/Mywriting.woff';
import MyWritingWoff2 from '../../fonts/MyWriting/Mywriting.woff2';
import {theme as qualitizeTheme} from '@software/reactcommons';
import {createTheme} from '@mui/material';

/************************************************************************************************
 * Create the Qualitize material ui theme. Defines the main colors and typography of the app.
 *************************************************************************************************/

function createOnlineUiTheme() {
    return createTheme({
        ...qualitizeTheme,
        components: {
            MuiCssBaseline: {
                styleOverrides: `
                        @font-face {                 
                            font-family: 'Barlow';
                            font-style: normal;
                            font-weight: 100;
                            font-display: swap;
                            src: local('Barlow Thin'), url(${BarlowThinWoff2}) format('woff2'), url(${BarlowThinWoff}) format('woff'), url(${BarlowThinTTF}) format('ttf');
                        }
                        
                        @font-face {
                            font-family: 'Barlow',
                            font-style: italic,
                            font-weight: 100,
                            font-display: swap,
                            src: local('Barlow Thin Italic'), url(${BarlowThinItalicWoff2}) format('woff2'), url(${BarlowThinItalicWoff}) format('woff'), url(${BarlowThinItalicTTF}) format('ttf');
                        }
                        
                        @font-face {                   
                            font-family: 'Barlow';
                            font-style: normal;
                            font-weight: 200;
                            font-display: swap;
                            src: local('Barlow Extra Light'), url(${BarlowExtraLightWoff2}) format('woff2'), url(${BarlowExtraLightWoff}) format('woff'), url(${BarlowExtraLightTTF}) format('ttf');
                        }
                        @font-face {                            
                            font-family: 'Barlow';
                            font-style: italic;
                            font-weight: 200;
                            font-display: swap;
                            src: local('Barlow Extra Light Italic'), url(${BarlowExtraLightItalicWoff2}) format('woff2'), url(${BarlowExtraLightItalicWoff}) format('woff'), url(${BarlowExtraLightItalicTTG}) format('ttf');
                        }
                        
                        @font-face {
                            font-family: 'Barlow';
                            font-style: normal;
                            font-weight: 300;
                            font-display: swap;
                            src: local('Barlow Light'), url(${BarlowLightWoff2}) format('woff2'), url(${BarlowLightWoff}) format('woff'), url(${BarlowLightTTF}) format('ttf');
                        }
                        @font-face {
                            font-family: 'Barlow';
                            font-style: italic;
                            font-weight: 300;
                            font-display: swap;
                            src: local('Barlow Light Italic'), url(${BarlowLightItalicWoff2}) format('woff2'), url(${BarlowLightItalicWoff}) format('woff'), url(${BarlowLightItalicTTF}) format('ttf');
                        }
                        @font-face {               
                            font-family: 'Barlow';
                            font-style: normal;
                            font-weight: 400;
                            font-display: swap;
                            src: local('Barlow'), url(${BarlowWoff2}) format('woff2'), url(${BarlowWoff}) format('woff'), url(${BarlowTTF}) format('ttf');
                        }
                        @font-face {
                            font-family: 'Barlow';
                            font-style: italic;
                            font-weight: 400;
                            font-display: swap;
                            src: local('Barlow Italic'), url(${BarlowItalicWoff2}) format('woff2'), url(${BarlowItalicWoff}) format('woff'), url(${BarlowItalicTTF}) format('ttf');
                        }
                        @font-face {
                            font-family: 'Barlow';
                            font-style: normal;
                            font-weight: 500;
                            font-display: swap;
                            src: local('Barlow Medium'), url(${BarlowMediumWoff2}) format('woff2'), url(${BarlowMediumWoff}) format('woff'), url(${BarlowMediumTTF}) format('ttf');
                        }
                        @font-face {
                            font-family: 'Barlow';
                            font-style: italic;
                            font-weight: 500;
                            font-display: swap;
                            src: local('Barlow Medium Italic'), url(${BarlowMediumItalicWoff2}) format('woff2'), url(${BarlowMediumItalicWoff}) format('woff'), url(${BarlowMediumItalicTTF}) format('ttf');
                        }
                        @font-face {
                            font-family: 'Barlow';
                            font-style: normal;
                            font-weight: 600;
                            font-display: swap;
                            src: local('Barlow Semi Bold'), url(${BarlowSemiBoldWoff2}) format('woff2'), url(${BarlowSemiBoldWoff}) format('woff'), url(${BarlowSemiBoldTTF}) format('ttf');
                        }
                        @font-face {   
                            font-family: 'Barlow';
                            font-style: italic;
                            font-weight: 600;
                            font-display: swap;
                            src: local('Barlow Semi Bold Italic'), url(${BarlowSemiBoldItalicWoff2}) format('woff2'), url(${BarlowSemiBoldItalicWoff}) format('woff'), url(${BarlowSemiBoldItalicTTF}) format('ttf');
                        }
                        
                        @font-face {
                            font-family: 'Barlow';
                            font-style: normal;
                            font-weight: 700;
                            font-display: swap;
                            src: local('Barlow Bold'), url(${BarlowBoldWoff2}) format('woff2'), url(${BarlowBoldWoff}) format('woff'), url(${BarlowBoldTTF}) format('ttf');
                        }
                        @font-face {
                            font-family: 'Barlow';
                            font-style: italic;
                            font-weight: 700;
                            font-display: swap;
                            src: local('Barlow Bold Italic'), url(${BarlowBoldItalicWoff2}) format('woff2'), url(${BarlowBoldItalicWoff}) format('woff'), url(${BarlowBoldItalicTTF}) format('ttf');
                        }
                        
                        @font-face {
                            font-family: 'Barlow';
                            font-style: normal;
                            font-weight: 800;
                            font-display: swap;
                            src: local('Barlow Extra Bold'), url(${BarlowExtraBoldWoff2}) format('woff2'), url(${BarlowExtraBoldWoff}) format('woff'), url(${BarlowExtraBoldTTF}) format('ttf');
                        }
                        
                        @font-face {
                            font-family: 'Barlow';
                            font-style: italic;
                            font-weight: 800;
                            font-display: swap;
                            src: local('Barlow Extra Bold Italic'), url(${BarlowExtraBoldItalicWoff2}) format('woff2'), url(${BarlowExtraBoldItalicWoff}) format('woff'), url(${BarlowExtraBoldItalicTTF}) format('ttf');
                        }
                        
                        @font-face {
                            font-family: 'Barlow';
                            font-style: normal;
                            font-weight: 900;
                            font-display: swap;
                            src: local('Barlow Black'), url(${BarlowBlackWoff2}) format('woff2'), url(${BarlowBlackWoff}) format('woff'), url(${BarlowBlackTTF}) format('ttf');
                        }
                        
                        @font-face {
                            font-family: 'Barlow';
                            font-style: italic;
                            font-weight: 900;
                            font-display: swap;
                            src: local('Barlow Black Italic'), url(${BarlowBlackItalicWoff2}) format('woff2'), url(${BarlowBlackItalicWoff}) format('woff'), url(${BarlowBlackItalicTTF}) format('ttf');
                        }
                        
                        @font-face {
                            font-family: 'Barlow Condensed';
                            font-style: normal;
                            font-weight: 100;
                            font-display: swap;
                            src: local('Barlow Condensed Thin'), url(${BarlowCondensedThinWoff2}) format('woff2'), url(${BarlowCondensedThinWoff}) format('woff'), url(${BarlowCondensedThinTTF}) format('ttf');
                        }
                        
                        @font-face {
                            font-family: 'Barlow Condensed';
                            font-style: italic;
                            font-weight: 100;
                            font-display: swap;
                            src: local('Barlow Condensed Thin Italic'), url(${BarlowCondensedThinItalicWoff2}) format('woff2'), url(${BarlowCondensedThinItalicWoff}) format('woff'), url(${BarlowCondensedThinItalicTTF}) format('ttf');
                        }
                        
                        @font-face {
                            font-family: 'Barlow Condensed';
                            font-style: normal;
                            font-weight: 200;
                            font-display: swap;
                            src: local('Barlow Condensed Extra Light'), url(${BarlowCondensedExtraLightWoff2}) format('woff2'), url(${BarlowCondensedExtraLightWoff}) format('woff'), url(${BarlowCondensedExtraLightTTF}) format('ttf');
                        }
                        
                        @font-face {
                            font-family: 'Barlow Condensed';
                            font-style: italic;
                            font-weight: 200;
                            font-display: swap;
                            src: local('Barlow Condensed Extra Light Italic'), url(${BarlowCondensedExtraLightItalicWoff2}) format('woff2'), url(${BarlowCondensedExtraLightItalicWoff}) format('woff'), url(${BarlowCondensedExtraLightItalicTTG}) format('ttf');
                        }
                        
                        @font-face {
                            font-family: 'Barlow Condensed';
                            font-style: normal;
                            font-weight: 300;
                            font-display: swap;
                            src: local('Barlow Condensed Light'), url(${BarlowCondensedLightWoff2}) format('woff2'), url(${BarlowCondensedLightWoff}) format('woff'), url(${BarlowCondensedLightTTF}) format('ttf');
                        }
                        
                        @font-face {
                            font-family: 'Barlow Condensed';
                            font-style: italic;
                            font-weight: 300;
                            font-display: swap;
                            src: local('Barlow Condensed Light Italic'), url(${BarlowCondensedLightItalicWoff2}) format('woff2'), url(${BarlowCondensedLightItalicWoff}) format('woff'), url(${BarlowCondensedLightItalicTTF}) format('ttf');
                        }
                        @font-face {
                            font-family: 'Barlow Condensed';
                            font-style: normal;
                            font-weight: 400;
                            font-display: swap;
                            src: local('Barlow Condensed'), url(${BarlowCondensedWoff2}) format('woff2'), url(${BarlowCondensedWoff}) format('woff'), url(${BarlowCondensedTTF}) format('ttf');
                        }
                                           
                        @font-face {
                            font-family: 'Barlow Condensed';
                            font-style: italic;
                            font-weight: 400;
                            font-display: swap;
                            src: local('Barlow Condensed Italic'), url(${BarlowCondensedItalicWoff2}) format('woff2'), url(${BarlowCondensedItalicWoff}) format('woff'), url(${BarlowCondensedItalicTTF}) format('ttf');
                        }
                        
                        @font-face {
                            font-family: 'Barlow Condensed';
                            font-style: normal;
                            font-weight: 500;
                            font-display: swap;
                            src: local('Barlow Condensed Medium'), url(${BarlowCondensedMediumWoff2}) format('woff2'), url(${BarlowCondensedMediumWoff}) format('woff'), url(${BarlowCondensedMediumTTF}) format('ttf');
                        }
                        
                        @font-face {
                            font-family: 'Barlow Condensed';
                            font-style: italic;
                            font-weight: 500;
                            font-display: swap;
                            src: local('Barlow Condensed Medium Italic'), url(${BarlowCondensedMediumItalicWoff2}) format('woff2'), url(${BarlowCondensedMediumItalicWoff}) format('woff'), url(${BarlowCondensedMediumItalicTTF}) format('ttf');
                        }
                        
                        @font-face {
                            font-family: 'Barlow Condensed';
                            font-style: normal;
                            font-weight: 600;
                            font-display: swap;
                            src: local('Barlow Condensed Semi Bold'), url(${BarlowCondensedSemiBoldWoff2}) format('woff2'), url(${BarlowCondensedSemiBoldWoff}) format('woff'), url(${BarlowCondensedSemiBoldTTF}) format('ttf');
                        }
                        
                        @font-face {
                            font-family: 'Barlow Condensed';
                            font-style: italic;
                            font-weight: 600;
                            font-display: swap;
                            src: local('Barlow Condensed Semi Bold Italic'), url(${BarlowCondensedSemiBoldItalicWoff2}) format('woff2'), url(${BarlowCondensedSemiBoldItalicWoff}) format('woff'), url(${BarlowCondensedSemiBoldItalicTTF}) format('ttf');
                        }
                        
                        @font-face {
                            font-family: 'Barlow Condensed';
                            font-style: normal;
                            font-weight: 700;
                            font-display: swap;
                            src: local('Barlow Condensed Bold'), url(${BarlowCondensedBoldWoff2}) format('woff2'), url(${BarlowCondensedBoldWoff}) format('woff'), url(${BarlowCondensedBoldTTF}) format('ttf');
                        }
                        
                        @font-face {
                            font-family: 'Barlow Condensed';
                            font-style: italic;
                            font-weight: 700;
                            font-display: swap;
                            src: local('Barlow Condensed Bold Italic'), url(${BarlowCondensedBoldItalicWoff2}) format('woff2'), url(${BarlowCondensedBoldItalicWoff}) format('woff'), url(${BarlowCondensedBoldItalicTTF}) format('ttf');
                        }
                        
                        @font-face {
                            font-family: 'Barlow Condensed';
                            font-style: normal;
                            font-weight: 800;
                            font-display: swap;
                            src: local('Barlow Condensed Extra Bold'), url(${BarlowCondensedExtraBoldWoff2}) format('woff2'), url(${BarlowCondensedExtraBoldWoff}) format('woff'), url(${BarlowCondensedExtraBoldTTF}) format('ttf');
                        }
                        
                        @font-face {
                            font-family: 'Barlow Condensed';
                            font-style: italic;
                            font-weight: 800;
                            font-display: swap;
                            src: local('Barlow Condensed Extra Bold Italic'), url(${BarlowCondensedExtraBoldItalicWoff2}) format('woff2'), url(${BarlowCondensedExtraBoldItalicWoff}) format('woff'), url(${BarlowCondensedExtraBoldItalicTTF}) format('ttf');
                        }
                        
                        @font-face {
                            font-family: 'Barlow Condensed';
                            font-style: normal;
                            font-weight: 900;
                            font-display: swap;
                            src: local('Barlow Condensed Black'), url(${BarlowCondensedBlackWoff2}) format('woff2'), url(${BarlowCondensedBlackWoff}) format('woff'), url(${BarlowCondensedBlackTTF}) format('ttf');
                        }
                        
                        @font-face {
                            font-family: 'Barlow Condensed';
                            font-style: italic;
                            font-weight: 900;
                            font-display: swap;
                            src: local('Barlow Condensed Black Italic'), url(${BarlowCondensedBlackItalicWoff2}) format('woff2'), url(${BarlowCondensedBlackItalicWoff}) format('woff'), url(${BarlowCondensedBlackItalicTTF}) format('ttf');
                        }
                        
                        @font-face {
                            font-family: 'MyWriting';
                            font-style: normal;
                            font-weight: 400;
                            font-display: swap;
                            src: local('MyWriting'), url(${MyWritingWoff2}) format('woff2'), url(${MyWritingWoff}) format('woff'), url(${MyWritingTTF}) format('ttf');
                        }
                    `
            }
        }
    });
}

const theme = createOnlineUiTheme();

export default theme;
