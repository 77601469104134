import {createAction} from '@reduxjs/toolkit';
import {UserState} from '../user/types';
import {OfflineReason, OnlineState} from '../connection/types';
import {createBasicSagaFetchAction, getRequest, postRequest, SagaHandling} from '@software/reactcommons';
import {Route} from '../../../api/Api';
import {fetchDeepLinkTokenSuccessSaga, hideAppLoader} from '../../sagas/ApplicationSaga';
import {CompiledMessage} from '@software/reactcommons/dist/localization/message/message.types';
import {createAuthenticatedSagaFetchAction} from '@software/reactcommons-security';
import {MdsLocale} from '../../../types/Types';


/***************************************************************
 * Redux actions types
 **************************************************************/
export const RequestWebSocketConnection = 'application/requestWebSocketConnection';
export const CloseWebSocketConnection = 'application/closeWebSocketConnection';
export const WebSocketConnectionClosed = 'application/webSocketConnectionClosed';
export const WebSocketConnectionOpened = 'application/webSocketConnectionOpened';
export const KeepWebSocketConnectionAlive = 'application/keepWebSocketConnectionAlive';
export const SendWebSocketMessage = 'application/sendWebSocketMessage';


/***************************************************************
 * Redux action payloads
 **************************************************************/

export interface RequestWebSocketConnectionActionPayload {
    url: string;
}

export interface SendWebSocketMessageActionPayload {
    url?: string;
    body: Object;
}


/***************************************************************
 * Redux action creator
 **************************************************************/


/**
 * Parameter is the URL for which the connection should be opened
 */
export const requestWebSocketConnectionAction = createAction<RequestWebSocketConnectionActionPayload>(RequestWebSocketConnection);

export const keepWebSocketConnectionAlive = createAction<string>(KeepWebSocketConnectionAlive);

/**
 * Parameter is the URL for which the connection should be closed
 */
export const closeWebSocketConnection = createAction<string>(CloseWebSocketConnection);
export const webSocketConnectionClosed = createAction<string>(WebSocketConnectionClosed);
export const webSocketConnectionOpened = createAction<string>(WebSocketConnectionOpened);
export const sendWebSocketMessage = createAction<SendWebSocketMessageActionPayload>(SendWebSocketMessage);

/***************************************************************
 * WebSocket messages and actions
 **************************************************************/

export enum OutgoingWebSocketMessageType {
    RefreshConnection = 'RefreshConnection'
}


export enum IncomingWebSocketMessageType {
}

export interface WebSocketMessage {
    type: IncomingWebSocketMessageType;
    user: UserState;
}


export const enableOnlineCheck = createAction<string>('application/enableOnlineCheck');

export interface SetOnlineStatePayload {
    url: string;
    state: OnlineState;
    offlineReason?: OfflineReason;
}

export interface DeepLink {
    autoTriggerLoginTokenMail: boolean;
    loggedInMessage?: CompiledMessage;
    loginPageMessage?: CompiledMessage;
    redirectPath?: string;
    username?: string;
}

export interface LoadDeepLinkPayload {
    token: string;
    triggerAutoActions: boolean;
}

export const loadDeepLinkActionAndSaga = createBasicSagaFetchAction<LoadDeepLinkPayload, DeepLink, never>({
    actionGroup: 'application',
    actionName: 'loadDeepLink',
    networkCall: (selectedLanguage, requestBody) => {
        return postRequest(Route.DeepLink.Load, {
            selectedLanguage,
            body: {
                token: requestBody?.token
            }
        });
    },
    successGenerator: [fetchDeepLinkTokenSuccessSaga],
    errorGenerator: [hideAppLoader]
});

export const LoadLocaleNameActionAndSaga = createAuthenticatedSagaFetchAction<{ locale: string; }, MdsLocale[], never>({
    actionGroup: 'application',
    actionName: 'loadLocaleName',
    networkCall: (jwt, selectedLanguage, params) => {
        return getRequest(Route.Locales.GetLocaleName(params!.locale), {
            jwt,
            selectedLanguage
        });
    },
    sagaHandling: SagaHandling.Every
});

export const LoadLocalesActionAndSaga = createAuthenticatedSagaFetchAction<void, MdsLocale[], never>({
    actionGroup: 'application',
    actionName: 'loadLocales',
    networkCall: (jwt, selectedLanguage) => {
        return getRequest(Route.Locales.GetLocales, {
            jwt,
            selectedLanguage
        });
    }
});