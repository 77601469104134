import {createAuthenticatedSagaFetchAction, LoginPasswordRule} from '@software/reactcommons-security';
import {
    createFetchAction,
    deleteRequest,
    getFilenameFromResponseHeaders,
    getRequest,
    postRequest,
    putRequest,
    SagaHandling,
    UploadFile,
    UploadFilePayload
} from '@software/reactcommons';
import {Route} from '../../../api/Api';
import {
    CreateDepartmentBody,
    CreateDepartmentGroupBody,
    CreateStickyBody,
    DataSource,
    Department,
    DepartmentGroup,
    IncidentProblem,
    LoadDepartmentsResponse,
    LoadStickiesResponse,
    LocationOpeningHour,
    Sticky,
    TicketEntry,
    UpdateDepartmentBody,
    UpdateDepartmentGroupBody,
    UpdateStickyBody
} from './types';
import {put} from 'redux-saga/effects';
import {
    addIncidentTicketNote,
    deleteStickyFromState,
    removeAccountsFromState,
    setAccountsDisabledState, setUpdateDepartmentSuccessFetchStatus
} from './administration';
import {CompiledMessage} from '@software/reactcommons/dist/localization/message/message.types';
import {DateTime} from 'luxon';
import {OpaqueIdsProps} from '../../../types/Types';
import {saveAs} from 'file-saver';

export interface LoadOpeningHoursForLocationPayload {
    id: number;
}

export interface UploadIncidentFilesPayload extends UploadFilePayload {
    terminalId: number;
}

export interface UploadIncidentFilesResponse {
    fileName: string;
}

export interface UploadIncidentFilesSuccessPayload extends UploadIncidentFilesResponse {
    id: string;
}

export interface SetIncidentFileUploadProgressPayload {
    id: string;
    progress: number;
}

export interface DeleteUploadIncidentFilePayload {
    file: UploadFile;
    terminalId: number;
}

export const uploadIncidentFilesAction = createFetchAction<UploadIncidentFilesPayload, UploadIncidentFilesSuccessPayload, any>('administration', 'uploadIncidentFile');

export const loadOpeningHoursForLocationActionAndSaga = createAuthenticatedSagaFetchAction<LoadOpeningHoursForLocationPayload, LocationOpeningHour, never>({
    actionGroup: 'administration',
    actionName: 'loadOpeningHoursForLocation',
    networkCall: (jwt, selectedLanguage, requestBody) => {
        return getRequest(Route.Administration.LoadOpeningHours(requestBody?.id), {jwt, selectedLanguage});
    }
});

export const saveOpeningHoursForLocationsActionAndSaga = createAuthenticatedSagaFetchAction<LocationOpeningHour[], LocationOpeningHour[], never>({
    actionGroup: 'administration',
    actionName: 'saveOpeningHours',
    networkCall: (jwt, selectedLanguage, body) => {
        return postRequest(Route.Administration.SaveOpeningHours, {body, jwt, selectedLanguage});
    }
});

export interface LoadTerminalsPayload {
    locationIds: number[];
    customerId: number;
}

export interface ApiListResponse<T> {
    elements: T[];
    totalCount?: number;
}

export const loadTerminalsActionAndSaga = createAuthenticatedSagaFetchAction<LoadTerminalsPayload, ApiListResponse<DataSource>, never>({
    actionGroup: 'administration',
    actionName: 'loadTerminals',
    networkCall: (jwt, selectedLanguage, body) => {
        return postRequest(Route.Administration.LoadTerminals, {body, jwt, selectedLanguage});
    }
});

export const loadQrCodesActionAndSaga = createAuthenticatedSagaFetchAction<LoadTerminalsPayload, ApiListResponse<DataSource>, never>({
    actionGroup: 'administration',
    actionName: 'loadQrCodes',
    networkCall: (jwt, selectedLanguage, body) => {
        return postRequest(Route.Administration.LoadQrCodes, {body, jwt, selectedLanguage});
    }
});

export const loadIncidentProblemsActionAndSaga = createAuthenticatedSagaFetchAction<void, ApiListResponse<IncidentProblem>, never>({
    actionGroup: 'administration',
    actionName: 'loadIncidentProblems',
    networkCall: (jwt, selectedLanguage) => {
        return getRequest(Route.Administration.LoadIncidentProblems, {jwt, selectedLanguage});
    }
});

export const deleteIncidentUploadFileActionAndSaga = createAuthenticatedSagaFetchAction<DeleteUploadIncidentFilePayload, void, never>({
    actionGroup: 'administration',
    actionName: 'deleteIncidentUploadFile',
    networkCall: (jwt, selectedLanguage, payload) => {
        return deleteRequest(Route.Administration.DeleteUploadedIncidentFile(payload?.terminalId as number, payload?.file.filename as string), {
            jwt,
            selectedLanguage
        });
    }
});

export enum FileAttachmentType {
    Image = 'Image',
    Video = 'Video'
}

export interface FileAttachment {
    type: FileAttachmentType;
    fileName: string;
}

export interface CreateIncidentPayload {
    deviceId: number;
    contactName: string;
    contactPhone: string;
    contactEmail: string;
    comment: string;
    problemIds: number[];
    fileAttachments: FileAttachment[];
}

export interface AddIncidentTicketEntryPayload {
    ticketEntry: TicketEntry;
    deviceId: number;
}

export const createIncidentActionAndSaga = createAuthenticatedSagaFetchAction<CreateIncidentPayload, TicketEntry, never>({
    actionGroup: 'administration',
    actionName: 'createIncident',
    networkCall: (jwt, selectedLanguage, body) => {
        return postRequest(Route.Administration.CreateIncident, {body, jwt, selectedLanguage});
    },
    successGenerator: [function* (request, response): Generator<any, any, any> {
        yield put(addIncidentTicketNote({
            ticketEntry: response,
            deviceId: request.deviceId
        }));
    }]
});


export interface DeviceStatusMessagePayload {
    locationId: number;
    deviceId: number;
    isOnline: boolean;
}

export interface BaseSearchAccountsRequest {
    texts?: string[];
    createdFrom?: number;
    createdUntil?: number;
    isTemporarilyDisabled?: boolean;
}


export interface SearchAccountsRequest extends BaseSearchAccountsRequest {
    limit?: number;
    offset?: number;
}

export enum TemporarilyDisabledReasonType {
    TooManyFailedPasswordLogins = 'TooManyFailedPasswordLogins',
    ManuallyDisabled = 'ManuallyDisabled'
}

export interface TemporarilyDisabledReason {
    type: TemporarilyDisabledReasonType;
}

export interface TooManyFailedPasswordLoginsDisabledReason extends TemporarilyDisabledReason {
    type: TemporarilyDisabledReasonType.TooManyFailedPasswordLogins;
    n: number;
}

export interface ManuallyDisabledDisabledReason extends TemporarilyDisabledReason {
    type: TemporarilyDisabledReasonType.ManuallyDisabled;
    explanation: CompiledMessage;
    automaticReEnableTimestamp?: string;
}

export enum TemporarilyDisabledStateType {
    NotDisabled = 'NotDisabled',
    Disabled = 'Disabled'
}

export interface TemporarilyDisabledState {
    type: TemporarilyDisabledStateType;
    ignoreBeforeTimestamp?: string;
}

export interface DisabledTemporarilyDisabledState extends TemporarilyDisabledState {
    type: TemporarilyDisabledStateType.Disabled;
    disabledTimestamp: string;
    reason: TemporarilyDisabledReason;
}

export interface NotDisabledTemporarilyDisabledState extends TemporarilyDisabledState {
    type: TemporarilyDisabledStateType.NotDisabled;
}


export enum PublicLoginMethodType {
    Password = 'Password',
    LoginToken = 'LoginToken',
    OpenIdConnectAuthorizationCodeFlow = 'OpenIdConnectAuthorizationCodeFlow'
}

export interface PublicLoginMethod {
    type: PublicLoginMethodType;
}

export interface PasswordPublicLoginMethod extends PublicLoginMethod {
    type: PublicLoginMethodType.Password;
    passwordRules: LoginPasswordRule[];
}

export interface LoginTokenPublicLoginMethod extends PublicLoginMethod {
    type: PublicLoginMethodType.LoginToken;
}

export interface OpenIdConnectAuthorizationCodeFlowPublicLoginMethod extends PublicLoginMethod {
    type: PublicLoginMethodType.OpenIdConnectAuthorizationCodeFlow;
    providerName: CompiledMessage;
}

export enum PublicLoginMethodConfigurationType {
    FromPreset = 'FromPreset',
    Explicit = 'Explicit',
    None = 'None'
}

export interface PublicLoginMethodConfiguration {
    type: PublicLoginMethodConfigurationType;
}

export interface FromPresetPublicLoginMethodConfiguration extends PublicLoginMethodConfiguration {
    type: PublicLoginMethodConfigurationType.FromPreset;
    name: CompiledMessage;
    description: CompiledMessage;
    loginMethods: PublicLoginMethod[];
}

export interface ExplicitPublicLoginMethodConfiguration extends PublicLoginMethodConfiguration {
    type: PublicLoginMethodConfigurationType.Explicit;
    loginMethods: PublicLoginMethod[];
}

export interface NonePublicLoginMethodConfiguration extends PublicLoginMethodConfiguration {
    type: PublicLoginMethodConfigurationType.None;
}

export enum PublicSecurityRuleConfigurationType {
    None = 'None',
    FromPreset = 'FromPreset'
}

export interface PublicSecurityRuleConfiguration {
    type: PublicSecurityRuleConfigurationType;
}

export interface NonePublicSecurityRuleConfiguration extends PublicSecurityRuleConfiguration {
    type: PublicSecurityRuleConfigurationType.None;
}

export interface FromPresetPublicSecurityRuleConfiguration extends PublicSecurityRuleConfiguration {
    type: PublicSecurityRuleConfigurationType.FromPreset;
    name: CompiledMessage;
    description: CompiledMessage;
}

export enum PublicRoleConfigurationType {
    FromPresets = 'FromPresets',
    Explicit = 'Explicit',
    None = 'None'
}

export interface PublicRoleConfiguration {
    type: PublicRoleConfigurationType;
}

export interface PresetInfo {
    name: CompiledMessage;
    description: CompiledMessage;
}

export interface FromPresetsPublicRoleConfiguration extends PublicRoleConfiguration {
    type: PublicRoleConfigurationType.FromPresets;
    presets: PresetInfo[];
}

export interface ExplicitPublicRoleConfiguration extends PublicRoleConfiguration {
    type: PublicRoleConfigurationType.Explicit;
}

export interface NonePublicRoleConfiguration extends PublicRoleConfiguration {
    type: PublicRoleConfigurationType.None;
}

export enum PublicAuthenticationConfigurationType {
    FromPreset = 'FromPreset',
    Explicit = 'Explicit'
}

export interface PublicAuthenticationConfiguration {
    type: PublicAuthenticationConfigurationType;
    loginMethodConfiguration: PublicLoginMethodConfiguration;
    roleConfiguration: PublicRoleConfiguration;
    securityRuleConfiguration: PublicSecurityRuleConfiguration;
}

export interface FromPresetPublicAuthenticationConfiguration extends PublicAuthenticationConfiguration {
    type: PublicAuthenticationConfigurationType.FromPreset;
    name: CompiledMessage;
    description: CompiledMessage;
}

export interface ExplicitPublicAuthenticationConfiguration extends PublicAuthenticationConfiguration {
    type: PublicAuthenticationConfigurationType.Explicit;
}

export interface PersonAccountAdminSelfServiceView {
    opaqueId: string;
    displayUsername?: string;
    displayEmail?: string;
    createdTimestamp: string;
    temporarilyDisabledState: TemporarilyDisabledState;
    authenticationConfiguration: PublicAuthenticationConfiguration;
}

export interface SearchAccountsResponse {
    accounts: PersonAccountAdminSelfServiceView[];
    totalCount: number;
}

export const loadAccountsActionAndSaga = createAuthenticatedSagaFetchAction<SearchAccountsRequest, SearchAccountsResponse, never>({
    actionGroup: 'administration',
    actionName: 'loadAccounts',
    networkCall: (jwt, selectedLanguage, body) => {
        return postRequest(Route.Administration.LoadAccounts, {body, jwt, selectedLanguage});
    },
    debounceDelay: 500
});

export const deactivateAccountsActionAndSaga = createAuthenticatedSagaFetchAction<OpaqueIdsProps, void, never>({
    actionGroup: 'administration',
    actionName: 'deactivateAccounts',
    networkCall: (jwt, selectedLanguage, body) => {
        return postRequest(Route.Administration.DeactivateAccounts, {body, jwt, selectedLanguage});
    },
    successGenerator: [function* (request): Generator<any, any, any> {
        // On success remove the deactivated accounted from the state
        yield put(removeAccountsFromState(request.opaqueIds));
    }]
});

export interface DisableAccountsRequestBody {
    opaqueIds: string[];
    explanation: CompiledMessage;
    automaticReEnableTimestamp?: string;
}

export const disableAccountsActionAndSaga = createAuthenticatedSagaFetchAction<DisableAccountsRequestBody, void, never>({
    actionGroup: 'administration',
    actionName: 'disableAccounts',
    networkCall: (jwt, selectedLanguage, body) => {
        return postRequest(Route.Administration.DisableAccounts, {body, jwt, selectedLanguage});
    },
    successGenerator: [function* (request): Generator<any, any, any> {
        // On success set the disabled state of the selected accounts
        yield put(setAccountsDisabledState({
            opaqueIds: request.opaqueIds,
            state: {
                type: TemporarilyDisabledStateType.Disabled,
                disabledTimestamp: DateTime.now().toISO(),
                reason: {
                    type: TemporarilyDisabledReasonType.ManuallyDisabled,
                    automaticReEnableTimestamp: request.automaticReEnableTimestamp,
                    explanation: request.explanation
                } as ManuallyDisabledDisabledReason,
            } as DisabledTemporarilyDisabledState
        }));
    }]
});

export const enableAccountsActionAndSaga = createAuthenticatedSagaFetchAction<OpaqueIdsProps, void, never>({
    actionGroup: 'administration',
    actionName: 'enableAccounts',
    networkCall: (jwt, selectedLanguage, body) => {
        return postRequest(Route.Administration.EnableAccounts, {body, jwt, selectedLanguage});
    },
    successGenerator: [function* (request): Generator<any, any, any> {
        // On success set the disabled state of the selected accounts
        yield put(setAccountsDisabledState({
            opaqueIds: request.opaqueIds,
            state: {
                type: TemporarilyDisabledStateType.NotDisabled,
            } as NotDisabledTemporarilyDisabledState
        }));
    }]
});

export const exportAccountsActionAndSaga = createAuthenticatedSagaFetchAction<BaseSearchAccountsRequest, Blob, never>({
    actionGroup: 'administration',
    actionName: 'exportAccounts',
    networkCall: (jwt, selectedLanguage, body) => {
        return postRequest(Route.Administration.ExportAccounts, {body, jwt, selectedLanguage});
    },
    successHandler: [function (_, response, responseHeaders) {
        // On success set the disabled state of the selected accounts
        saveAs(response, getFilenameFromResponseHeaders(responseHeaders));
    }]
});

export interface LoadAccountProfilesResponse {
    profiles: FromPresetPublicAuthenticationConfiguration[];
}

export const loadAccountProfilesActionAndSaga = createAuthenticatedSagaFetchAction<void, LoadAccountProfilesResponse, never>({
    actionGroup: 'administration',
    actionName: 'loadAccountProfiles',
    networkCall: (jwt, selectedLanguage) => {
        return getRequest(Route.Administration.LoadAccountProfiles, {jwt, selectedLanguage});
    }
});

export interface LoadAccountLoginAuditLogsBody {
    from: number;
    until: number;
}

export interface PersonAccountAuditView {
    opaqueId: string;
    displayUsername: string;
    displayEmail: string;
}

export enum LoginActivityType {
    Success = 'Success',
    Failure = 'Failure'
}

export interface LoginActivity {
    type: LoginActivityType;
    timestamp: string;
}

export interface UsedLoginMethod {
    type: PublicLoginMethodType;
}

export interface PasswordUsedLoginMethod extends UsedLoginMethod {
    type: PublicLoginMethodType.Password;
}

export interface LoginTokenUsedLoginMethod extends UsedLoginMethod {
    type: PublicLoginMethodType.LoginToken;
}

export interface OpenIdConnectAuthorizationCodeFlowUsedLoginMethod extends UsedLoginMethod {
    type: PublicLoginMethodType.OpenIdConnectAuthorizationCodeFlow;
    providerName: CompiledMessage;
}

export interface SuccessLoginActivity extends LoginActivity {
    type: LoginActivityType.Success
    method: UsedLoginMethod;
}

export interface PersonAccountFailedLoginDetails {
    type: PublicLoginMethodType;
    reason: CompiledMessage;
}

export interface PasswordPersonAccountFailedLoginDetails extends PersonAccountFailedLoginDetails {
    type: PublicLoginMethodType.Password;
}

export interface LoginTokenPersonAccountFailedLoginDetails extends PersonAccountFailedLoginDetails {
    type: PublicLoginMethodType.LoginToken;
}

export interface OpenIdConnectAuthorizationCodeFlowPersonAccountFailedLoginDetails extends PersonAccountFailedLoginDetails {
    type: PublicLoginMethodType.OpenIdConnectAuthorizationCodeFlow;
    state: string;
}

export interface FailureLoginActivity extends LoginActivity {
    type: LoginActivityType.Failure
    details: PersonAccountFailedLoginDetails;
}

export interface AccountLoginAuditLog {
    personAccount: PersonAccountAuditView;
    activity: LoginActivity;
}

export interface LoadAccountLoginAuditLogsResponse {
    entries: AccountLoginAuditLog[];
}

export enum AvailableLoginAuditTimeSpan {
    Last7Days = 'Last7Days',
    Last30Days = 'Last30Days',
    LastMonth = 'LastMonth',
    Last3Months = 'Last3Months',
    Last6Months = 'Last6Months',
    LastYear = 'LastYear',
    Custom = 'Custom'
}

export const loadAccountLoginAuditLogsActionAndSaga = createAuthenticatedSagaFetchAction<LoadAccountLoginAuditLogsBody, LoadAccountLoginAuditLogsResponse, never>({
    actionGroup: 'administration',
    actionName: 'loadAccountLoginAuditLogs',
    networkCall: (jwt, selectedLanguage, body) => {
        return postRequest(Route.Administration.LoadAccountAuditLogs, {jwt, selectedLanguage, body});
    }
});

/*******************************************************************************************
 * Departments
 *******************************************************************************************/

export const loadAllDepartmentsAndGroupsSaga = createAuthenticatedSagaFetchAction<void, LoadDepartmentsResponse, never>({
    actionGroup: 'administration',
    actionName: 'loadDepartments',
    networkCall: (jwt, selectedLanguage) => {
        return getRequest(Route.Administration.LoadDepartments, {
            jwt,
            selectedLanguage
        });
    }
});

export const createNewDepartmentActionAndSaga = createAuthenticatedSagaFetchAction<CreateDepartmentBody, Department, never>({
    actionGroup: 'administration',
    actionName: 'createNewDepartment',
    networkCall: (jwt, selectedLanguage, body) => {
        return postRequest(Route.Administration.CreateNewDepartment, {
            jwt,
            selectedLanguage,
            body
        });
    }
});

export const editDepartmentActionAndSaga = createAuthenticatedSagaFetchAction<UpdateDepartmentBody, Department, never>({
    actionGroup: 'administration',
    actionName: 'editDepartment',
    networkCall: (jwt, selectedLanguage, body) => {
        return putRequest(Route.Administration.EditDepartment, {
            jwt,
            selectedLanguage,
            body: {
                ...body,
                onlyUpdateOrder: undefined
            }
        });
    },
    successGenerator: [function* (request): Generator<any, any, any> {
        yield put(setUpdateDepartmentSuccessFetchStatus({id: request.id, onlyUpdateOrder: request.onlyUpdateOrder}));
    }],
    sagaHandling: SagaHandling.Every
});

export const createNewDepartmentGroupActionAndSaga = createAuthenticatedSagaFetchAction<CreateDepartmentGroupBody, DepartmentGroup, never>({
    actionGroup: 'administration',
    actionName: 'createNewDepartmentGroup',
    networkCall: (jwt, selectedLanguage, body) => {
        return postRequest(Route.Administration.CreateNewDepartmentGroup, {
            jwt,
            selectedLanguage,
            body
        });
    }
});

export const editDepartmentGroupActionAndSaga = createAuthenticatedSagaFetchAction<UpdateDepartmentGroupBody, DepartmentGroup, never>({
    actionGroup: 'administration',
    actionName: 'editDepartmentGroup',
    networkCall: (jwt, selectedLanguage, body) => {
        return putRequest(Route.Administration.EditDepartmentGroup, {
            jwt,
            selectedLanguage,
            body
        });
    },
});

export interface DepartmentImageUploadResponse {
    filename: string;
}

export const uploadDepartmentImageAction = createFetchAction<UploadFilePayload, DepartmentImageUploadResponse, never>('administration', 'uploadDepartmentImage');


/*******************************************************************************************
 * Sticky Editor
 *******************************************************************************************/

export const loadAllStickiesActionAndSaga = createAuthenticatedSagaFetchAction<void, LoadStickiesResponse, never>({
    actionGroup: 'administration',
    actionName: 'loadStickies',
    networkCall: (jwt, selectedLanguage) => {
        return getRequest(Route.Administration.LoadStickies, {
            jwt,
            selectedLanguage
        });
    }
});

export const createNewStickyActionAndSaga = createAuthenticatedSagaFetchAction<CreateStickyBody, Sticky, never>({
    actionGroup: 'administration',
    actionName: 'createNewSticky',
    networkCall: (jwt, selectedLanguage, body) => {
        return postRequest(Route.Administration.CreateNewSticky, {
            jwt,
            selectedLanguage,
            body
        });
    }
});

export const editStickyActionAndSaga = createAuthenticatedSagaFetchAction<UpdateStickyBody, Sticky, never>({
    actionGroup: 'administration',
    actionName: 'editSticky',
    networkCall: (jwt, selectedLanguage, body) => {
        return putRequest(Route.Administration.EditSticky, {
            jwt,
            selectedLanguage,
            body
        });
    },
});

export const deleteStickyActionAndSaga = createAuthenticatedSagaFetchAction<number, void, never>({
    actionGroup: 'administration',
    actionName: 'deleteSticky',
    networkCall: (jwt, selectedLanguage, payload) => {
        return deleteRequest(Route.Administration.DeleteSticky(payload!), {
            jwt,
            selectedLanguage
        });
    },
    successGenerator: [function* (request): Generator<any, any, any> {
        yield put(deleteStickyFromState(request));
    }]
});