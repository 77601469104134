import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Routes from '../constants/routes';
import {refreshTokenAction} from '@software/reactcommons-security';
import {
    useDeepLinkToken, useForcedLogoutRedirect, useInactivityLogout, useIsBlockedRedirect,
    useLanguageFromCookie,
    useParameterRedirect,
    useRedirect, useRedirectOnSetupFailure
} from '../hooks/hooks';
import {AppState} from '../types/Types';
import Menu from './skeleton/menu/Menu';
import InactivityLogoutDialog from './sites/authentication/dialogs/InactivityLogoutDialog';
import {Role} from '../redux/reducer/user/types';
import {loadDashboardsActionAndSaga} from '../redux/reducer/dashboard/actions';

const pathNamesWithoutMenu = [
    Routes.Login.Base(''),
    Routes.FeedbackBase(Routes.Feedback.Reports.Base(Routes.Feedback.Reports.Download(''))),
    Routes.Download.Base(Routes.Download.DashboardElementExport(''))
];

/**
 * React functional component that handles the setup of the application.
 * It defines the usage of several hooks which are used globally in the web app, e.g. the usage of the language cookie
 * or the redirect in the web app if defined via parameter.
 *
 * @component
 * @category Structure
 *
 * @return The rendered component containing the menu and the inactivity logout dialog or null if user is not logged in
 * or in one of the parts of the application which are defined to have no menu.
 */
const AppSetup: React.FC = () => {
    const dispatch = useDispatch();
    const {pathname} = useLocation();

    const prepared = useSelector((state: AppState) => state.user.prepared);
    const loggedIn = useSelector((state: AppState) => Boolean(state.user.info.jwt));
    const hasDashboardAccess = useSelector((state: AppState) => state.user.info.roles.includes(Role.Dashboard2_0) || state.user.info.roles.includes(Role.OperationsDashboard));
    const customerId = useSelector((state: AppState) => state.user.selectedCustomerId);

    // On setup load failure redirect to login page and clean the user state
    useRedirectOnSetupFailure();
    // Get the current language and store it in the state
    useLanguageFromCookie();
    // Get redirects from url parameter if available
    useParameterRedirect();
    // Enable global app redirects
    useRedirect();
    // Enable global listening on deep link tokens
    useDeepLinkToken();
    // Enable listening for inactivity (if defined via post authentication rules)
    useInactivityLogout();
    // Enable redirection to login page on forced logout (e.g. via logout in a different tab)
    useForcedLogoutRedirect();
    // Enable redirect to the login disabled site if user is blocked
    useIsBlockedRedirect();

    useEffect(() => {
        // If use can access new dashboard, load the dashboards
        if (hasDashboardAccess) {
            dispatch(loadDashboardsActionAndSaga.action({}));
        }
    }, [dispatch, hasDashboardAccess, customerId]);

    useEffect(() => {
        // On mount, check if user is already logged in
        dispatch(refreshTokenAction.action());
    }, [dispatch]);


    if (prepared && loggedIn && !pathNamesWithoutMenu.some(it => pathname.startsWith(it))) {
        // Check if user is logged in and menu structure should be shown, then display the menu and top bar
        return (<>
            <Menu/>
            <InactivityLogoutDialog/>
        </>);
    }
    // User is either not logged in or in login area, then do not render menu structure
    return null;
}


export default AppSetup;
