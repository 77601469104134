import {ChartConfiguration} from '@software/chartlibrary';

export interface DashboardElementProps {
    dashboardId: number;
    elementId: number;
}

export interface DashboardElementWithConfigUpdateProps extends DashboardElementProps {
    setChartConfiguration: (config: ChartConfiguration) => void;
}


export enum RankingType {
    AvgGrade = 'AVG_GRADE',
    TopBox = 'TOPBOX',
    Top2Box = 'TOP2BOX',
    Top3Box = 'TOP3BOX',
    NPS = 'NPS',
    BottomBox = 'BOTTOMBOX',
    Bottom2Box = 'BOTTOM2BOX',
    Bottom3Box = 'BOTTOM3BOX',
    PercentPromoters = 'PERCENT_PROMOTERS',
    PercentDetractors = 'PERCENT_DETRACTORS',
    ServiceIndex = 'SERVICE_INDEX',
    Avg = 'AVG',
    Count = 'COUNT',
}

export const RankingTypeToAppendix: Partial<Record<RankingType, string>> = {
    [RankingType.TopBox]: '%',
    [RankingType.Top2Box]: '%',
    [RankingType.Top3Box]: '%',
    [RankingType.BottomBox]: '%',
    [RankingType.Bottom2Box]: '%',
    [RankingType.Bottom3Box]: '%',
    [RankingType.PercentPromoters]: '%',
    [RankingType.PercentDetractors]: '%',
}

export const RankingTypeToDifferenceAppendix: Partial<Record<RankingType, string>> = {
    [RankingType.TopBox]: 'PP',
    [RankingType.Top2Box]: 'PP',
    [RankingType.Top3Box]: 'PP',
    [RankingType.BottomBox]: 'PP',
    [RankingType.Bottom2Box]: 'PP',
    [RankingType.Bottom3Box]: 'PP',
    [RankingType.PercentPromoters]: 'PP',
    [RankingType.PercentDetractors]: 'PP',
}


export interface FilterQuestion {
    categoryName?: string;
    questionId: number;
    text: string;
    shortName: string;
}