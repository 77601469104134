import {ActionReducerMapBuilder, Draft, PayloadAction} from '@reduxjs/toolkit';
import {VideoState} from './types';
import {
    AddVideosToPlaylistSectionPayload,
    deleteVideoPlaylistAction,
    enableVideoPlaylistAction,
    highlightVideoPlaylistAction,
    loadVideoPlaylistHighlightsAction,
    loadVideoPlaylistsAction,
    PlaylistSectionPayload,
    RemoveVideoFromPlaylistSectionPayload,
    RemoveVideoPlaylistImagePayload,
    ResetVideoPlaylistFetchStatusPayload,
    SetActiveVideoPlaylistVideoPayload,
    SetPlaylistSectionTitlePayload,
    SetSectionVideosPayload,
    SetUploadVideoPlaylistTitleImageProgressPayload,
    setVideoPlaylistProperty,
    UpdatePlaylistSectionPositionPayload,
    updateVideoPlaylistAction,
    uploadVideoPlaylistTitleImageAction
} from './actions';
import {FetchStatus, normalize, notUndefined} from '@software/reactcommons';


const setPlaylistFetchStatus = (state: Draft<VideoState>, playlistId: string, fetchStatus: FetchStatus, fetchStatusAttribute: 'saveFetchStatus' | 'enableFetchStatus' | 'highlightFetchStatus' | 'deleteFetchStatus') => {
    const playlist = state.playlists.elements[playlistId];
    if (playlist) {
        playlist[fetchStatusAttribute] = fetchStatus;
    }
}

export const playlistReducers = {
    createEmptyVideoPlaylist: (state: VideoState) => {
        state.playlists.elements['-1'] = {
            id: -1,
            playlistId: '-1',
            title: '',
            subTitle: '',
            description: '',
            enabled: true,
            sections: [{
                id: -1,
                videos: [],
                position: 1,
                title: ''
            }],
            previewImages: [],
            tags: [],
            views: 0,
            highlighted: false
        }
    },
    addVideoPlaylistSection: (state: VideoState, action: PayloadAction<string>) => {
        const playlist = state.playlists.elements[action.payload];
        if (playlist) {
            const position = ([...playlist.sections].sort((p1, p2) => p2.position - p1.position)[0]?.position || 0) + 1;
            playlist.sections.push({id: -1, videos: [], title: '', position});
        }
    },
    updateVideoPlaylistSectionPosition: (state: VideoState, action: PayloadAction<UpdatePlaylistSectionPositionPayload>) => {
        const playlist = state.playlists.elements[action.payload.playlistId];
        if (playlist) {
            playlist.sections.forEach(it => {
                if (it.position === action.payload.sectionPosition) {
                    // Element is the handled element, so set the new position
                    it.position = action.payload.newPosition;
                } else if (it.position === action.payload.newPosition) {
                    // Since the position can only be changed by one, update the element which was currently in
                    // the new position to the position of the handled element
                    it.position = action.payload.sectionPosition;
                }
            });
        }
    },
    removeVideoPlaylistSection: (state: VideoState, action: PayloadAction<PlaylistSectionPayload>) => {
        const playlist = state.playlists.elements[action.payload.playlistId];
        if (playlist) {
            playlist.sections = playlist.sections.filter(it => it.position !== action.payload.sectionPosition);
        }
    },
    addVideosToPlaylistSection: (state: VideoState, action: PayloadAction<AddVideosToPlaylistSectionPayload>) => {
        const section = state.playlists.elements[action.payload.playlistId]?.sections.find(it => it.position === action.payload.sectionPosition);
        if (section) {
            const existingVideoIds = section.videos.map(it => it.videoId);
            const newVideoIds = action.payload.videoIds.filter(it => !existingVideoIds.includes(it));
            const position = ([...section.videos].sort((i1, i2) => i2.position - i1.position)[0]?.position || 0);
            // Add all new videos
            section.videos = [...section.videos, ...newVideoIds.map((it, index) => {
                const video = state.videos.elements[it];
                if (video) {
                    return {
                        videoId: video.videoID,
                        position: position + index + 1,
                        title: video.title,
                        lengthMillis: video.lengthMillis,
                        creator: video.creator,
                        createdTimestamp: video.createdTimestamp
                    }
                }
                return undefined;
            }).filter(notUndefined)].sort((i1, i2) => i1.position - i2.position);
        }
    },
    setSectionVideos: (state: VideoState, action: PayloadAction<SetSectionVideosPayload>) => {
        const videos = action.payload.videos.map((it, index) => ({...it, position: index + 1}));
        const section = state.playlists.elements[action.payload.playlistId]?.sections.find(it => it.position === action.payload.sectionPosition);
        if (section) {
            section.videos = videos;
        }
    },
    removeVideoFromPlaylistSection: (state: VideoState, action: PayloadAction<RemoveVideoFromPlaylistSectionPayload>) => {
        const section = state.playlists.elements[action.payload.playlistId]?.sections.find(it => it.position === action.payload.sectionPosition);
        if (section) {
            // Remove the video by filtering the array and update the positions of the video.
            section.videos = section.videos.filter(it => it.videoId !== action.payload.videoId).map((it, index) => ({
                ...it,
                position: index + 1
            }));
        }
        return state;
    },
    setVideoPlaylistSectionTitle: (state: VideoState, action: PayloadAction<SetPlaylistSectionTitlePayload>) => {
        const section = state.playlists.elements[action.payload.playlistId]?.sections.find(it => it.position === action.payload.sectionPosition);
        if (section) {
            section.title = action.payload.title;
        }
    },
    setUploadVideoPlaylistTitleImageProgress: (state: VideoState, action: PayloadAction<SetUploadVideoPlaylistTitleImageProgressPayload>) => {
        const playlist = state.playlists.elements[action.payload.playlistId];
        if (playlist) {
            playlist.uploadImageProgress = action.payload.progress;
        }
    },
    setActiveVideoPlaylistVideo: (state: VideoState, action: PayloadAction<SetActiveVideoPlaylistVideoPayload>) => {
        const playlist = state.playlists.elements[action.payload.playlistId];
        if (playlist) {
            playlist.activeSection = action.payload.section;
            playlist.activeVideo = action.payload.videoId;
        }
    },
    removeVideoPlaylistImage: (state: VideoState, action: PayloadAction<RemoveVideoPlaylistImagePayload>) => {
        const playlist = state.playlists.elements[action.payload.playlistId];
        if (playlist) {
            playlist.previewImages = playlist.previewImages.filter(it => it.path !== action.payload.path);
        }
    },
    removeVideoPlaylist: (state: VideoState, action: PayloadAction<string>) => {
        delete state.playlists.elements[action.payload];
    },
    resetVideoPlaylistEdit: (state: VideoState, action: PayloadAction<string>) => {
        const playlist = state.playlists.originalElements[action.payload];
        if (playlist) {
            // Reset playlist to the original playlist
            state.playlists.elements[playlist.playlistId] = {...playlist};
        }
    },
    resetVideoPlaylistFetchStatus: (state: VideoState, action: PayloadAction<ResetVideoPlaylistFetchStatusPayload>) => {
        setPlaylistFetchStatus(state, action.payload.playlistId, FetchStatus.Default, action.payload.fetchStatus);
    }
}

export const extraPlaylistReducers = (builder: ActionReducerMapBuilder<VideoState>) =>
    builder.addCase(setVideoPlaylistProperty, (state, action) => {
        const playlist = state.playlists.elements[action.payload.playlistId];
        if (playlist) {
            // @ts-ignore
            playlist[action.payload.property] = action.payload.value;
        }
    }).addCase(uploadVideoPlaylistTitleImageAction.errorAction, (state, action) => {
        const playlist = state.playlists.elements[action.payload.playlistId];
        if (playlist) {
            playlist.uploadImageFetchStatus = FetchStatus.Error;
        }
    }).addCase(uploadVideoPlaylistTitleImageAction.startAction, (state, action) => {
        const playlist = state.playlists.elements[action.payload.playlistId];
        if (playlist) {
            playlist.uploadImageFetchStatus = FetchStatus.Active;
        }
    }).addCase(uploadVideoPlaylistTitleImageAction.successAction, (state, action) => {
        const playlist = state.playlists.elements[action.payload.playlistId];
        if (playlist) {
            playlist.uploadImageFetchStatus = FetchStatus.Success;
            playlist.previewImages = [{
                path: action.payload.response.imagePath.path,
                resolutionWidth: 0,
                fileName: action.payload.response.imagePath.fileName
            }];
        }
    }).addCase(updateVideoPlaylistAction.resetAction, (state, action) => {
        setPlaylistFetchStatus(state, action.payload, FetchStatus.Default, 'saveFetchStatus');
    }).addCase(updateVideoPlaylistAction.startAction, (state, action) => {
        setPlaylistFetchStatus(state, action.payload.playlistId, FetchStatus.Active, 'saveFetchStatus');
    }).addCase(updateVideoPlaylistAction.errorAction, (state, action) => {
        setPlaylistFetchStatus(state, action.payload.playlistId, FetchStatus.Error, 'saveFetchStatus');
    }).addCase(updateVideoPlaylistAction.successAction, (state, action) => {
        if (state.playlists.elements[action.payload.playlistId]) {
            state.playlists.elements[action.payload.playlistId]!.saveFetchStatus = FetchStatus.Success;
        }
        state.playlists.elements[action.payload.response.playlistId] = {
            ...(state.playlists.elements[action.payload.response.playlistId] || {}),
            ...action.payload.response,
            saveFetchStatus: FetchStatus.Success
        };
    }).addCase(loadVideoPlaylistsAction.startAction, (state) => {
        state.playlists.fetchStatus = FetchStatus.Active;
    }).addCase(loadVideoPlaylistsAction.errorAction, (state) => {
        state.playlists.fetchStatus = FetchStatus.Error;
    }).addCase(loadVideoPlaylistsAction.resetAction, (state) => {
        state.playlists.fetchStatus = FetchStatus.Default;
    }).addCase(loadVideoPlaylistsAction.successAction, (state, action) => {
        state.playlists.fetchStatus = FetchStatus.Success;
        state.playlists.elements = {...state.playlists.elements, ...normalize(action.payload.playlists, undefined, 'playlistId')};
        state.playlists.originalElements = {...state.playlists.originalElements, ...normalize(action.payload.playlists, undefined, 'playlistId')};
        state.playlists.count = action.payload.totalCount;
    }).addCase(highlightVideoPlaylistAction.startAction, (state, action) => {
        setPlaylistFetchStatus(state, action.payload.playlistId, FetchStatus.Active, 'highlightFetchStatus');
    }).addCase(highlightVideoPlaylistAction.errorAction, (state, action) => {
        setPlaylistFetchStatus(state, action.payload.playlistId, FetchStatus.Error, 'highlightFetchStatus');
    }).addCase(highlightVideoPlaylistAction.successAction, (state, action) => {
        const playlist = state.playlists.elements[action.payload.playlistId];
        if (playlist) {
            playlist.highlightFetchStatus = FetchStatus.Success;
            playlist.highlighted = action.payload.value;
            // Check if playlist was highlighted, then push it to the highlights, otherwise remove it
            if (action.payload.value) {
                state.playlists.highlights.push(playlist.playlistId);
            } else {
                state.playlists.highlights = state.playlists.highlights.filter(it => it !== playlist.playlistId);
            }
        }
    }).addCase(enableVideoPlaylistAction.startAction, (state, action) => {
        setPlaylistFetchStatus(state, action.payload.playlistId, FetchStatus.Active, 'enableFetchStatus');
    }).addCase(enableVideoPlaylistAction.errorAction, (state, action) => {
        setPlaylistFetchStatus(state, action.payload.playlistId, FetchStatus.Error, 'enableFetchStatus');
    }).addCase(enableVideoPlaylistAction.successAction, (state, action) => {
        const playlist = state.playlists.elements[action.payload.playlistId];
        if (playlist) {
            playlist.enableFetchStatus = FetchStatus.Success;
            playlist.enabled = action.payload.value;
        }
    }).addCase(deleteVideoPlaylistAction.startAction, (state, action) => {
        setPlaylistFetchStatus(state, action.payload.playlistId, FetchStatus.Active, 'deleteFetchStatus');
    }).addCase(deleteVideoPlaylistAction.errorAction, (state, action) => {
        setPlaylistFetchStatus(state, action.payload.playlistId, FetchStatus.Error, 'deleteFetchStatus');
    }).addCase(deleteVideoPlaylistAction.successAction, (state, action) => {
        setPlaylistFetchStatus(state, action.payload.playlistId, FetchStatus.Success, 'deleteFetchStatus');
    }).addCase(loadVideoPlaylistHighlightsAction.startAction, (state) => {
        state.playlists.highlightFetchStatus = FetchStatus.Active;
    }).addCase(loadVideoPlaylistHighlightsAction.errorAction, state => {
        state.playlists.highlightFetchStatus = FetchStatus.Error;
    }).addCase(loadVideoPlaylistHighlightsAction.successAction, (state, action) => {
        state.playlists.highlightFetchStatus = FetchStatus.Success;
        state.playlists.elements = {...normalize(action.payload, undefined, 'playlistId'), ...state.playlists.elements};
        state.playlists.originalElements = {...normalize(action.payload, undefined, 'playlistId'), ...state.playlists.originalElements};
        state.playlists.highlights = action.payload.map(it => it.playlistId)
    })
