import {FetchStatus, UploadFile} from '@software/reactcommons';
import {
    ApiListResponse,
    AccountLoginAuditLog,
    FileAttachment,
    FromPresetPublicAuthenticationConfiguration,
    PersonAccountAdminSelfServiceView
} from './actions';
import {PaginationState} from '../../../types/Types';

export enum IncomingWebSocketMessageType {
    DeviceStatus = 'DeviceStatus'
}

export enum WeekDay {
    None, Sunday, Monday, Tuesday, Wednesday, Thursday, Friday, Saturday
}

export const SortedWeekDays = [WeekDay.Monday, WeekDay.Tuesday, WeekDay.Wednesday, WeekDay.Thursday, WeekDay.Friday, WeekDay.Saturday, WeekDay.Sunday]

export interface OpeningHour {
    dayOfWeek: WeekDay;
    closed: boolean;
    startTimeOfDay?: string;
    endTimeOfDay?: string
}

export interface LocationOpeningHour {
    locationId: number;
    alwaysOpen: boolean;
    openingHours: OpeningHour[];
}


export enum LocationSetupStatus {
    NO_TABLET,
    SETUP,
    ACTIVE,
    TEMP_CLOSED,
    TERMINATED,
    INACTIVE,
    TEARDOWN
}

export interface DataSource {
    deviceId: number;
    locationId: number;
    isOnline: boolean;
    setupStatus: LocationSetupStatus;
    pinCode?: string;
    deviceLocationName: string;
    connectionMethod?: string;
    ticketEntries?: TicketEntry[];
}

export interface TicketEntry {
    id: number;
    contactName?: string;
    contactPhone?: string;
    noteTime: number;
    comment: string;
    problems: IncidentProblem[];
    attachments: FileAttachment[];
    createdByPerson: boolean;
}

export interface IncidentProblem {
    id: number;
    name: string;
    description: string;
}


export enum AllowedIncidentUploadVideoType {
    MP4 = 'video/mp4',
    QUICKTIME = 'video/quicktime'
}

export enum AllowedIncidentImageUploadType {
    JPG = 'image/jpg',
    JPEG = 'image/jpeg',
    PNG = 'image/png'
}



export interface AdministrationState {
    openingHours: {
        selectedLocationId: number;
        elements: Record<number, LocationOpeningHour>;
    };
    terminals: {
        elements: Record<number, DataSource>;
    };
    qrCodes: {
        elements: Record<number, DataSource>;
    };
    incidents: {
        problems: Record<number, IncidentProblem>;
        uploads: Record<string, UploadFile>;
    };
    accounts: PaginationState<PersonAccountAdminSelfServiceView>;
    profiles: {
        elements: FromPresetPublicAuthenticationConfiguration[];
    };
    loginAudit: {
        elements: AccountLoginAuditLog[];
    };
    departments: {
        departments: Department[];
        createdDepartment?: Department;
        updatedDepartment?: Department;
        departmentRecord: Record<number, Department | undefined>;
        departmentGroups: Record<number, DepartmentGroup | undefined>;
        createdDepartmentGroup?: DepartmentGroup;
        updatedDepartmentGroup?: DepartmentGroup;
        uploadedImage?: UploadFile;
        aspectRatio?: {
            width: number;
            height: number;
        };
        wording: {
            singular: string;
            plural: string;
        }
    };
    stickies: {
        elements: Record<number, Sticky | undefined>;
        createdSticky?: Sticky;
        updatedSticky?: Sticky;
        previewPath: string;
        stickyPosition: Position;
        screenDimension: Dimension;
        selectedItems: string[];
    }
    fetchStatus: {
        openingHours: {
            load: FetchStatus;
            save: FetchStatus;
        };
        terminals: {
            load: FetchStatus;
        };
        qrCodes: {
            load: FetchStatus;
        };
        incidents: {
            load: FetchStatus;
            create: FetchStatus;
        };
        accounts: {
            load: FetchStatus;
            disable: FetchStatus;
            reEnable: FetchStatus;
            deactivate: FetchStatus;
            profiles: FetchStatus;
            loginAudit: FetchStatus;
            export: FetchStatus;
        };
        departments: {
          load: FetchStatus;
          createDepartment: FetchStatus;
          editDepartment: Record<number, FetchStatus | undefined>;
          editOrder: Record<number, FetchStatus | undefined>;
          saveGroup: FetchStatus;
          editGroup: Record<number, FetchStatus | undefined>;
          uploadImage: FetchStatus;
        };
        stickies: {
            load: FetchStatus;
            delete: FetchStatus;
            save: FetchStatus;
            update: Record<number, FetchStatus | undefined>;
        }
    };
}

/*******************************************************************************************
 * Departments
 *******************************************************************************************/

export enum CreateStateSelection {
    Active = 'Active',
    Inactive = 'Inactive',
}

export interface Department {
    id: number;
    customerId: number;
    name: string;
    reportingName: string;
    active: boolean;
    imagePath: string;
    order: number;
    groupId?: number;
}

export interface DepartmentGroup {
    id: number;
    customerId: number;
    groupId: number;
    singularName: string;
    pluralName: string;
}

export interface LoadDepartmentsResponse {
    departments: Department[];
    groups: DepartmentGroup[];
    wording: {
        singular: string;
        plural: string;
    };
    ratio?: {
        width: number;
        height: number;
    };
}

export interface CreateDepartmentBody {
    name: string;
    order: number;
    active: boolean;
    imagePath: string;
    reportingName?: string;
    groupId?: number;
}

export interface UpdateDepartmentBody extends CreateDepartmentBody {
    id: number;
    onlyUpdateOrder: boolean;
}

export interface CreateDepartmentGroupBody {
    groupId: number;
    singularName: string;
    pluralName: string;
}

export interface UpdateDepartmentGroupBody extends CreateDepartmentGroupBody {
    id: number;
}


/*******************************************************************************************
 * Sticky Editor
 *******************************************************************************************/

export enum StickyColor {
    Yellow = 'gelb',
    Pink = 'rosa',
    White = 'weiss'
}

export enum StickyVisibilityType {
    Restricted = 'restricted',
    Unrestricted = 'unrestricted'
}

export interface StickyVisibility {
    type: StickyVisibilityType;
}

export interface StickyUnrestrictedVisibility extends StickyVisibility {
    type: StickyVisibilityType.Unrestricted;
}

export interface StickyRestrictedVisibility extends StickyVisibility {
    type: StickyVisibilityType.Restricted;
    regionIds: number[];
    locationIds: number[];
    dataSourceIds: number[];
}

export interface CreateStickyBody {
    color: StickyColor;
    text: string;
    visibility: StickyVisibility;
    startDate?: number;
    endDate?: number;
}

export interface Sticky extends CreateStickyBody {
    id: number;
    active: boolean;
    customerId: number;
    lastAction?: string;
    editable?: boolean;
}

export interface Position {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
}

export interface Dimension {
    width?: number;
    height?: number;
}

export interface LoadStickiesResponse extends ApiListResponse<Sticky> {
    screenPreviewPath: string;
    position: Position;
    screenDimension: Dimension;
}

export interface UpdateStickyBody extends CreateStickyBody {
    id: number;
    active: boolean;
}

