import {FetchStatus} from '@software/reactcommons';
import {
    PDFDocumentProxy
} from 'pdfjs-dist/types/src/display/api';

export enum ReportTimeScopeType {
    Day = 'Day',
    Week = 'Week',
    Month = 'Month',
    Quarter = 'Quarter',
    Year = 'Year',
    Custom = 'Custom',
    Total = 'Total'
}

export enum ReportTimeSpanType {
    Day,
    Week,
    Month,
    Quarter,
    Year,
    Custom,
    Total
}

export const ReportTimeScopeToTimeSpan: Record<ReportTimeScopeType, ReportTimeSpanType> = {
    [ReportTimeScopeType.Day]: ReportTimeSpanType.Day,
    [ReportTimeScopeType.Week]: ReportTimeSpanType.Week,
    [ReportTimeScopeType.Month]: ReportTimeSpanType.Month,
    [ReportTimeScopeType.Quarter]: ReportTimeSpanType.Quarter,
    [ReportTimeScopeType.Year]: ReportTimeSpanType.Year,
    [ReportTimeScopeType.Custom]: ReportTimeSpanType.Custom,
    [ReportTimeScopeType.Total]: ReportTimeSpanType.Total
}

export interface ReportTimeScope {
    type: ReportTimeScopeType;
}

export interface DayReportTimeScope extends ReportTimeScope {
    type: ReportTimeScopeType.Day;
    day: string;
}

export interface WeekReportTimeScope extends ReportTimeScope {
    type: ReportTimeScopeType.Week;
    weekOfYear: number;
    year: number;
}

export interface MonthReportTimeScope extends ReportTimeScope {
    type: ReportTimeScopeType.Month;
    month: number;
    year: number;
}

export interface QuarterReportTimeScope extends ReportTimeScope {
    type: ReportTimeScopeType.Quarter;
    quarter: number;
    year: number;
}

export interface YearReportTimeScope extends ReportTimeScope {
    type: ReportTimeScopeType.Year;
    year: number;
}

export interface CustomReportTimeScope extends ReportTimeScope {
    type: ReportTimeScopeType.Custom;
    firstDay?: string;
    lastDay?: string;
}

export interface TotalReportTimeScope extends ReportTimeScope {
    type: ReportTimeScopeType.Total;
    firstDay?: string;
    lastDay?: string;
}

export enum ReportScopeType {
    Central = 'Central',
    Region = 'Region',
    Location = 'Location',
    Department = 'Department'
}

export interface ReportScope {
    type: ReportScopeType;
}

export interface CentralReportScope extends ReportScope {
    type: ReportScopeType.Central;
}

export interface DepartmentReportScope extends ReportScope {
    type: ReportScopeType.Department;
    name?: string;
}

export interface RegionReportScope extends ReportScope {
    type: ReportScopeType.Region;
    regionId: number;
}

export interface LocationReportScope extends ReportScope {
    type: ReportScopeType.Location;
    locationId: number;
}

export interface ReportFilterScope {
    type: ReportScopeType;
}

export interface CentralReportFilterScope extends ReportFilterScope {
    type: ReportScopeType.Central;
    name: string;
}

export interface DepartmentReportFilterScope extends ReportFilterScope {
    type: ReportScopeType.Department;
    name: string;
}

export interface RegionReportFilterScope extends ReportFilterScope {
    type: ReportScopeType.Region;
}

export interface LocationReportFilterScope extends ReportFilterScope {
    type: ReportScopeType.Location;
}

export enum ReportContentType {
    PDF = 'PDF',
    Html = 'Html',
    Download = 'Download'
}

export interface ReportContent {
    type: ReportContentType;
    additionalContent: boolean;
}

export interface PdfReportContent extends ReportContent {
    type: ReportContentType.PDF;
    filePath: string;
    fileName?: string;
}

export interface HtmlReportContent extends ReportContent {
    type: ReportContentType.Html;
    html: string;
}

export interface DownloadReportContent extends ReportContent {
    type: ReportContentType.Download;
    filePath: string;
    fileName?: string;
}

export interface Report {
    opaqueId: string;
    name: string;
    sentDateTime: string;
    timeScope: ReportTimeScope;
    scope: ReportScope;
    content: ReportContent;
    previewImagePath?: string;
}

export enum SearchReportScopeType {
    All = 'All',
    Central = 'Central',
    AllRegions = 'AllRegions',
    AllLocations = 'AllLocations',
    Region = 'Region',
    Location = 'Location'
}

export interface SearchReportScope {
    type: SearchReportScopeType;
}

export interface SearchReportScope {
    type: SearchReportScopeType;
}

export interface AllSearchReportScope extends SearchReportScope {
    type: SearchReportScopeType.All;
}

export interface CentralSearchReportScope extends SearchReportScope {
    type: SearchReportScopeType.Central;
}

export interface AllRegionsSearchReportScope extends SearchReportScope {
    type: SearchReportScopeType.AllRegions;
}

export interface AllLocationsSearchReportScope extends SearchReportScope {
    type: SearchReportScopeType.AllLocations;
}

export interface RegionSearchReportScope extends SearchReportScope {
    type: SearchReportScopeType.Region;
    regionId: number;
}

export interface LocationSearchReportScope extends SearchReportScope {
    type: SearchReportScopeType.Location;
    locationId: number;
}

export interface ReportSearch {
    scope: SearchReportScope;
    timeSpans: ReportTimeSpanType[];
    from?: string;
    to?: string;
    searchText: string;
    page: number;
    pageSize: number;
    replace?: boolean;
}

export interface ReportByReportScope {
    type: ReportScopeType;
    page: number;
    pageSize: number;
    totalCount: number;
    elements: string[];
    fetchStatus: FetchStatus;
}


export interface PdfOutlineSection {
    title: string;
    bold: boolean;
    italic: boolean;
    color: Uint8ClampedArray;
    dest: string | Array<any> | null;
    url: string | null;
    unsafeUrl: string | undefined;
    newWindow: boolean | undefined;
    count: number | undefined;
    items: any[];
}


export interface PdfOutlineItem {
    dest: string | Promise<any>;
    title: string;
    items?: PdfOutlineItem[];
}

export interface PdfOutline {
    title: string;
    bold: boolean;
    italic: boolean;
    /**
     * - The color in RGB format to use for
     * display purposes.
     */
    color: Uint8ClampedArray;
    dest: string | Array<any> | null;
    url: string | null;
    unsafeUrl: string | undefined;
    newWindow: boolean | undefined;
    count: number | undefined;
    items: PdfOutlineItem[];
}

export interface ActiveReport {
    pdf: PDFDocumentProxy;
    search: string;
    pages: string[];
    usedSearch?: string;
    searchResults: number[];
    loading?: boolean;
    activeResult?: number;
}

export interface ReportState {
    elements: Record<string, Report>;
    outlines: Record<string, PdfOutline[]>;
    search: ReportSearch;
    availableTimeSpans: ReportTimeSpanType[];
    availableScopes: ReportFilterScope[];
    reportsByScope: Record<string, ReportByReportScope | undefined>;
    count: number;
    visible: string[];
    latest: string[];
    selectedReportScope?: ReportScopeType;
    activeReport?: ActiveReport;
    fetchStatus: {
        load: FetchStatus;
        loadSingle: FetchStatus;
        scopes: FetchStatus;
        timeSpans: FetchStatus;
        latest: FetchStatus;
        checkReportAccess: FetchStatus;
    };
}