
export enum OfflineReason {
    BackendNotReachable = 'BackendNotReachable',
    NetworkError = 'NetworkError'
}

export enum OnlineState {
    Online = 'Online',
    Offline = 'Offline'
}

export interface BackendOnlineState {
    state: OnlineState;
    offlineReason?: OfflineReason;
}

export interface ConnectionState {
    online: Record<string, BackendOnlineState>;
    webSocketState: Record<string, OnlineState>;
}