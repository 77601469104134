import {createAction} from '@reduxjs/toolkit';
import {
    ComparisonGrouping,
    ComparisonPeriod,
    CustomPeriod,
    DashboardElementConfiguration,
    DashboardElementPreviewConfigType,
    DashboardGroupResponse,
    DashboardResponse,
    DashboardResponseType,
    FileDashboardElementDisplayType,
    FilterOption,
    Period,
    PeriodGrouping,
    RankingOrder,
    ResponseDashboard,
    SingleDashboardResponse
} from './types';
import {ChartConfiguration} from '@software/chartlibrary';
import {createFetchAction, getRequest, postRequest, SagaHandling} from '@software/reactcommons';
import {createAuthenticatedSagaFetchAction} from '@software/reactcommons-security';
import {Route} from '../../../api/Api';
import {ApiListResponse} from '../administration/actions';
import {call, put, PutEffect} from 'redux-saga/effects';
import {setDashboardElementExportSuccess} from './dashboard';
import {downloadElementExport} from '../../sagas/feedback/DashboardSaga';
import {Sentiment} from '../../../types/charts/chartTypes';
import {RankingType} from '../../../components/sites/feedback/dashboard/elements/types';
import {IdParams} from '../../../types/Types';

/***************************************************************
 * Redux actions types
 **************************************************************/


export const SyncDashboardDataActionKey = 'dashboard/syncDashboardData';
export const SearchTableActionKey = 'dashboard/searchTable';

/***************************************************************
 * Redux action payloads
 **************************************************************/


export interface DashboardElementPayload {
    id: number;
    dashboard?: number;
}

export interface SetGlobalRefreshTimestampActionPayload {
    id: number;
    timestamp: number;
}

export interface EnableShowIconsActionPayload {
    id: number;
    showIcons: boolean;
}

export interface EnableOnlyIncludeFinishedFeedbackActionPayload {
    id: number;
    onlyIncludeFinishedFeedback: boolean;
}

export interface EnableShowThresholdActionPayload {
    id: number;
    showThresholds: boolean;
}


export interface UpdateElementRestrictionsActionPayload {
    id: number;
    name: string;
    options?: FilterOption[];

    dashboardId?: number;
}

export interface UpdateElementSubChartTypeActionPayload {
    id: number;
    type: number;
}

export interface UpdateElementDataSuccessActionPayload extends UpdateElementFetchStatusActionPayload {
    config: DashboardElementConfiguration;
    merge?: boolean;
}

export interface UpdateElementFetchStatusActionPayload {
    id: number;
    dashboard: number;
}

export interface UpdateGlobalFilterActionPayload {
    name: string;
    options: string[];
}

export interface UpdateFilterActionPayload {
    name: string;
    options: FilterOption[];
}

export interface UpdateElementFilterActionPayload extends UpdateGlobalFilterActionPayload {
    id: number;
    dashboardId?: number;
}

export interface UpdateElementPeriodActionPayload {
    id: number;
    period: Period;
    dashboardId?: number;
}

export interface UpdateElementCustomPeriodActionPayload {
    id: number;
    type: number;
    period: CustomPeriod;
    dashboardId?: number;
}

export interface UpdateAllElementsCustomPeriodActionPayload {
    type: number;
    period: CustomPeriod;
}

export interface SearchTableActionPayload {
    id: number;
    text: string;
}

export interface DownloadChartPayload {
    id: number;
    configuration: ChartConfiguration;
    type: string;
    name: string;
}

export interface DownloadElementPayload {
    id: number;
}

export interface SetSelectedComparisonPeriodPayload {
    id: number;
    period: ComparisonPeriod;
}


export interface SetCustomComparisonPeriodPayload {
    id: number;
    period: CustomPeriod;
    type: ComparisonPeriod;
}


export interface SetAllElementsCustomComparisonPeriodPayload {
    period: CustomPeriod;
    type: ComparisonPeriod;
}

/***************************************************************
 * Redux action creator
 **************************************************************/

export const loadDashboardsActionAndSaga = createAuthenticatedSagaFetchAction<{}, ApiListResponse<DashboardResponse>, never>({
    actionGroup: 'dashboard',
    actionName: 'loadDashboards',
    networkCall: (jwt, selectedLanguage, params) => getRequest(Route.LoadDashboards, {params, jwt, selectedLanguage}),
    successGenerator: [function*(request, response): Generator<PutEffect, any, any> {
        // Check if some dashboards have elements, which have preview display type original, then trigger filter loading for the dashboard
        const dashboardIds = response.elements.flatMap(it => {
            // Map the response to an array of dashboards
            if (it.type === DashboardResponseType.Dashboard) {
                return [(it as SingleDashboardResponse).dashboard]
            } else if (it.type === DashboardResponseType.Group) {
                return (it as DashboardGroupResponse).group.dashboards;
            }
            return [];
        }).filter(it => {
            // Check if some elements of this dashboard have the original type as preview config type
            return it.elements.some(element => element.previewConfig?.type === DashboardElementPreviewConfigType.OriginalElement);
        }).map(it => it.id);

        // Trigger for each dashboard the load endpoint
        for (const id of dashboardIds) {
            yield put(loadDashboardActionAndSaga.action({id}));
        }
    }]
});

export const loadDashboardActionAndSaga = createAuthenticatedSagaFetchAction<IdParams, ResponseDashboard, never>({
    actionGroup: 'dashboard',
    actionName: 'loadDashboard',
    networkCall: (jwt, selectedLanguage, params) => getRequest(Route.LoadDashboard(params!.id), {
        jwt,
        selectedLanguage
    }),
    sagaHandling: SagaHandling.Every
});

export interface SetDashboardElementVisiblePayload {
    id: number;
    visible: boolean;
    dashboardId: number;
}

export interface UpdateElementPagePayload {
    id: number;
    dashboard?: number;
    page: number;
}

export const syncElementConfigAction = createFetchAction<DashboardElementPayload, DashboardElementPayload, 'UNKNOWN'>('dashboard', 'syncElementConfig');

export const syncDashboardData = createAction(SyncDashboardDataActionKey);


export const searchTable = createAction<SearchTableActionPayload>(SearchTableActionKey);

export const downloadChartAction = createFetchAction<DownloadChartPayload, DownloadChartPayload, 'UNKNOWN'>('dashboard', 'downloadChart');
export const downloadElementAction = createFetchAction<DownloadElementPayload, DownloadElementPayload, 'UNKNOWN'>('dashboard', 'downloadElement');

export interface ExportElementPayload {
    dashboardElementId: number;
    exportId: number;
}

export interface DashboardElementExport {
    opaqueId: string;
    fileName: string;
}

export const DownloadElementExportActionKey = 'dashboards/downloadElementExport';

export const downloadElementExportAction = createAction<string>(DownloadElementExportActionKey);

export const exportElementActionAndSaga = createAuthenticatedSagaFetchAction<ExportElementPayload, DashboardElementExport | undefined, never>({
    actionGroup: 'dashboard',
    actionName: 'exportElement',
    networkCall: (jwt, selectedLanguage, requestBody) => {
        return postRequest(Route.Dashboards.Export.ExportElement(requestBody!.dashboardElementId, requestBody!.exportId), {
            jwt,
            selectedLanguage
        });
    },
    successGenerator: [function* (request, response, responseHeaders): Generator<any, any, any> {
        if (response?.opaqueId) {
            yield call(downloadElementExport, response.opaqueId);
        }
        yield put(setDashboardElementExportSuccess(request));
    }],
    timeoutMillis: 360000
});

export interface LoadElementWithConfigPayload {
    dashboardId: number;
    id: number;
    config: DashboardElementConfiguration;
    keepOriginalPeriod?: boolean;
}

export const loadElementWithConfigAction = createFetchAction<LoadElementWithConfigPayload, UpdateElementDataSuccessActionPayload, 'UNKNOWN'>('dashboard', 'loadElementWithConfig');

export interface SetDashboardElementQuestionPayload {
    dashboardId: number;
    elementId: number;
    questionId: number;
}

export interface SetDashboardFileElementDisplayTypePayload {
    dashboardId: number;
    elementId: number;
    displayType: FileDashboardElementDisplayType;
}

export interface SetDashboardElementRankingTypePayload {
    dashboardId: number;
    elementId: number;
    rankingType: RankingType;
}

export interface SetDashboardElementRankingOrderPayload {
    dashboardId: number;
    elementId: number;
    rankingOrder: RankingOrder;
}

export interface SetDashboardElementGroupingPayload {
    dashboardId: number;
    elementId: number;
    grouping: ComparisonGrouping;
}

export interface SetDashboardElementRatingFilterPayload {
    dashboardId: number;
    elementId: number;
    ratingFilter: Sentiment[];
}

export interface SetDashboardElementPeriodGroupingFilterPayload {
    dashboardId: number;
    elementId: number;
    periodGrouping: PeriodGrouping;
}

export interface SetDashboardElementCategoryFilterPayload {
    dashboardId: number;
    elementId: number;
    categories: number[];
}

export interface SetDashboardElementSearchFilterPayload {
    dashboardId: number;
    elementId: number;
    search: string[];
}

export interface ElementExportFinishWebSocketMessagePayload {
    opaqueId: string;
    title: string;
    message: string;
    action: string;
}