import {
    call, CallEffect,
    cancel,
    CancelEffect,
    cancelled,
    delay,
    fork,
    ForkEffect,
    put,
    PutEffect,
    SelectEffect,
    CancelledEffect,
    select,
    takeEvery,
    takeLatest
} from 'redux-saga/effects';
import {AppState} from '../../../types/Types';
import {Route} from '../../../api/Api';
import {
    addApplicationMessage,
    denormalize,
    FetchStatus,
    logger,
    postRequest,
    Request,
    RequestResponse, SnackbarVariant,
    spawnSagas
} from '@software/reactcommons';
import {
    ComparisonPeriod,
    CustomPeriod,
    DashboardElement,
    DashboardElementConfiguration, LoadDashboardElementResponse,
    Period,
    PeriodToDefaultComparisonPeriod,
} from '../../reducer/dashboard/types';
import {
    DashboardElementPayload,
    downloadChartAction,
    DownloadChartPayload,
    downloadElementAction,
    downloadElementExportAction,
    DownloadElementExportActionKey,
    DownloadElementPayload,
    ElementExportFinishWebSocketMessagePayload,
    exportElementActionAndSaga,
    loadDashboardActionAndSaga,
    loadDashboardsActionAndSaga,
    loadElementWithConfigAction,
    LoadElementWithConfigPayload,
    SearchTableActionKey,
    SearchTableActionPayload,
    SetDashboardElementVisiblePayload,
    SyncDashboardDataActionKey,
    syncElementConfigAction,
    UpdateElementPagePayload
} from '../../reducer/dashboard/actions';
import {PayloadAction} from '@reduxjs/toolkit';
import {saveAs} from 'file-saver';
import {CancelAllSagasViaForcedTabLogoutActionKey, jwtSelector, logoutAction} from '@software/reactcommons-security';
import {
    setGlobalRefreshTimestamp, setTableSearch,
    updateElementDataActive,
    updateElementDataError,
    updateElementDataSuccess
} from '../../reducer/dashboard/dashboard';
import {Task} from 'redux-saga';
import {DateTime} from 'luxon';

/**
 * Helper method which converts the predefined periods (e.g. today, yesterday etc.) into a start and end timestamp in UTC
 * timezone.
 */
export const getIntervalForSelectedPeriod = ({
                                                 selectedPeriod,
                                                 periods
                                             }: { selectedPeriod: Period, periods: CustomPeriod[] }) => {
    let startTimestamp;
    let endTimestamp;
    switch (selectedPeriod) {
        case Period.Today:
            startTimestamp = DateTime.fromObject({hour: 0, minute: 0, second: 0, millisecond: 0});
            endTimestamp = DateTime.fromObject({hour: 23, minute: 59, second: 59, millisecond: 0});
            break;
        case Period.LastDay:
            startTimestamp = DateTime.fromObject({hour: 0, minute: 0, second: 0, millisecond: 0}).minus({days: 1});
            endTimestamp = DateTime.fromObject({hour: 23, minute: 59, second: 59, millisecond: 0}).minus({days: 1});
            break;
        case Period.LastWeek:
            startTimestamp = DateTime.fromObject({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0
            }).minus({weeks: 1}).set({weekday: 1});
            endTimestamp = DateTime.fromObject({
                hour: 23,
                minute: 59,
                second: 59,
                millisecond: 0
            }).minus({weeks: 1}).set({weekday: 7});
            break;
        case Period.LastMonth:
            startTimestamp = DateTime.fromObject({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0
            }).minus({months: 1}).set({day: 1});
            endTimestamp = DateTime.fromObject({
                hour: 23,
                minute: 59,
                second: 59,
                millisecond: 0
            }).set({day: 1}).minus({days: 1});
            break;
        case Period.CalendarWeek:
            startTimestamp = DateTime.fromObject({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0
            }).minus({weeks: 12}).set({weekday: 1});
            endTimestamp = DateTime.fromObject({hour: 23, minute: 59, second: 59, millisecond: 0});
            break;
        case Period.LastQuarter:
            startTimestamp = DateTime.fromObject({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0
            }).minus({months: 3}).startOf('quarter');
            endTimestamp = DateTime.fromObject({
                hour: 23,
                minute: 59,
                second: 59,
                millisecond: 0
            }).minus({months: 3}).endOf('quarter').set({millisecond: 0});
            break;
        case Period.Custom:
        case Period.Day:
            startTimestamp = DateTime.fromMillis(periods[0]?.startTimestamp ?? DateTime.now().valueOf()).set({
                minute: 0,
                second: 0,
                hour: 0,
                millisecond: 0
            });
            // Assure that the last second of the day is used
            endTimestamp = DateTime.fromMillis(periods[0]?.endTimestamp ?? DateTime.now().valueOf()).set({
                hour: 23,
                minute: 59,
                second: 59,
                millisecond: 0
            });
            break;
        case Period.YearToDate:
            startTimestamp = DateTime.now().set({
                // Ordinal = day of year, starting by 1
                ordinal: 1,
                minute: 0,
                second: 0,
                hour: 0,
                millisecond: 0
            });
            endTimestamp = DateTime.now();
            break;
        case Period.LastYear:
            startTimestamp = DateTime.now().minus({year: 1}).set({
                // Ordinal = day of year, starting by 1
                ordinal: 1,
                minute: 0,
                second: 0,
                hour: 0,
                millisecond: 0
            });
            // Jump to last millisecond of previous year
            endTimestamp = DateTime.now().set({
                // Ordinal = day of year, starting by 1
                ordinal: 1,
                minute: 0,
                second: 0,
                hour: 0,
                millisecond: 0
            }).minus({millisecond: 1});
            break;
        case Period.Total:
        default:
            break;
    }
    if (startTimestamp && endTimestamp) {
        return [{
            startTimestamp: startTimestamp.toUTC().valueOf(),
            endTimestamp: endTimestamp.toUTC().valueOf()
        }];
    }
    return [];
};

export const getIntervalForSelectedComparisonPeriod = ({
                                                           periodStartTimestamp,
                                                           periodEndTimestamp,
                                                           selectedComparisonPeriod,
                                                           periods
                                                       }: {
    periodStartTimestamp?: number, periodEndTimestamp?: number,
    selectedPeriod: Period, selectedComparisonPeriod: ComparisonPeriod, periods?: CustomPeriod[]
}) => {
    let startTimestamp;
    let endTimestamp;
    const periodStart = periodStartTimestamp ? DateTime.fromMillis(periodStartTimestamp) : DateTime.now();
    const periodEnd = periodEndTimestamp ? DateTime.fromMillis(periodEndTimestamp) : DateTime.now();
    switch (selectedComparisonPeriod) {
        case ComparisonPeriod.PreviousDay:
            startTimestamp = periodStart.minus({days: 1}).set({minute: 0, second: 0, hour: 0, millisecond: 0});
            endTimestamp = periodEnd.minus({days: 1}).set({minute: 59, second: 59, hour: 23, millisecond: 0});
            break;
        case ComparisonPeriod.PreviousWeek:
            startTimestamp = periodStart.minus({weeks: 1}).set({
                weekday: 1,
                minute: 0,
                second: 0,
                hour: 0,
                millisecond: 0
            });
            endTimestamp = periodEnd.minus({weeks: 1}).set({
                weekday: 7,
                minute: 59,
                second: 59,
                hour: 23,
                millisecond: 0
            });
            break;
        case ComparisonPeriod.PreviousMonth:
            startTimestamp = periodStart.minus({months: 1}).set({
                day: 1,
                minute: 0,
                second: 0,
                hour: 0,
                millisecond: 0
            });
            endTimestamp = periodEnd.set({day: 1}).minus({days: 1}).set({
                minute: 59,
                second: 59,
                hour: 23,
                millisecond: 0
            });
            break;
        case ComparisonPeriod.PreviousQuarter:
            startTimestamp = periodStart.minus({months: 3}).startOf('quarter').set({
                minute: 0,
                second: 0,
                hour: 0,
                millisecond: 0
            });
            endTimestamp = periodEnd.minus({months: 3}).endOf('quarter').set({
                minute: 59,
                second: 59,
                hour: 23,
                millisecond: 0
            });
            break;
        case ComparisonPeriod.PreviousYear:
            startTimestamp = periodStart.minus({year: 1}).startOf('year').set({
                minute: 0,
                second: 0,
                hour: 0,
                millisecond: 0
            });
            endTimestamp = periodEnd.minus({year: 1}).endOf('year').set({
                minute: 59,
                second: 59,
                hour: 23,
                millisecond: 0
            });
            break;
        case ComparisonPeriod.YearToDate:
            startTimestamp = DateTime.now().set({
                // Ordinal = day of year, starting by 1
                ordinal: 1,
                minute: 0,
                second: 0,
                hour: 0,
                millisecond: 0
            });
            endTimestamp = DateTime.now();
            break;
        case ComparisonPeriod.Custom:
        case ComparisonPeriod.Day:
            startTimestamp = DateTime.fromMillis(periods?.[0]?.startTimestamp ?? DateTime.now().valueOf()).set({
                minute: 0,
                second: 0,
                hour: 0,
                millisecond: 0
            });
            // Assure that the last second of the day is used
            endTimestamp = DateTime.fromMillis(periods?.[0]?.endTimestamp ?? DateTime.now().valueOf()).set({
                minute: 59,
                second: 59,
                hour: 23,
                millisecond: 0
            });
            break;
        default:
            break;
    }
    if (startTimestamp && endTimestamp) {
        return [{
            startTimestamp: startTimestamp.toUTC().toMillis(),
            endTimestamp: endTimestamp.toUTC().toMillis()
        }];
    }
    return [];
};

export function* setElementVisible(action: PayloadAction<SetDashboardElementVisiblePayload>): Generator<CallEffect | ForkEffect, void, any> {
    if (action.payload.visible) {
        yield call(cancelElementSyncTasks, action.payload.id);
        const task = yield fork(syncElementConfig, {
            id: action.payload.id,
            dashboard: action.payload.dashboardId
        }, true);
        syncByElementId.push({id: action.payload.id, task});
    }
}


export function* setElementVisibleSaga(): Generator<ForkEffect, void, void> {
    yield takeEvery('dashboard/setDashboardElementVisible', setElementVisible);
}

/**
 * Saga forks the element data fetching and cancels it after 2 minutes if it is not finished yet.
 *
 * @param id The ID of the element for which the data should be loaded.
 * @param dashboard The ID of the dashboard the element belongs to.
 * @param merge Whether the response should be merged into the current state
 * @returns {Generator<any, void, ?>}
 */
export function* loadElementDataWithTimeout(id: number, dashboard: number, merge?: boolean): Generator<ForkEffect | CallEffect<true> | CancelEffect, void, Task> {
    // Fork fetch saga to enable refresh of data multiple elements at the same time
    const loadData = yield fork(fetchElementData, id, dashboard, merge);
    // Wait two minutes for a request timeout.
    yield delay(120000);
    // Cancel data loading if it is not finished yet.
    yield cancel(loadData);
}


export function* loadElementDataWithConfig({payload}: PayloadAction<LoadElementWithConfigPayload>): Generator<SelectEffect | PutEffect<PayloadAction<any>> | CallEffect | CancelledEffect, void, boolean & string & RequestResponse<DashboardElementConfiguration>> {
    let request: Request<any> | undefined = undefined;
    try {
        const jwt: string = yield select(jwtSelector);

        yield put(updateElementDataActive({id: payload.id, dashboard: payload.dashboardId}));
        // Select the current active config of the element
        const periods = getIntervalForSelectedPeriod(payload.config);
        let usedPage = 0;
        let usedPageSize = 25;
        request = postRequest(Route.LoadElement(payload.id), {
            body: {
                config: {...payload.config, periods, comparisonPeriods: []},
                periods,
                page: usedPage,
                pageSize: usedPageSize,
                updateConfig: !payload.keepOriginalPeriod
            },
            jwt
        })
        // Load element data is a post request, post the configuration and the start and end timestamp of the selected period
        let {response}: RequestResponse<LoadDashboardElementResponse> = yield call(request.request);
        // Tell the store that loading was finished and inject the response into the state
        const config = {
            ...response.elementConfig,
            chart: response.chart,
            n: response.n,
            additionalExplanations: response.additionalExplanations,
            tooFewRespondents: response.tooFewRespondents
        };
        if (payload.keepOriginalPeriod) {
            const {originalPeriods, selectedPeriod} = yield select((state: AppState) => ({
                originalPeriods: state.dashboard.dashboards[payload.dashboardId]?.elements[payload.id]?.config?.periods || [],
                selectedPeriod: state.dashboard.dashboards[payload.dashboardId]?.elements[payload.id]?.config?.selectedPeriod
            }));
            config.periods = originalPeriods;
            config.selectedPeriod = selectedPeriod;
        }
        yield put(updateElementDataSuccess({
            id: payload.id,
            dashboard: payload.dashboardId,
            config,
            merge: false
        }));
    } catch (e) {
        logger.error('Error in load element config!', e);
        // Tell the store that an error occurred
        yield put(updateElementDataError({
            id: payload.id,
            dashboard: payload.dashboardId
        }));
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* loadElementDataWithConfigSaga() {
    yield takeEvery(loadElementWithConfigAction.actionKey, loadElementDataWithConfig);
}

/**
 * Saga which loads the data of an element based on the configuration of the element.
 *
 * @param id The ID of the element.
 * @param dashboard The ID of the dashboard the element belongs.
 * @param merge Optional parameter which indicates whether a lazy loading happens and the response should be merged into the element state.
 * @returns {Generator<any, any, any>}
 */
export function* fetchElementData(id: number, dashboard: number, merge: boolean = false): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const jwt: string = yield select(jwtSelector);
        // Select the current active config of the element
        const element: DashboardElement = yield select((state: AppState) => state.dashboard.dashboards[dashboard]?.elements[id]);
        if (element?.config) {
            // Tell the store that the element is currently fetching data.
            yield put(updateElementDataActive({id, dashboard}));
            let config = element.config;
            const periods = getIntervalForSelectedPeriod(config);
            let comparisonPeriods;
            const originalComparison = config.comparison;
            const originalComparisonPeriods = config.comparisonPeriods;
            if (Boolean(config.comparison) && (periods[0] || (config.selectedPeriod ?? Period.Total) === Period.Total)) {
                const comparisonPeriod = config.comparison?.period ?? PeriodToDefaultComparisonPeriod[config.selectedPeriod ?? Period.Total] ?? ComparisonPeriod.Total;
                comparisonPeriods = config.comparisonPeriods?.length ? config.comparisonPeriods : getIntervalForSelectedComparisonPeriod({
                    periods: config.comparisonPeriods,
                    periodStartTimestamp: periods[0]?.startTimestamp,
                    periodEndTimestamp: periods[0]?.endTimestamp,
                    selectedComparisonPeriod: comparisonPeriod,
                    selectedPeriod: config.selectedPeriod,
                });
                // Inject the comparison period into the config
                config = {
                    ...config,
                    comparison: {
                        ...config.comparison,
                        period: comparisonPeriod
                    }
                }
            }
            let usedPage = element.page;
            let usedPageSize = element.pageSize ?? 25;
            // If the result should not be merged but a page is selected
            // make sure that at least the number of pages is reloaded
            if (!merge && element.page) {
                usedPage = 0;
                usedPageSize = (element.page + 1) * usedPageSize;
            }
            request = postRequest(Route.LoadElement(id), {
                jwt,
                body: {
                    config: {...config, periods, comparisonPeriods},
                    periods,
                    page: usedPage,
                    pageSize: usedPageSize
                }
            })
            // Load element data is a post request, post the configuration and the start and end timestamp of the selected period
            let {response}: RequestResponse<LoadDashboardElementResponse> = yield call(request.request);
            // Tell the store that loading was finished and inject the response into the state
            yield put(updateElementDataSuccess({
                id, dashboard, config: {
                    ...response.elementConfig,
                    n: response.n,
                    chart: response.chart,
                    additionalExplanations: response.additionalExplanations,
                    tooFewRespondents: response.tooFewRespondents,
                    // Restore comparison
                    comparison: originalComparison,
                    comparisonPeriods: originalComparisonPeriods
                }, merge
            }));
        }
    } catch (e) {
        logger.error('Error while fetching element data!', (e as Error).stack);
        // An error occurred during loading, tell the store.
        yield put(updateElementDataError({id, dashboard}));
    } finally {
        // Check if generator was cancelled, this happens if the request has endured too long.
        if (yield cancelled()) {
            yield put(updateElementDataError({id, dashboard}));
            request?.abort();
        }
    }
}

interface SyncEffectById {
    id: number;
    task: Task;
}

let syncByElementId: SyncEffectById[] = [];

/**
 * Saga to synchronize the configuration for an element with the backend and load the element data.
 *
 * @returns {Generator<any, any, any>}
 */
export function* syncElementConfig({
                                       id,
                                       dashboard
                                   }: DashboardElementPayload, useFork: boolean = true): Generator<any, any, any> {
    let forkTask;
    // Get the ID of the selected dashboard
    let usedDashboard: number = dashboard ?? -1;
    if (usedDashboard < 0) {
        usedDashboard = yield select((state: AppState) => state.dashboard.selectedDashboard);
    }
    yield put(syncElementConfigAction.startAction({id, dashboard: usedDashboard}));
    try {
        if (useFork) {
            // Fork the fetching of the element data with the new configuration.
            forkTask = yield fork(loadElementDataWithTimeout, id, usedDashboard);
        } else {
            yield call(fetchElementData, id, usedDashboard);
        }
        // Tell the store that the element has been synced.
        yield put(syncElementConfigAction.successAction({id, dashboard: usedDashboard}));
    } catch (e: any) {
        logger.error(e);
        // An error occurred either during element data loading or configuration syncing, tell the store
        yield put(syncElementConfigAction.errorAction({
            id,
            dashboard: usedDashboard,
            message: e.message
        }));
    } finally {
        if (yield cancelled()) {
            if (forkTask) {
                yield cancel(forkTask);
            }
        }
    }
}

export function* loadElementPage(action: PayloadAction<UpdateElementPagePayload>): Generator<SelectEffect | ForkEffect<void>, any, number> {
    let usedDashboard: number = action.payload.dashboard || -1;
    if (usedDashboard < 0) {
        usedDashboard = yield select((state: AppState) => state.dashboard.selectedDashboard);
    }
    // Load the element data and merge the response into the current state of the element
    yield fork(loadElementDataWithTimeout, action.payload.id, usedDashboard, true);
}

export function* loadElementPageSaga() {
    yield takeEvery('dashboard/updateElementPage', loadElementPage);
}

export function* cancelElementSyncTasks(id: number) {
    const existing = syncByElementId.filter(it => it.id === id);
    for (const it of existing) {
        yield cancel(it.task);
    }
    syncByElementId = syncByElementId.filter(it => it.id !== id);
}

export function* syncElementConfigViaAction(action: PayloadAction<DashboardElementPayload>): Generator<CallEffect | CancelEffect | ForkEffect, any, any> {
    yield call(cancelElementSyncTasks, action.payload.id);
    const task = yield fork(syncElementConfig, action.payload);
    syncByElementId.push({
        id: action.payload.id,
        task
    });
}

/**
 * Saga which reacts on every sync element configuration action and starts the synchronization.
 *
 * @returns {Generator<any, any, any>}
 */
export function* syncElementConfigViaActionSaga(): Generator<any, any, any> {
    yield takeEvery(syncElementConfigAction.actionKey, syncElementConfigViaAction);
}

export function* globalConfigurationSyncAndLoad(): Generator<CallEffect | ForkEffect | SelectEffect, any, any> {
    // Add short delay to prevent duplicate loading if action gets fired twice
    yield delay(100);
    // Select the elements of the current active dashboard, if the dashboard is not found, an empty object is returned.
    const {
        elements, dashboard
    } = yield select((state: AppState) => ({
        elements: state.dashboard.dashboards[state.dashboard.selectedDashboard]?.elements || {},
        dashboard: state.dashboard.selectedDashboard
    }));
    // Load data for every element
    for (const elementId of Object.keys(elements)) {
        yield call(cancelElementSyncTasks, Number(elementId));
        const task = yield fork(syncElementConfig, {id: Number(elementId), dashboard});
        syncByElementId.push({
            id: Number(elementId),
            task
        })
    }
}

/**
 * Generator which is called when the user forces a reload and sync of dashboard configuration via the global configuration
 * buttons.
 *
 * @returns {Generator<any, any, any>}
 */
export function* globalConfigurationSyncAndLoadSaga(): Generator<any, any, any> {
    yield takeLatest(['dashboard/updateAllElementsCustomPeriod', 'dashboard/updateAllElementsPeriod', 'dashboard/resetGlobalFilter',
        'dashboard/applyGlobalFilter', 'dashboard/setAllElementComparisonPeriod', 'dashboard/setAllElementCustomComparisonPeriod',
        'dashboard/resetAllElementsSelectedComparisonPeriod', 'dashboard/removeAllElementsSelectedComparisonPeriod'], globalConfigurationSyncAndLoad);
}


let activeSyncTasks: Task[] = [];

export const getActiveSyncTasksLength = () => activeSyncTasks.length;
export const addActiveSyncTask = (task: Task) => activeSyncTasks.push(task);

export function* syncDashboardData(): Generator<CancelEffect | SelectEffect | ForkEffect<void>, any, any> {
    try {
        // Cancel all active requests
        if (activeSyncTasks.length) {
            yield cancel(activeSyncTasks);
        }

        activeSyncTasks = [];
        // Select the current active dashboard and active elements from state
        const {dashboard, elements} = yield select((state: AppState) => ({
            dashboard: state.dashboard.selectedDashboard,
            elements: state.dashboard.dashboards[state.dashboard.selectedDashboard]?.elements || {}
        }));
        const usedKeys = Object.keys(elements).filter(it => elements[it].visible);
        // Load data for every element
        for (const elementID of usedKeys) {
            activeSyncTasks.push(yield fork(loadElementDataWithTimeout, Number(elementID), dashboard));
        }
    } catch (e) {
        logger.error(e);
    }
}

/**
 * Saga which loads the data for each element of the current active dashboard if triggered via action.
 *
 * @returns {Generator<any, any, any>}
 */
export function* syncDashboardDataSaga(): Generator<any, any, any> {
    // Wait for action which triggers the loading of every element.
    yield takeLatest(SyncDashboardDataActionKey, syncDashboardData);
}

/**
 * Saga which re-loads the data of each active dashboard element periodically.
 *
 * @returns {Generator<any, any, any>}
 */
export function* periodicElementDataLoading(): Generator<CallEffect<any> | CancelEffect | SelectEffect | PutEffect<any>, any, any> {
    try {
        while (true) {
            // Select the current active dashboard, the active elements and whether the dashboard is currently syncing from the state
            const {dashboard, elements, isSyncing, timeout} = yield select((state: AppState) => ({
                dashboard: state.dashboard.selectedDashboard,
                elements: state.dashboard.dashboards[state.dashboard.selectedDashboard]?.elements,
                isSyncing: state.dashboard.selectedDashboard > 0 && denormalize(state.dashboard.dashboards[state.dashboard.selectedDashboard]?.elements || {}).some((element: DashboardElement) => element.fetchStatus === FetchStatus.Active),
                timeout: state.dashboard.dashboards[state.dashboard.selectedDashboard]?.refreshInterval ?? 600000
            }));
            // Only update if dashboard is currently not syncing
            if (!isSyncing && elements) {
                // Cancel all active requests
                if (activeSyncTasks.length) {
                    yield cancel(activeSyncTasks);
                }
                activeSyncTasks = [];
                // Load data for every element
                for (const elementID of Object.keys(elements)) {
                    // For each element, fork the load saga
                    yield call(fetchElementData, Number(elementID), dashboard);
                }
                // Put the current timestamp into the state as last refresh timestamp.
                const timestamp = DateTime.now().toUTC().toMillis();
                yield put(setGlobalRefreshTimestamp({id: dashboard, timestamp}));
            }
            // Wait for the timeout to restart the saga
            yield delay(timeout);
        }
    } catch (e) {
        // Check if an error occurred, if so log it.
        logger.error(e);
    }
}

let liveModeTask: Task | undefined;

export function* enableLiveMode(action: PayloadAction<boolean>): Generator<ForkEffect | CancelEffect, void, Task | undefined> {
    if (action.payload) {
        // Check if the live mode was not enabled recently
        if (!liveModeTask) {
            // Fork the data loading and pass the interval as parameter
            liveModeTask = yield fork(periodicElementDataLoading);
        }
    } else if (liveModeTask) {
        // User disabled the live mode, if generator exists, cancel the generator.
        yield cancel(liveModeTask);
        liveModeTask = undefined;
    }
}

/**
 * Saga which enables or disables the live mode of the dashboard.
 * @returns {Generator<any, any, any>}
 */
export function* enableLiveModeSaga(): Generator<any, any, any> {
    yield takeLatest('dashboard/enableLiveMode', enableLiveMode);
}

export function* searchTable(action: PayloadAction<SearchTableActionPayload>) {
    yield delay(500);
    yield put(setTableSearch(action.payload));
}

export function* searchTableSaga() {
    yield takeLatest(SearchTableActionKey, searchTable);
}

export function* downloadChart({payload}: PayloadAction<DownloadChartPayload>): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const jwt: string = yield select(jwtSelector);
        yield put(downloadChartAction.startAction(payload));
        request = postRequest(Route.DownloadChart, {
            body: {
                type: payload.type,
                config: payload.configuration
            },
            jwt
        })
        const {response}: RequestResponse<Blob> = yield call(request.request);
        saveAs(response, `${DateTime.now().toFormat('yyyyMMdd')}-${payload.name.replace(/ /g, '_')}`);
        yield put(downloadChartAction.successAction(payload));
    } catch (e: any) {
        logger.error(e);
        yield put(downloadChartAction.errorAction({
            ...payload,
            message: e.message
        }));
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* downloadChartSaga() {
    yield takeEvery(downloadChartAction.actionKey, downloadChart);
}

export function* downloadElement({payload}: PayloadAction<DownloadElementPayload>): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const jwt: string = yield select(jwtSelector);
        const element = yield select((state: AppState) => state.dashboard.dashboards[state.dashboard.selectedDashboard]?.elements[payload.id]);
        if (element?.config) {
            const config = element.config;
            const periods = getIntervalForSelectedPeriod(config);
            yield put(downloadElementAction.startAction(payload));
            request = postRequest(Route.DownloadElement(payload.id), {
                body: {
                    config,
                    periods
                },
                jwt
            })
            const {response}: RequestResponse<Blob> = yield call(request.request);
            saveAs(response, `${DateTime.now().toFormat('yyyyMMdd')}-${element.name.replace(' ', '_').replace('/', '_')}.zip`);
            yield put(downloadElementAction.successAction(payload));
        }
    } catch (e: any) {
        logger.error(e);
        yield put(downloadElementAction.errorAction({
            ...payload,
            message: e.message
        }));
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* downloadElementSaga() {
    yield takeEvery(downloadElementAction.actionKey, downloadElement);
}

export function downloadElementExport(opaqueId: string) {
    try {
        window.open(Route.Dashboards.Export.DownloadExportElement(opaqueId), '_self');
    } catch (e) {
        logger.error('Cannot download element export', e);
    }
}


export function* downloadElementExportSaga() {
    yield takeEvery(DownloadElementExportActionKey, function ({payload}: PayloadAction<string>) {
        downloadElementExport(payload);
    });
}

export function* handleElementExportSucceededWebSocketNotificationSaga() {
    yield takeEvery('dashboards/webSocketExportCompleteMessage', function* (action: PayloadAction<ElementExportFinishWebSocketMessagePayload>) {
        yield put(addApplicationMessage({
            key: new Date().getTime().toString(),
            title: action.payload.title,
            text: action.payload.message,
            variant: SnackbarVariant.Success,
            autoHideDuration: -1,
            action: {
                title: action.payload.action,
                reduxAction: downloadElementExportAction(action.payload.opaqueId)
            }
        }))
    })
}


/**
 * The saga handles all incoming web socket messages.
 *
 * @return {Generator<ForkEffect | * | void>}
 */
function* DashboardSaga(): Generator<any, void, void> {
    yield call(spawnSagas([
        {
            generator: syncElementConfigViaActionSaga
        }, {
            generator: syncDashboardDataSaga
        }, {
            generator: globalConfigurationSyncAndLoadSaga
        }, {
            generator: enableLiveModeSaga
        }, {
            generator: searchTableSaga
        }, {
            generator: downloadChartSaga
        }, {
            generator: loadElementPageSaga
        }, {
            generator: downloadElementSaga
        }, {
            generator: setElementVisibleSaga
        }, {
            generator: loadElementDataWithConfigSaga
        },
        {generator: loadDashboardsActionAndSaga.saga},
        {generator: loadDashboardActionAndSaga.saga},
        {generator: exportElementActionAndSaga.saga},
        {generator: downloadElementExportSaga},
        {generator: handleElementExportSucceededWebSocketNotificationSaga},
    ], [logoutAction.actionKey, CancelAllSagasViaForcedTabLogoutActionKey]));
}

export default DashboardSaga;