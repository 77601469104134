import {ActionReducerMapBuilder, Draft} from '@reduxjs/toolkit';
import {VideoState, VideoWish, VideoWishVote} from './types';
import {
    createVideoWishAction,
    loadVideoWishesAction,
    upvoteWishAction,
    videoWishCreated,
    videoWishVoteCreated,
    videoWishVoteDeleted
} from './actions';
import {FetchStatus, normalize, notUndefined} from '@software/reactcommons';
import {markdownConverter} from '../../../constants/Constants';


const mergeWishIntoState = (state: Draft<VideoState>, wish: VideoWish) => {
    // Check if wish does not exist yet (e.g. web socket connection was faster than http response)
    if (!state.wishes.elements[wish.id]) {
        state.wishes.elements[wish.id] = {
            ...wish,
            description: markdownConverter.makeHtml(wish.description),
            upvoteFetchStatus: FetchStatus.Default
        };
        // Add wish to the beginning of the latest array
        state.wishes.latest.unshift(wish.id);
        // Append wish to the end of popular array
        state.wishes.popular.push(wish.id);
        state.wishes.createFetchStatus = FetchStatus.Success;
    }
};

const reduceWishVote = (state: VideoState, vote: VideoWishVote, created: boolean) => {
    let votes = state.wishes.elements[vote.wishID]!.votes;
    if (created) {
        // Only add the vote if it has not been added yet
        if (!votes.some(it => it.id === vote.id)) {
            votes.unshift(vote);
        }
    } else {
        state.wishes.elements[vote.wishID]!.votes = votes.filter(it => it.id !== vote.id);
    }
    // Sort the popular wishes by their new vote count
    state.wishes.popular = state.wishes.popular.map(it => state.wishes.elements[it]).filter(notUndefined)
        .sort((w1, w2) => w2.votes.length - w1.votes.length).map(it => it.id)
}

export const wishReducers = {};

export const extraWishReducers = (builder: ActionReducerMapBuilder<VideoState>) =>
    builder.addCase(loadVideoWishesAction.startAction, state => {
        state.wishes.fetchStatus = FetchStatus.Active;
    }).addCase(loadVideoWishesAction.errorAction, state => {
        state.wishes.fetchStatus = FetchStatus.Error;
    }).addCase(loadVideoWishesAction.successAction, (state, action) => {
        state.wishes.fetchStatus = FetchStatus.Success;
        state.wishes.latest = action.payload.newestWishes;
        state.wishes.popular = action.payload.popularWishIds;
        state.wishes.finished = action.payload.finishedWishIds;
        state.wishes.elements = {
            ...state.wishes.elements,
            ...normalize(action.payload.all.map(it => ({
                ...it,
                upvoteFetchStatus: FetchStatus.Default,
                description: markdownConverter.makeHtml(it.description)
            })))
        };
    }).addCase(createVideoWishAction.startAction, (state, action) => {
        state.wishes.createFetchStatus = FetchStatus.Active;
    }).addCase(createVideoWishAction.errorAction, (state, action) => {
        state.wishes.createFetchStatus = FetchStatus.Error;
    }).addCase(createVideoWishAction.resetAction, (state, action) => {
        state.wishes.createFetchStatus = FetchStatus.Default;
    }).addCase(createVideoWishAction.successAction, (state, action) => {
        mergeWishIntoState(state, action.payload);
    }).addCase(upvoteWishAction.startAction, (state, action) => {
        const wish = state.wishes.elements[action.payload.id];
        if (wish) {
            wish.upvoteFetchStatus = FetchStatus.Active;
        }
    }).addCase(upvoteWishAction.errorAction, (state, action) => {
        const wish = state.wishes.elements[action.payload.id];
        if (wish) {
            wish.upvoteFetchStatus = FetchStatus.Error;
        }
    }).addCase(upvoteWishAction.successAction, (state, action) => {
        reduceWishVote(state, {
            ...action.payload.vote,
            wishID: action.payload.id
        }, action.payload.voted);
    }).addCase(videoWishCreated, (state, action) => {
        mergeWishIntoState(state, action.payload.wish);
    }).addCase(videoWishVoteCreated, (state, action) => {
        reduceWishVote(state, action.payload.wishVote, true)
    }).addCase(videoWishVoteDeleted, (state, action) => {
        reduceWishVote(state, {
            wishID: action.payload.wishID,
            id: action.payload.wishVoteID
        } as VideoWishVote, false)
    });