import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ConnectionState, OnlineState} from './types';
import {SetOnlineStatePayload, webSocketConnectionClosed, webSocketConnectionOpened} from '../application/actions';


export const initialConnectionState: ConnectionState = {
    online: {},
    webSocketState: {}
};

const connection = createSlice({
    name: 'connection',
    initialState: initialConnectionState,
    reducers: {
        setOnlineState: (state, action: PayloadAction<SetOnlineStatePayload>) => {
            state.online[action.payload.url] = {
                state: action.payload.state,
                offlineReason: action.payload.offlineReason
            };
        }
    },
    extraReducers: builder => builder.addCase(webSocketConnectionOpened, (state, action) => {
        state.webSocketState[action.payload] = OnlineState.Online;
    }).addCase(webSocketConnectionClosed, (state, action) => {
        state.webSocketState[action.payload] = OnlineState.Offline;
    })
});

export const {setOnlineState} = connection.actions;

export default connection.reducer;