import {all, call, CallEffect, delay, put, select, takeLatest} from 'redux-saga/effects';
import {
    checkDownloadReportAccessActionSaga,
    loadLatestReportsActionAndSaga,
    loadReportActionAndSaga,
    loadReportsActionAndSaga,
    loadReportsByReportScopeActionAndSaga,
    loadReportScopesActionAndSaga,
    loadReportTimeSpansActionAndSaga,
} from '../../reducer/reports/actions';
import {PayloadAction} from '@reduxjs/toolkit';
import {AppState} from '../../../types/Types';
import {ActiveReport, ReportSearch} from '../../reducer/reports/types';
import {logger, spawnSagas} from '@software/reactcommons';
import {CancelAllSagasViaForcedTabLogoutActionKey, logoutAction} from '@software/reactcommons-security';
import {PDFDocumentProxy, PDFPageProxy, TextContent, TextItem} from 'pdfjs-dist/types/src/display/api';
import {setActivePdfReportPages, setActivePdfReportSearchResults, setReportSearch} from '../../reducer/reports/reports';

export function* setReportSearchText({payload}: PayloadAction<string>): Generator<any, any, any> {
    const search: ReportSearch = yield select((state: AppState) => state.reports.search);
    yield put(setReportSearch({
        ...search,
        page: 0,
        searchText: payload,
        replace: true
    }));
}

export function* setReportSearchTextSaga() {
    yield takeLatest('reports/setReportsSearchText', setReportSearchText);
}

export function* loadPdfReportPage(pdf: PDFDocumentProxy, pageIndex: number) {
    const pageData: PDFPageProxy = yield pdf.getPage(pageIndex);
    const textContent: TextContent = yield pageData.getTextContent();
    return textContent.items.map((item) => {
        return (item as TextItem).str;
    }).join(' ');
}

export function* setActiveReport({payload}: PayloadAction<ActiveReport | undefined>) {
    if (payload) {
        const pages: string[] = yield all([...new Array(payload.pdf.numPages)].map((_, index) => call(loadPdfReportPage, payload.pdf, index + 1)));
        yield put(setActivePdfReportPages(pages));
    }
}

export function* setActiveReportSaga() {
    yield takeLatest('reports/setActivePdfReport', setActiveReport);
}

export function* searchActiveReport({payload}: PayloadAction<string>): Generator<any, any, any> {
    try {
        // Wait 500ms to avoid multiple searches which lower the performance
        yield delay(500);
        if (!payload.trim()) {
            yield put(setActivePdfReportSearchResults({pages: [], usedSearch: ''}));
            return;
        }
        const pages: string[] = yield select((state: AppState) => state.reports.activeReport?.pages || []);

        const regex = payload.trim().split(' ').filter(it => it.length > 1).map(it => new RegExp(`${it}*`, 'i'));
        const updatedResults: number[] = [];

        pages.forEach((text, index) => {
            if (regex.some(it => it.test(text))) {
                updatedResults.push(index + 1);
            }
        });
        yield put(setActivePdfReportSearchResults({pages: updatedResults, usedSearch: payload}));
    } catch (e) {
        logger.error('Could not search active report!', e);
    }
}

export function* searchActiveReportSaga() {
    yield takeLatest('reports/setActivePdfReportSearch', searchActiveReport);
}

export default function* ReportSaga(): Generator<CallEffect<void>> {
    yield call(spawnSagas([
        {generator: loadReportsActionAndSaga.saga},
        {generator: loadReportScopesActionAndSaga.saga},
        {generator: loadReportTimeSpansActionAndSaga.saga},
        {generator: loadLatestReportsActionAndSaga.saga},
        {generator: loadReportsByReportScopeActionAndSaga.saga},
        {generator: loadReportActionAndSaga.saga},
        {generator: checkDownloadReportAccessActionSaga.saga},
        {generator: setReportSearchTextSaga},
        {generator: setActiveReportSaga},
        {generator: searchActiveReportSaga}
    ], [logoutAction.actionKey, CancelAllSagasViaForcedTabLogoutActionKey]));
}