import {FetchStatus, normalize} from '@software/reactcommons';
import {
    ActiveReport,
    Report,
    ReportScopeType,
    ReportSearch,
    ReportState,
    SearchReportScopeType
} from './types';
import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';
import {
    checkDownloadReportAccessActionSaga,
    loadLatestReportsActionAndSaga,
    loadReportActionAndSaga,
    loadReportsActionAndSaga,
    loadReportsByReportScopeActionAndSaga,
    loadReportScopesActionAndSaga,
    loadReportTimeSpansActionAndSaga,
    SetActivePdfReportSearchResultsPayload,
    SetPdfOutlinePayload,
} from './actions';
import {ApiListResponse} from '../administration/actions';
import {setSelectedCustomerIdAction} from '../user/actions';

export const initialReportSearch = {
    scope: {type: SearchReportScopeType.All},
    page: 0,
    pageSize: 24,
    searchText: '',
    timeSpans: []
};

export const initialReportState: ReportState = {
    elements: {},
    latest: [],
    search: initialReportSearch,
    reportsByScope: {},
    availableTimeSpans: [],
    availableScopes: [],
    visible: [],
    count: 0,
    outlines: {},
    fetchStatus: {
        load: FetchStatus.Default,
        loadSingle: FetchStatus.Default,
        scopes: FetchStatus.Default,
        timeSpans: FetchStatus.Default,
        latest: FetchStatus.Default,
        checkReportAccess: FetchStatus.Default
    }
};

const getInitialReportsBySelectedScopeState = (scope: ReportScopeType) => ({
    elements: [],
    page: 0,
    fetchStatus: FetchStatus.Default,
    totalCount: 0,
    type: scope,
    pageSize: 24
})

const reduceReportsBySelectedScope = (state: Draft<ReportState>, fetchStatus: FetchStatus, response?: ApiListResponse<Report>, page?: number) => {
    if (state.selectedReportScope !== undefined) {
        // Assure that the reportsByScope for the selected report scope exists in the state
        if (!state.reportsByScope[state.selectedReportScope]) {
            state.reportsByScope[state.selectedReportScope] = getInitialReportsBySelectedScopeState(state.selectedReportScope);
        }

        if (response) {
            state.elements = {...state.elements, ...normalize(response.elements, undefined, 'opaqueId')}
        }
        // Merge the state with the response
        state.reportsByScope[state.selectedReportScope] = {
            ...state.reportsByScope[state.selectedReportScope]!,
            fetchStatus,
            totalCount: response?.totalCount ?? state.reportsByScope[state.selectedReportScope]?.totalCount ?? 0,
            elements: [...new Set([...(state.reportsByScope[state.selectedReportScope]?.elements || []), ...(response?.elements.map(it => it.opaqueId) || [])])],
            page: page ?? state.reportsByScope[state.selectedReportScope]?.page ?? 0
        }
    }
}

const reports = createSlice({
    name: 'reports',
    initialState: initialReportState,
    reducers: {
        setSelectedReportScope: (state, action: PayloadAction<ReportScopeType | undefined>) => {
            if (action.payload !== undefined) {
                state.reportsByScope[action.payload] = (state.reportsByScope[action.payload] || getInitialReportsBySelectedScopeState(action.payload));
            }
            state.selectedReportScope = action.payload;
        },
        increaseSelectedReportScopePage: state => {
            state.reportsByScope[state.selectedReportScope!]!.page++;
        },
        setReportSearch: (state, action: PayloadAction<ReportSearch>) => {
            state.search = action.payload;
        },
        increaseReportSearchPage: state => {
            state.search.page++;
            state.search.replace = false;
        },
        setPdfOutline: (state, action: PayloadAction<SetPdfOutlinePayload>) => {
            state.outlines[action.payload.filePath] = action.payload.outline;
        },
        setActivePdfReport: (state, action: PayloadAction<ActiveReport | undefined>) => {
            if (action.payload) {
                // Use this cast since immer types have somehow a problem without casting
                state.activeReport = action.payload as Draft<ActiveReport>;
            } else {
                delete state.activeReport;
            }
        },
        setActivePdfReportSearch: (state, action: PayloadAction<string>) => {
            if (state.activeReport) {
                state.activeReport.search = action.payload;
                state.activeReport.loading = true;
                state.activeReport.activeResult = -1;
            }
        },
        setActivePdfReportPages: (state, action: PayloadAction<string[]>) => {
            if (state.activeReport) {
                state.activeReport.pages = action.payload;
            }
        },
        setActivePdfReportSearchResults: (state, action: PayloadAction<SetActivePdfReportSearchResultsPayload>) => {
            if (state.activeReport) {
                state.activeReport.searchResults = action.payload.pages;
                state.activeReport.usedSearch = action.payload.usedSearch;
                state.activeReport.loading = false;
                state.activeReport.activeResult = 0;
            }
        },
        setActivePdfReportActiveSearchResult: (state, action: PayloadAction<number>) => {
            if (state.activeReport) {
                // Avoid that active result index can be set to a higher number than the search result length
                state.activeReport.activeResult = action.payload < 0 ? state.activeReport.searchResults.length - 1 : action.payload % state.activeReport.searchResults.length;
            }
        }
    },
    extraReducers: builder =>
        builder.addCase(loadReportsActionAndSaga.startAction, (state, action) => {
            state.fetchStatus.load = FetchStatus.Active;
            state.visible = action.payload.replace ? [] : state.visible;
        }).addCase(loadReportsActionAndSaga.successAction, (state, action) => {
            state.fetchStatus.load = FetchStatus.Success;
            state.elements = {...state.elements, ...normalize(action.payload.elements, undefined, 'opaqueId')};
            state.visible = [...state.visible, ...action.payload.elements.map(it => it.opaqueId)];
            state.count = action.payload.totalCount || 0;
        }).addCase(loadReportsActionAndSaga.errorAction, (state) => {
            state.fetchStatus.load = FetchStatus.Error;
        }).addCase(loadReportsActionAndSaga.resetAction, (state) => {
            state.fetchStatus.load = FetchStatus.Default;
        }).addCase(loadReportTimeSpansActionAndSaga.startAction, state => {
            state.fetchStatus.timeSpans = FetchStatus.Active;
        }).addCase(loadReportTimeSpansActionAndSaga.errorAction, state => {
            state.fetchStatus.timeSpans = FetchStatus.Error;
            // Reset available time spans for the user because an error has occurred
            state.availableTimeSpans = [];
        }).addCase(loadReportTimeSpansActionAndSaga.successAction, (state, action) => {
            state.fetchStatus.timeSpans = FetchStatus.Success;
            state.availableTimeSpans = action.payload.elements;
        }).addCase(loadReportTimeSpansActionAndSaga.resetAction, state => {
            state.fetchStatus.timeSpans = FetchStatus.Default;
        }).addCase(loadReportScopesActionAndSaga.startAction, state => {
            state.fetchStatus.scopes = FetchStatus.Active;
        }).addCase(loadReportScopesActionAndSaga.errorAction, state => {
            state.fetchStatus.scopes = FetchStatus.Error;
            state.availableScopes = [];
        }).addCase(loadReportScopesActionAndSaga.successAction, (state, action) => {
            state.fetchStatus.scopes = FetchStatus.Success;
            state.availableScopes = action.payload.elements;
        }).addCase(loadReportScopesActionAndSaga.resetAction, state => {
            state.fetchStatus.scopes = FetchStatus.Default;
        }).addCase(loadLatestReportsActionAndSaga.startAction, state => {
            state.fetchStatus.latest = FetchStatus.Active;
        }).addCase(loadLatestReportsActionAndSaga.errorAction, state => {
            state.fetchStatus.latest = FetchStatus.Error;
        }).addCase(loadLatestReportsActionAndSaga.resetAction, state => {
            state.fetchStatus.latest = FetchStatus.Default;
        }).addCase(loadLatestReportsActionAndSaga.successAction, (state, action) => {
            state.fetchStatus.latest = FetchStatus.Success;
            state.elements = {...state.elements, ...normalize(action.payload.elements, undefined, 'opaqueId')};
            state.latest = action.payload.elements.map(it => it.opaqueId);
            state.count = action.payload.totalCount || 0;
        }).addCase(loadReportsByReportScopeActionAndSaga.startAction, (state) => {
            reduceReportsBySelectedScope(state, FetchStatus.Active);
        }).addCase(loadReportsByReportScopeActionAndSaga.successAction, (state, action) => {
            reduceReportsBySelectedScope(state, FetchStatus.Success, action.payload)
        }).addCase(loadReportsByReportScopeActionAndSaga.errorAction, state => {
            reduceReportsBySelectedScope(state, FetchStatus.Error);
        }).addCase(loadReportsByReportScopeActionAndSaga.resetAction, state => {
            reduceReportsBySelectedScope(state, FetchStatus.Default);
        }).addCase(setSelectedCustomerIdAction.startAction, () => {
            return {...initialReportState};
        }).addCase(loadReportActionAndSaga.startAction, state => {
            state.fetchStatus.loadSingle = FetchStatus.Active;
        }).addCase(loadReportActionAndSaga.successAction, (state, action: PayloadAction<Report>) => {
            state.fetchStatus.loadSingle = FetchStatus.Success;
            state.elements[action.payload.opaqueId] = action.payload;
        }).addCase(loadReportActionAndSaga.errorAction, state => {
            state.fetchStatus.loadSingle = FetchStatus.Error;
        }).addCase(loadReportActionAndSaga.resetAction, state => {
            state.fetchStatus.loadSingle = FetchStatus.Default;
        }).addCase(checkDownloadReportAccessActionSaga.startAction, state => {
            state.fetchStatus.checkReportAccess = FetchStatus.Active;
        }).addCase(checkDownloadReportAccessActionSaga.successAction, state => {
            state.fetchStatus.checkReportAccess = FetchStatus.Success;
        }).addCase(checkDownloadReportAccessActionSaga.errorAction, state => {
            state.fetchStatus.checkReportAccess = FetchStatus.Error;
        }).addCase(checkDownloadReportAccessActionSaga.resetAction, state => {
            state.fetchStatus.checkReportAccess = FetchStatus.Default;
        })
})

export const {
    setActivePdfReportSearch,
    setActivePdfReportPages,
    setActivePdfReportSearchResults,
    setActivePdfReportActiveSearchResult,
    increaseReportSearchPage,
    setActivePdfReport,
    setPdfOutline,
    setReportSearch,
    increaseSelectedReportScopePage,
    setSelectedReportScope
} = reports.actions;

export default reports.reducer;