import {ActionReducerMapBuilder, PayloadAction} from '@reduxjs/toolkit';
import {VideoState} from './types';
import {
    highlightVideoAction,
    loadVideoHighlightsAction,
    videoHighlightCreated,
    videoHighlightDeleted
} from './actions';
import {FetchStatus} from '@software/reactcommons';


export const highlightReducers = {
    setActiveVideoHighlightSliderItem: (state: VideoState, action: PayloadAction<number>) => {
        state.highlights.activeItem = action.payload;
    }
}

export const extraHighlightReducers = (builder: ActionReducerMapBuilder<VideoState>) =>
    builder.addCase(highlightVideoAction.startAction, (state, action) => {
        const video = state.videos.elements[action.payload.videoID];
        if (video) {
            video.highlightFetchStatus = FetchStatus.Active;
        }
        // Also update fetch status in admin area
        if (state.statistics.videos.elements[action.payload.videoID]) {
            state.statistics.fetchStatus.highlightFetchStatus = FetchStatus.Active;
        }
    }).addCase(highlightVideoAction.successAction, (state, action) => {
        const video = state.videos.elements[action.payload.videoID];
        if (video) {
            video.highlightFetchStatus = FetchStatus.Success;
            video.highlighted = action.payload.highlight;
            // Add or remove the video to/from highlights
            if (video.highlighted) {
                if (!state.highlights.videos.includes(action.payload.videoID)) {
                    state.highlights.videos.unshift(action.payload.videoID);
                }
            } else {
                state.highlights.videos = state.highlights.videos.filter(it => it !== action.payload.videoID);
            }
        }
        let statisticVideo = state.statistics.videos.elements[action.payload.videoID];
        if (statisticVideo) {
            state.statistics.fetchStatus.highlightFetchStatus = FetchStatus.Success;
            statisticVideo.highlighted = action.payload.highlight;
        }
    }).addCase(highlightVideoAction.errorAction, (state, action) => {
        const video = state.videos.elements[action.payload.videoID];
        if (video) {
            video.highlightFetchStatus = FetchStatus.Error;
        }
        if (state.statistics.videos.elements[action.payload.videoID]) {
            state.statistics.fetchStatus.highlightFetchStatus = FetchStatus.Error;
        }
    }).addCase(loadVideoHighlightsAction.startAction, state => {
        state.highlights.fetchStatus = FetchStatus.Active;
    }).addCase(loadVideoHighlightsAction.successAction, (state, action) => {
        state.highlights = {
            fetchStatus: FetchStatus.Success,
            videos: action.payload.map(it => it.video.videoID),
            activeItem: 0
        };
        action.payload.map(it => it.video).forEach(it => {
            state.videos.elements[it.videoID] = {
                ...(state.videos.elements[it.videoID] || {}),
                ...it
            }
        });
    }).addCase(loadVideoHighlightsAction.errorAction, state => {
        state.highlights.fetchStatus = FetchStatus.Error;
    }).addCase(videoHighlightCreated, (state, action) => {
        // Check if video is already highlighted (happens if the active use has highlighted the video)
        if (!state.highlights.videos.includes(action.payload.highlight.video.videoID)) {
            let video = {
                ...state.videos.elements[action.payload.highlight.video.videoID],
                ...action.payload.highlight.video
            };
            video.highlighted = true;

            // Add the video to the highlights
            state.highlights.videos = [action.payload.highlight.video.videoID, ...state.highlights.videos];
            // Since new video has been added, increase the active item counter
            state.highlights.activeItem++;
        }
    }).addCase(videoHighlightDeleted, (state, action) => {
        state.highlights.videos = state.highlights.videos.filter(it => it !== action.payload.videoID);
        // Only reduce the active item if the removed highlight exists and was positioned after the active highlight in the highlight array
        state.highlights.activeItem -= (state.highlights.videos.includes(action.payload.videoID) && state.highlights.videos.indexOf(action.payload.videoID) <= state.highlights.activeItem ? 1 : 0)
    });