import {FetchStatus, normalize} from '@software/reactcommons';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    LandingPageCdnFileTypeSpecificInformationType,
    LandingPageCdnFontFileTypeSpecificInformation,
    LandingPageCdnImageFileTypeSpecificInformation,
    LandingPageCdnNoneFileTypeSpecificInformation,
    LandingPageState
} from './types';
import {
    CloneLandingPageActionAndSaga,
    CreateLandingPageActionAndSaga,
    DownloadLandingPageQRCodeActionAndSaga,
    LandingPageCdnFileDTO,
    LoadLandingPageActionAndSaga,
    LoadLandingPageCdnFilesActionAndSaga,
    LoadLandingPagePreviewActionAndSaga,
    LoadLandingPagesActionAndSaga,
    UpdateLandingPageActionAndSaga,
    uploadLandingPageFilesAction
} from './actions';
import {LandingPage} from '@software/landingpageui';
import {setSelectedCustomerIdAction} from '../user/actions';

export const initialLandingPageState: LandingPageState = {
    landingPages: {
        elements: {},
        visible: [],
        page: 0,
        pageSize: 24,
        numberOfElements: 0,
        fetchStatus: FetchStatus.Default,
        orderBy: [],
        search: ''
    },
    fetchStatus: {
        load: FetchStatus.Default,
        create: FetchStatus.Default,
        update: {},
        downloadQRCode: {},
        loadLandingPage: {},
        cdnFiles: FetchStatus.Default,
        fileUpload: FetchStatus.Default,
        preview: {},
        clone: {}
    },
    created: {},
    fileUpload: {
        progress: 0,
        files: []
    },
    cdnFiles: {
        files: {},
        images: {},
        fontFamilies: {}
    },
    preview: {}
};

const landingPages = createSlice({
    name: 'landingPages',
    initialState: initialLandingPageState,
    reducers: {
        increaseLandingPageSearchPage: (state) => {
            state.landingPages.page++;
        },
        setCloneLandingPageFetchStatusSuccess: (state, action: PayloadAction<string[]>) => {
            action.payload.forEach(it => {
                state.fetchStatus.clone[it] = FetchStatus.Success;
            });
        },
        setDownloadQRCodeFetchStatusSuccess: (state, action: PayloadAction<string[]>) => {
            action.payload.forEach(it => {
                state.fetchStatus.downloadQRCode[it] = FetchStatus.Success;
            });
        },
        setUploadLandingPageFilesProgress: (state, action: PayloadAction<number>) => {
            state.fileUpload.progress = action.payload;
        },
        resetUploadLandingPageFiles: (state) => {
            state.fileUpload.files = [];
        },
        setLandingPageLayoutDisplayPreview: (state, action: PayloadAction<{landingPage: LandingPage; opaqueId: string}>) => {
            state.fetchStatus.preview[action.payload.opaqueId] = FetchStatus.Success;
            state.preview[action.payload.opaqueId] = action.payload.landingPage;
        },
        resetCreateLandingPage: (state) => {
            state.created = {...initialLandingPageState.created};
        }
    },
    extraReducers: builder =>
        builder.addCase(LoadLandingPagesActionAndSaga.startAction, (state) => {
            state.fetchStatus.load = FetchStatus.Active;
        }).addCase(LoadLandingPagesActionAndSaga.errorAction, (state) => {
            state.fetchStatus.load = FetchStatus.Error;
        }).addCase(LoadLandingPagesActionAndSaga.resetAction, (state) => {
            state.fetchStatus.load = FetchStatus.Default;
        }).addCase(LoadLandingPagesActionAndSaga.successAction, (state, action) => {
            state.fetchStatus.load = FetchStatus.Success;
            state.landingPages.elements = {...state.landingPages.elements, ...normalize(action.payload.entities, undefined, 'opaqueId')};
            state.landingPages.numberOfElements = action.payload.totalCount;
            state.landingPages.visible = [...state.landingPages.visible, ...action.payload.entities.map(it => it.opaqueId)];
        }).addCase(LoadLandingPageActionAndSaga.startAction, (state, action) => {
            state.fetchStatus.loadLandingPage[action.payload.opaqueId] = FetchStatus.Active;
        }).addCase(LoadLandingPageActionAndSaga.errorAction, (state, action) => {
            state.fetchStatus.loadLandingPage[action.payload.opaqueId] = FetchStatus.Error;
        }).addCase(LoadLandingPageActionAndSaga.resetAction, (state, action) => {
            state.fetchStatus.loadLandingPage[action.payload.opaqueId] = FetchStatus.Default;
        }).addCase(LoadLandingPageActionAndSaga.successAction, (state, action) => {
            state.fetchStatus.loadLandingPage[action.payload.opaqueId] = FetchStatus.Success;
            state.landingPages.elements[action.payload.opaqueId] = action.payload;
        }).addCase(CreateLandingPageActionAndSaga.startAction, (state) => {
            state.fetchStatus.create = FetchStatus.Active;
        }).addCase(CreateLandingPageActionAndSaga.errorAction, (state) => {
            state.fetchStatus.create = FetchStatus.Error;
        }).addCase(CreateLandingPageActionAndSaga.resetAction, (state) => {
            state.fetchStatus.create = FetchStatus.Default;
        }).addCase(CreateLandingPageActionAndSaga.successAction, (state, action) => {
            state.fetchStatus.create = FetchStatus.Success;
            state.landingPages.elements[action.payload.opaqueId] = action.payload;
            state.created.id = action.payload.opaqueId;
        }).addCase(DownloadLandingPageQRCodeActionAndSaga.startAction, (state, action) => {
            action.payload.opaqueIds.forEach(it => {
                state.fetchStatus.downloadQRCode[it] = FetchStatus.Active;
            });
        }).addCase(DownloadLandingPageQRCodeActionAndSaga.errorAction, (state, action) => {
            action.payload.opaqueIds.forEach(it => {
                state.fetchStatus.downloadQRCode[it] = FetchStatus.Error;
            });
        }).addCase(DownloadLandingPageQRCodeActionAndSaga.resetAction, (state, action) => {
            action.payload.opaqueIds.forEach(it => {
                state.fetchStatus.downloadQRCode[it] = FetchStatus.Default;
            });
        }).addCase(UpdateLandingPageActionAndSaga.startAction, (state, action) => {
            state.fetchStatus.update[action.payload.opaqueId] = FetchStatus.Active;
        }).addCase(UpdateLandingPageActionAndSaga.errorAction, (state, action) => {
            state.fetchStatus.update[action.payload.opaqueId] = FetchStatus.Error;
        }).addCase(UpdateLandingPageActionAndSaga.resetAction, (state, action) => {
            state.fetchStatus.update[action.payload.opaqueId] = FetchStatus.Default;
        }).addCase(UpdateLandingPageActionAndSaga.successAction, (state, action) => {
            state.fetchStatus.update[action.payload.opaqueId] = FetchStatus.Success;
            state.landingPages.elements[action.payload.opaqueId] = action.payload;
        }).addCase(LoadLandingPageCdnFilesActionAndSaga.startAction, (state, action) => {
            state.fetchStatus.cdnFiles = FetchStatus.Active;
        }).addCase(LoadLandingPageCdnFilesActionAndSaga.errorAction, (state, action) => {
            state.fetchStatus.cdnFiles = FetchStatus.Error;
        }).addCase(LoadLandingPageCdnFilesActionAndSaga.resetAction, (state, action) => {
            state.fetchStatus.cdnFiles = FetchStatus.Default;
        }).addCase(LoadLandingPageCdnFilesActionAndSaga.successAction, (state, action) => {
            state.fetchStatus.cdnFiles = FetchStatus.Success;
            action.payload.entities.forEach(it => {
                state.cdnFiles.files[it.id] = it;
            });
            state.cdnFiles.images = {
                ...state.cdnFiles.images,
                ...action.payload.entities.reduce((acc, curr) => {
                    if (curr.typeSpecificInformation.type === LandingPageCdnFileTypeSpecificInformationType.Image || curr.typeSpecificInformation.type === LandingPageCdnFileTypeSpecificInformationType.None) {
                        acc[curr.id] = curr as LandingPageCdnFileDTO<LandingPageCdnImageFileTypeSpecificInformation | LandingPageCdnNoneFileTypeSpecificInformation>;
                    }
                    return acc;
                }, {} as Record<number, LandingPageCdnFileDTO<LandingPageCdnImageFileTypeSpecificInformation | LandingPageCdnNoneFileTypeSpecificInformation> | undefined>)
            };
            state.cdnFiles.fontFamilies = {
                ...state.cdnFiles.fontFamilies,
                ...action.payload.entities.reduce((acc, curr) => {
                    if (curr.typeSpecificInformation.type === LandingPageCdnFileTypeSpecificInformationType.Font) {
                        if (!acc[(curr.typeSpecificInformation as LandingPageCdnFontFileTypeSpecificInformation).baseFontFamily]) {
                            acc[(curr.typeSpecificInformation as LandingPageCdnFontFileTypeSpecificInformation).baseFontFamily] = [];
                        }
                        acc[(curr.typeSpecificInformation as LandingPageCdnFontFileTypeSpecificInformation).baseFontFamily].push(curr.id);
                    }
                    return acc;
                }, {} as Record<string, number[]>)
            };
        }).addCase(uploadLandingPageFilesAction.startAction, (state) => {
            state.fetchStatus.fileUpload = FetchStatus.Active;
        }).addCase(uploadLandingPageFilesAction.errorAction, (state) => {
            state.fetchStatus.fileUpload = FetchStatus.Error;
        }).addCase(uploadLandingPageFilesAction.resetAction, (state) => {
            state.fetchStatus.fileUpload = FetchStatus.Default;
            state.fileUpload.progress = 0;
            state.fileUpload.files = [];
        }).addCase(uploadLandingPageFilesAction.successAction, (state, action) => {
            state.fetchStatus.fileUpload = FetchStatus.Success;
            state.cdnFiles.files[action.payload.id] = action.payload;
            state.fileUpload.files.push(action.payload);
            if (action.payload.typeSpecificInformation.type === LandingPageCdnFileTypeSpecificInformationType.Image || action.payload.typeSpecificInformation.type === LandingPageCdnFileTypeSpecificInformationType.None) {
                state.cdnFiles.images[action.payload.id] = action.payload as LandingPageCdnFileDTO<LandingPageCdnImageFileTypeSpecificInformation | LandingPageCdnNoneFileTypeSpecificInformation>;
            }
            if (action.payload.typeSpecificInformation.type === LandingPageCdnFileTypeSpecificInformationType.Font) {
                if (!state.cdnFiles.fontFamilies[(action.payload.typeSpecificInformation as LandingPageCdnFontFileTypeSpecificInformation).baseFontFamily]) {
                    state.cdnFiles.fontFamilies[(action.payload.typeSpecificInformation as LandingPageCdnFontFileTypeSpecificInformation).baseFontFamily] = [];
                }
                state.cdnFiles.fontFamilies[(action.payload.typeSpecificInformation as LandingPageCdnFontFileTypeSpecificInformation).baseFontFamily]!.push(action.payload.id);
            }
        }).addCase(LoadLandingPagePreviewActionAndSaga.startAction, (state, action) => {
            state.fetchStatus.preview[action.payload.opaqueId] = FetchStatus.Active;
        }).addCase(LoadLandingPagePreviewActionAndSaga.errorAction, (state, action) => {
            state.fetchStatus.preview[action.payload.opaqueId] = FetchStatus.Error;
            delete state.preview[action.payload.opaqueId];
        }).addCase(LoadLandingPagePreviewActionAndSaga.resetAction, (state, action) => {
            state.fetchStatus.preview[action.payload.opaqueId] = FetchStatus.Default;
        }).addCase(CloneLandingPageActionAndSaga.startAction, (state, action) => {
            state.fetchStatus.clone[action.payload.opaqueId] = FetchStatus.Active;
        }).addCase(CloneLandingPageActionAndSaga.errorAction, (state, action) => {
            state.fetchStatus.clone[action.payload.opaqueId] = FetchStatus.Error;
        }).addCase(CloneLandingPageActionAndSaga.resetAction, (state, action) => {
            state.fetchStatus.clone[action.payload.opaqueId] = FetchStatus.Default;
        }).addCase(CloneLandingPageActionAndSaga.successAction, (state, action) => {
            state.landingPages.elements[action.payload.opaqueId] = action.payload;
            state.landingPages.visible = [action.payload.opaqueId, ...state.landingPages.visible];
        }).addCase(setSelectedCustomerIdAction.action, () => {
            return {...initialLandingPageState};
        })
})

export const {
    increaseLandingPageSearchPage,
    setDownloadQRCodeFetchStatusSuccess,
    setUploadLandingPageFilesProgress,
    resetUploadLandingPageFiles,
    setLandingPageLayoutDisplayPreview,
    setCloneLandingPageFetchStatusSuccess,
    resetCreateLandingPage
} = landingPages.actions;

export default landingPages.reducer;