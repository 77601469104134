import {createAction} from '@reduxjs/toolkit';
import {
    DashboardResponse,
    LoadVideoWishesResponse,
    SearchResponse,
    UploadVideo,
    Video,
    VideoCategoryResponse,
    VideoComment,
    VideoHighlight,
    VideoPath,
    VideoPlaylist,
    VideoPlaylistSectionVideo,
    VideoStatisticResponse,
    VideoTag,
    VideoTagsResponse,
    VideoUploadResponse,
    VideoUserDTO,
    VideoWish,
    VideoWishVote
} from './types';
import {UserState} from '../user/types';
import {createFetchAction, getRequest, SagaHandling} from '@software/reactcommons';
import {createAuthenticatedSagaFetchAction, SecurityErrorCode} from '@software/reactcommons-security';
import {Route} from '../../../api/Api';

/***************************************************************
 * Redux actions types
 **************************************************************/


export const SetUploadVideoProperty = 'videos/setUploadVideoProperty';

export const SetEnabledVideoHighlightSlider = 'videos/setEnabledVideoHighlightSlider';

export const RecordView = 'videos/recordView';

export const SetVideoPlaylistProperty = 'videos/setVideoPlaylistProperty';

export const SetUploadVideoPlaylistTitleImageProgress = 'videos/setUploadVideoPlaylistTitleImageProgress';

export const RecordPlaylistView = 'videos/recordPlaylistView';

/***************************************************************
 * Redux action payloads
 **************************************************************/

export interface UpvoteWishSuccessPayload {
    id: number;
    voted: boolean;
    vote: VideoWishVote;
}

export interface UpvoteWishPayload {
    id: number;
    userVote?: VideoWishVote;
    vote: boolean;
}

export interface CreateVideoWishPayload {
    description: string;
    title: string;
}

export interface DeleteVideoActionPayload {
    id: number;
    videoID: string;
}


export interface SetVideoEnabledActionPayload {
    id: number;
    videoID: string;
    enabled: boolean;
}


export interface FetchSetVideoEnabledSuccessActionPayload {
    id: string;
    enabled: boolean;
}

export interface ToggleVideoEditActionPayload {
    id: string;
    video: Video;
}

export interface SetUploadVideoTagsForTagTypeActionPayload {
    tags?: VideoTag[];
    tagType: number;
}

export interface SetUploadVideoPropertyActionPayload<Key extends keyof UploadVideo, Value extends UploadVideo[Key]> {
    key: Key;
    value: Value;
}

export interface VideoReactionActionPayload {
    reactionTypeID: number;
    active: boolean;
    videoID: string;
    id: number;
}

export interface VideoReactionSuccessActionPayload extends VideoReactionActionPayload {
    user: VideoUserDTO;
}

export interface VideoInfoPayload {
    videoID: string;
}

export interface FetchLoadVideoCommentsSuccessActionPayload {
    id: string;
    comments: VideoComment[];
}

export interface SubmitVideoCommentActionPayload {
    id: string;
    text: string;
    parentID?: number;
}

export interface SubmitVideoCommentSuccessActionPayload {
    id: string;
    comment: VideoComment;
}

export interface DeleteVideoCommentActionPayload {
    id: string;
    commentID: number;
}

export interface FetchDeleteVideoCommentActionPayload {
    id: string;
    commentID: number;
    deletedBy: VideoUserDTO;
    deletedTimestamp: number;
}

export interface HighlightVideoPayload {
    highlight: boolean;
    id: number;
    videoID: string;
}

export interface SetPlaylistPropertyPayload<Key extends keyof VideoPlaylist, Value extends VideoPlaylist[Key]> {
    playlistId: string;
    property: Key;
    value: Value;
}


export interface SearchPayload {
    limit?: number;
    offset?: number;
    lazy?: boolean;
    search?: string;
    isTextSearch?: boolean;
}

export interface FetchSearchSuccessPayload {
    result: SearchResponse;
    lazy?: boolean;
}

export interface PlaylistSectionPayload {
    playlistId: string;
    // Use the position instead of the section id since every new section will have id -1
    sectionPosition: number;
}

export interface AddVideosToPlaylistSectionPayload extends PlaylistSectionPayload {
    videoIds: string[];
}

export interface SetSectionVideosPayload extends PlaylistSectionPayload {
    videos: VideoPlaylistSectionVideo[];
}

export interface RemoveVideoFromPlaylistSectionPayload extends PlaylistSectionPayload {
    videoId: string;
}

export interface SetPlaylistSectionTitlePayload extends PlaylistSectionPayload {
    title: string;
}

export interface UpdatePlaylistSectionPositionPayload extends PlaylistSectionPayload {
    newPosition: number;
}

export interface AddVideoPlaylistImagePayload {
    file: File;
    playlistId: string;
}

export interface VideoPlaylistImageUploadResponse {
    imagePath: {
        fileName: string;
        path: string;
    }
}

export interface FetchUploadVideoPlaylistTitleImageSuccessPayload {
    playlistId: string;
    response: VideoPlaylistImageUploadResponse;
}

export interface SetUploadVideoPlaylistTitleImageProgressPayload {
    playlistId: string;
    progress: number;
}


export interface UpdateVideoPlaylistResponse {
    response: VideoPlaylist;
    playlistId: string;
}

export interface LoadVideoPlaylistsResponse {
    playlists: VideoPlaylist[];
    totalCount: number;
}

export interface SetActiveVideoPlaylistVideoPayload {
    playlistId: string;
    videoId: string;
    section: number;
}

export interface VideoPlaylistActionPayload {
    playlistId: string;
    value: boolean;
}

export interface ResetVideoPlaylistFetchStatusPayload {
    playlistId: string;
    fetchStatus: 'saveFetchStatus' | 'enableFetchStatus' | 'highlightFetchStatus' | 'deleteFetchStatus';
}

/***************************************************************
 * Redux action creator
 **************************************************************/

export const loadVideoCategoriesAction = createFetchAction<{}, VideoCategoryResponse, 'UNKNOWN'>('videos', 'loadVideoCategories');

export const loadVideoTagsAction = createFetchAction<{}, VideoTagsResponse, 'UNKNOWN'>('videos', 'loadVideoTags');

export const loadVideoMostViewedAction = createFetchAction<{}, DashboardResponse, 'UNKNOWN'>('videos', 'loadMostViewed');

export const loadVideoLatestAction = createFetchAction<{}, DashboardResponse, 'UNKNOWN'>('videos', 'loadLatest');

export const loadVideoMostExcitingAction = createFetchAction<{}, DashboardResponse, 'UNKNOWN'>('videos', 'loadMostExciting');

export const loadVideoActionAndSaga = createAuthenticatedSagaFetchAction<{ videoId: string }, Video, SecurityErrorCode>({
    actionGroup: 'videos',
    actionName: 'loadVideo',
    networkCall: (jwt, selectedLanguage, params) => getRequest(Route.GetVideo(params?.videoId || ''), {
        jwt,
        selectedLanguage
    }),
    sagaHandling: SagaHandling.Every
});

export const searchVideosAction = createFetchAction<SearchPayload, FetchSearchSuccessPayload, 'UNKNOWN'>('videos', 'searchVideos');

export const deleteVideoAction = createFetchAction<DeleteVideoActionPayload, string, 'UNKNOWN', string>('videos', 'deleteVideo');

export const setVideoEnabledAction = createFetchAction<SetVideoEnabledActionPayload, FetchSetVideoEnabledSuccessActionPayload, 'UNKNOWN', string>('videos', 'setVideoEnabled');

export function setUploadVideoProperty<Key extends keyof UploadVideo, Value extends UploadVideo[Key]>(payload: SetUploadVideoPropertyActionPayload<Key, Value>) {
    return {
        type: SetUploadVideoProperty,
        payload
    }
}
setUploadVideoProperty.type = SetUploadVideoProperty;

export const uploadVideoAction = createFetchAction<{ file: File }, VideoUploadResponse, 'UNKNOWN'>('videos', 'uploadVideo');

export const saveUploadedVideoAction = createFetchAction<{}, Video, 'UNKNOWN'>('videos', 'saveUploadVideo');

export const setVideoReactionAction = createFetchAction<VideoReactionActionPayload, VideoReactionSuccessActionPayload, 'UNKNOWN'>('videos', 'setVideoReaction');
export const loadVideoCommentsAction = createFetchAction<VideoInfoPayload, FetchLoadVideoCommentsSuccessActionPayload, 'UNKNOWN'>('videos', 'loadVideoComments');

export const submitVideoCommentAction = createFetchAction<SubmitVideoCommentActionPayload, SubmitVideoCommentSuccessActionPayload, 'UNKNOWN', string>('videos', 'submitVideoComment');

export const deleteVideoCommentAction = createFetchAction<DeleteVideoCommentActionPayload, FetchDeleteVideoCommentActionPayload, 'UNKNOWN'>('videos', 'deleteVideoComment');

export const loadVideoHighlightsAction = createFetchAction<{}, VideoHighlight[], 'UNKNOWN'>('videos', 'loadVideoHighlights');

export const highlightVideoAction = createFetchAction<HighlightVideoPayload, HighlightVideoPayload, SecurityErrorCode>('videos', 'highlightVideo');

export const loadStatisticsAction = createFetchAction<{}, VideoStatisticResponse, 'UNKNOWN'>('videos', 'loadStatistics');

export const loadVideoWishesAction = createFetchAction<{}, LoadVideoWishesResponse, 'UNKNOWN'>('videos', 'loadWishes');

export const createVideoWishAction = createFetchAction<CreateVideoWishPayload, VideoWish, 'UNKNOWN'>('videos', 'createWish');

export const upvoteWishAction = createFetchAction<UpvoteWishPayload, UpvoteWishSuccessPayload, 'UNKNOWN'>('videos', 'upvoteWish');

export const setEnabledVideoHighlightSlider = createAction<boolean>(SetEnabledVideoHighlightSlider);


export const recordVideoView = createAction<string>(RecordView);

export function setVideoPlaylistProperty<Key extends keyof VideoPlaylist, Value extends VideoPlaylist[Key]>(payload: SetPlaylistPropertyPayload<Key, Value>) {
    return {
        type: SetVideoPlaylistProperty,
        payload
    }
}
setVideoPlaylistProperty.type = SetVideoPlaylistProperty;

export const uploadVideoPlaylistTitleImageAction = createFetchAction<AddVideoPlaylistImagePayload, FetchUploadVideoPlaylistTitleImageSuccessPayload, 'UNKNOWN'>('videos', 'uploadVideoPlaylistTitleImage');

export const setUploadVideoPlaylistTitleImageProgress = createAction<SetUploadVideoPlaylistTitleImageProgressPayload>(SetUploadVideoPlaylistTitleImageProgress);

export const updateVideoPlaylistAction = createFetchAction<{ playlistId: string }, UpdateVideoPlaylistResponse, 'UNKNOWN', string>('videos', 'updateVideoPlaylist');

export const loadVideoPlaylistsAction = createFetchAction<{}, LoadVideoPlaylistsResponse, 'UNKNOWN'>('videos', 'loadVideoPlaylists');

export const highlightVideoPlaylistAction = createFetchAction<VideoPlaylistActionPayload, VideoPlaylistActionPayload, SecurityErrorCode>('videos', 'highlightVideoPlaylist');

export const enableVideoPlaylistAction = createFetchAction<VideoPlaylistActionPayload, VideoPlaylistActionPayload, SecurityErrorCode>('videos', 'enableVideoPlaylist');

export const deleteVideoPlaylistAction = createFetchAction<{ playlistId: string }, { playlistId: string }, SecurityErrorCode>('videos', 'deleteVideoPlaylist');

export const loadVideoPlaylistHighlightsAction = createFetchAction<{}, VideoPlaylist[], 'UNKNOWN'>('videos', 'loadVideoPlaylistHighlights');

export const recordPlaylistView = createAction<string>(RecordPlaylistView);

export interface RemoveVideoPlaylistImagePayload {
    playlistId: string;
    path: string;
}

/***************************************************************
 * WebSocket messages and actions
 **************************************************************/

export interface VideoWebSocketMessage {
    type: IncomingWebSocketMessageType;
    user: UserState;
}

export interface VideoReactionMessage extends VideoWebSocketMessage {
    reaction: {
        id: number;
        reactionTypeID: number;
        videoID: number;
        user: VideoUserDTO;
    }
}

export interface VideoCreatedMessage extends VideoWebSocketMessage {
    videoDetail: Video;
}

export interface CommentMessage extends VideoWebSocketMessage {
    userID: number;
    videoID: number;
    commentDTO: VideoComment;
}

export interface VideoDeletedMessage extends VideoWebSocketMessage {
    videoID: number;
}

export interface VideoEnabledMessage extends VideoWebSocketMessage {
    videoDetail: Video;
}

export interface VideoPathCreatedMessage extends VideoWebSocketMessage {
    videoID: number;
    videoPath: VideoPath;
}

export interface VideoViewedMessage extends VideoWebSocketMessage {
    videoID: number;
}

export interface VideoHighlightCreatedMessage extends VideoWebSocketMessage {
    highlight: VideoHighlight;
}

export interface VideoHighlightDeletedMessage extends VideoWebSocketMessage {
    videoID: string;
}

export interface VideoWishCreatedMessage extends VideoWebSocketMessage {
    wish: VideoWish;
}

export interface VideoWishVoteCreatedMessage extends VideoWebSocketMessage {
    wishVote: VideoWishVote;
}

export interface VideoWishVoteDeletedMessage extends VideoWebSocketMessage {
    wishVoteID: number;
    wishID: number;
}

export const toggleVideoEdit = createAction<ToggleVideoEditActionPayload>('videos/toggleVideoEdit')


export enum IncomingWebSocketMessageType {
    VideoReactionCreated = 'VideoReactionCreated',
    VideoReactionDeleted = 'VideoReactionDeleted',
    VideoCommentCreated = 'VideoCommentCreated',
    VideoCommentDeleted = 'VideoCommentDeleted',
    VideoPathCreated = 'VideoPathCreated',
    VideoCreated = 'VideoCreated',
    VideoDeleted = 'VideoDeleted',
    VideoEnabled = 'VideoEnabled',
    VideoDisabled = 'VideoDisabled',
    VideoViewed = 'VideoViewed',
    HighlightCreated = 'HighlightCreated',
    HighlightDeleted = 'HighlightDeleted',
    WishCreated = 'WishCreated',
    WishDeleted = 'WishDeleted',
    WishVoteCreated = 'WishVoteCreated',
    WishVoteDeleted = 'WishVoteDeleted'
}

export const videoReactionCreated = createAction<VideoReactionMessage>(IncomingWebSocketMessageType.VideoReactionCreated);
export const videoReactionRemoved = createAction<VideoReactionMessage>(IncomingWebSocketMessageType.VideoReactionDeleted);
export const videoCreated = createAction<VideoCreatedMessage>(IncomingWebSocketMessageType.VideoCreated);
export const videoCommentCreated = createAction<CommentMessage>(IncomingWebSocketMessageType.VideoCommentCreated);
export const videoCommentRemoved = createAction<CommentMessage>(IncomingWebSocketMessageType.VideoCommentDeleted);
export const videoDeleted = createAction<VideoDeletedMessage>(IncomingWebSocketMessageType.VideoDeleted);
export const videoDisabled = createAction<VideoDeletedMessage>(IncomingWebSocketMessageType.VideoDisabled);
export const videoEnabled = createAction<VideoEnabledMessage>(IncomingWebSocketMessageType.VideoEnabled);
export const videoPathCreated = createAction<VideoPathCreatedMessage>(IncomingWebSocketMessageType.VideoPathCreated);
export const videoViewed = createAction<VideoViewedMessage>(IncomingWebSocketMessageType.VideoViewed);
export const videoHighlightCreated = createAction<VideoHighlightCreatedMessage>(IncomingWebSocketMessageType.HighlightCreated);
export const videoHighlightDeleted = createAction<VideoHighlightDeletedMessage>(IncomingWebSocketMessageType.HighlightDeleted);
export const videoWishCreated = createAction<VideoWishCreatedMessage>(IncomingWebSocketMessageType.WishCreated);
export const videoWishVoteCreated = createAction<VideoWishVoteCreatedMessage>(IncomingWebSocketMessageType.WishVoteCreated);
export const videoWishVoteDeleted = createAction<VideoWishVoteDeletedMessage>(IncomingWebSocketMessageType.WishVoteDeleted);


