import {
    ActionReducerMapBuilder,
    CaseReducer,
    PayloadAction
} from '@reduxjs/toolkit';
import {VideoState} from './types';
import {
    loadStatisticsAction,
} from './actions';
import {FetchStatus, normalize} from '@software/reactcommons';
import {OrderRequest} from '../../../types/Types';

export const setAdminVideoColumnSortOrder: CaseReducer<VideoState, PayloadAction<OrderRequest>> = (state, action) => {
    // Check if there is already an order in the state for the given config
    const orderBy = state.statistics.videos.orderBy.find(it => it.key === action.payload.key);
    if (orderBy) {
        orderBy.order = action.payload.order;
    } else {
        // Order was not part of the order by state yet, add it as dependency
        state.statistics.videos.orderBy.push(action.payload);
    }
};

export const adminReducers = {
    setAdminVideoColumnSortOrder: (state: VideoState, action: PayloadAction<OrderRequest>) => {
        // Check if there is already an order in the state for the given config
        const orderBy = state.statistics.videos.orderBy.find(it => it.key === action.payload.key);
        if (orderBy) {
            orderBy.order = action.payload.order;
        } else {
            // Order was not part of the order by state yet, add it as dependency
            state.statistics.videos.orderBy.push(action.payload);
        }
    },
    removeAdminVideoColumnSortOrder: (state: VideoState, action: PayloadAction<string>) => {
        state.statistics.videos.orderBy = state.statistics.videos.orderBy.filter(it => it.key !== action.payload);
    },
    setAdminVideoSearchText: (state: VideoState, action: PayloadAction<string>) => {
        state.statistics.videos.search = action.payload;
    },
    setAdminVideoPageSize: (state: VideoState, action: PayloadAction<number>) => {
        state.statistics.videos.pageSize = action.payload;
        state.statistics.videos.page = 0;
    },
    setAdminVideoPage: (state: VideoState, action: PayloadAction<number>) => {
        state.statistics.videos.page = action.payload;
    }
};


export const extraAdminReducers = (builder: ActionReducerMapBuilder<VideoState>) =>
    builder.addCase(loadStatisticsAction.startAction, state => {
        state.statistics.videos.fetchStatus = FetchStatus.Active;
    }).addCase(loadStatisticsAction.successAction, (state, action) => {
        state.statistics.videos.fetchStatus = FetchStatus.Success;
        state.statistics.videos.elements = {...state.statistics.videos.elements, ...normalize(action.payload.videos, undefined, 'videoID')};
        state.statistics.videos.visible = action.payload.videos.map(it => it.videoID);
        state.statistics.videos.numberOfElements = action.payload.totalCount;
        state.statistics.totalViews = action.payload.totalViews;
    }).addCase(loadStatisticsAction.errorAction, state => {
        state.statistics.videos.fetchStatus = FetchStatus.Error;
    })