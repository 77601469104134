import {FetchStatus} from '@software/reactcommons';
import {
    AdminState
} from './types';
import {
    saveEditedVideoAction,
    setEditVideoProperty,
} from './actions';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {markdownConverter} from '../../../constants/Constants';
import {removeHTMLTags} from '../../../helper/Helper';
import {VideoTag} from '../videos/types';
import {toggleVideoEdit} from '../videos/actions';


export const initialAdminState: AdminState = {
    editedVideo: {
        videoID: '',
        title: '',
        author: '',
        description: '',
        categories: [],
        tags: [],
        createdTags: [],
        selectedTags: [],
        selectedTagsByTagType: {},
        fetchStatus: FetchStatus.Default,
        saveFetchStatus: FetchStatus.Default,
    },
    preselectedCategories: []
};

const admin = createSlice({
    name: 'admin',
    initialState: initialAdminState,
    reducers: {
        setEditVideoTags: (state, action: PayloadAction<VideoTag[]>) => {
            state.editedVideo.tags = action.payload.filter((tag) => tag.id > -1);
            state.editedVideo.createdTags = action.payload.filter((tag) => tag.id === -1);
        },
        cancelEditVideo: state => {
            state.editedVideo = {...initialAdminState.editedVideo};
        }
    },
    extraReducers: builder => builder.addCase(setEditVideoProperty, (state, action) => {
        // @ts-ignore Check if any typings can be found to solve this
        state.editedVideo[action.payload.key] = action.payload.value;
    }).addCase(saveEditedVideoAction.startAction, state => {
        state.editedVideo.fetchStatus = FetchStatus.Active;
    }).addCase(saveEditedVideoAction.successAction, (state) => {
        state.editedVideo.fetchStatus = FetchStatus.Success;
    }).addCase(saveEditedVideoAction.errorAction, (state) => {
        state.editedVideo.fetchStatus = FetchStatus.Error;
    }).addCase(saveEditedVideoAction.resetAction, state => {
        state.editedVideo.fetchStatus = FetchStatus.Default;
    }).addCase(toggleVideoEdit, (state, action) => {
        state.editedVideo = {
            ...initialAdminState.editedVideo,
            ...action.payload.video,
            description: removeHTMLTags(markdownConverter.makeHtml(action.payload.video.description))
        }
    })
});

export const {setEditVideoTags, cancelEditVideo} = admin.actions;

export default admin.reducer;