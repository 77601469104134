import {AppState} from '../../../types/Types';
import {OperationsStatePart} from '../../reducer/operations/types';

export const toDoSearchSelector = (statePart: OperationsStatePart) => (state: AppState) => ({
    jwt: state.user.info.jwt,
    page: state.operations[statePart].page,
    pageSize: state.operations[statePart].pageSize,
    search: state.operations[statePart].search,
    toDoIds: state.operations[statePart].toDoIds,
    locationIds: state.operations[statePart].locationIds
});