import {ApplicationState} from './types';
import {
    baseApplicationState,
    createApplicationReducer, FetchStatus, CompiledMessage
} from '@software/reactcommons';
import {fetchAuthenticateSuccess} from '@software/reactcommons-security';
import {PayloadAction} from '@reduxjs/toolkit';
import {loadDeepLinkActionAndSaga, LoadLocalesActionAndSaga} from './actions';

export const initialApplicationState: ApplicationState = {
    ...baseApplicationState,
    modals: {
        showLogin: false,
        showInactivityLogout: false
    },
    appActions: [],
    fetchStatus: {
        deepLinkToken: FetchStatus.Default,
        locales: FetchStatus.Default,
    },
    customMessages: {},
    locales: {
        available: []
    }
};

const application = createApplicationReducer({
    initialState: initialApplicationState,
    reducers: {
        showLoginPrompt: (state) => {
            state.modals.showLogin = true;
        },
        setAfterLoginMessage: (state, action: PayloadAction<CompiledMessage | undefined>) => {
            state.customMessages.afterLoginMessage = action.payload;
        },
        setDeepLinkToken: (state, action: PayloadAction<string | undefined>) => {
            state.deepLinkToken = action.payload;
        },
        setShowInactivityLogoutModal: (state, action: PayloadAction<boolean>) => {
            state.modals.showInactivityLogout = action.payload;
        }
    },
    extraReducers: builder => builder.addCase(fetchAuthenticateSuccess, state => {
        state.modals.showLogin = false;
    }).addCase(loadDeepLinkActionAndSaga.startAction, (state) => {
        state.fetchStatus.deepLinkToken = FetchStatus.Active;
    }).addCase(loadDeepLinkActionAndSaga.errorAction, (state) => {
        state.fetchStatus.deepLinkToken = FetchStatus.Error;
    }).addCase(loadDeepLinkActionAndSaga.resetAction, (state) => {
        state.fetchStatus.deepLinkToken = FetchStatus.Default;
    }).addCase(loadDeepLinkActionAndSaga.successAction, (state, action) => {
        state.fetchStatus.deepLinkToken = FetchStatus.Success;
        if (action.payload.redirectPath) {
            state.redirect = action.payload.redirectPath;
        }
        state.customMessages.loginSiteMessage = action.payload.loginPageMessage;
        state.customMessages.afterLoginMessage = action.payload.loggedInMessage;
    }).addCase(LoadLocalesActionAndSaga.startAction, (state) => {
        state.fetchStatus.locales = FetchStatus.Active;
    }).addCase(LoadLocalesActionAndSaga.errorAction, (state) => {
        state.fetchStatus.locales = FetchStatus.Error;
    }).addCase(LoadLocalesActionAndSaga.resetAction, (state) => {
        state.fetchStatus.locales = FetchStatus.Default;
    }).addCase(LoadLocalesActionAndSaga.successAction, (state, action) => {
        state.fetchStatus.locales = FetchStatus.Success;
        state.locales.available = action.payload;
    })
});

export const {
    showLoginPrompt,
    setAfterLoginMessage,
    setDeepLinkToken,
    setShowInactivityLogoutModal
} = application.actions;

export default application.reducer;