import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {makeStyles} from 'tss-react/mui';
import {inactivityLogoutDurationInMsSelector} from '../../../../redux/reducer/user/selectors';
import {AppState} from '../../../../types/Types';
import {broadcastChannel, Dialog, useLocalization} from '@software/reactcommons';
import {Alert} from '@mui/material';
import {setShowInactivityLogoutModal} from '../../../../redux/reducer/application/application';
import {logoutAction} from '@software/reactcommons-security';
import {Messages} from '../../../../localization/localization';
import Typography from '@mui/material/Typography';
import {useInactivityLogoutDurationInSeconds} from '../../../../hooks/hooks';

const useStyles = makeStyles()(theme => ({
    description: {
        fontWeight: 500,
        fontSize: 18
    },
    submitButton: {
        whiteSpace: 'nowrap',
    }
}));


const InactivityLogoutDialog: React.FC = () => {

    const {classes} = useStyles();
    const dispatch = useDispatch();
    const durationInMillis = useSelector(inactivityLogoutDurationInMsSelector);
    const showDialog = useSelector((state: AppState) => state.application.modals.showInactivityLogout);
    const lastUserActivityTimestamp = useSelector((state: AppState) => state.user.lastActivity);
    const [referenceUserActivityTimestamp, setReferenceUserActivityTimestamp] = useState(0);
    const messages = useLocalization<Messages>();

    const secondsToLogout = useInactivityLogoutDurationInSeconds(referenceUserActivityTimestamp, showDialog);

    useEffect(() => {
        if (showDialog && referenceUserActivityTimestamp === 0) {
            // Check if reference user activity timestamp has not been set yet, then set it to the last user activity timestamp
            setReferenceUserActivityTimestamp(lastUserActivityTimestamp);
        } else if (!showDialog && referenceUserActivityTimestamp !== 0) {
            // The dialog has been closed, reset the reference activity timestamp
            setReferenceUserActivityTimestamp(0);
        }
    },[lastUserActivityTimestamp, showDialog, referenceUserActivityTimestamp]);

    if (durationInMillis > 0 && showDialog && secondsToLogout > 0) {

        return (<Dialog open={true}
                        title={messages.login.inactivityLogout.dialog.title}
                        submit={messages.login.inactivityLogout.dialog.submit}
                        classes={{
                            submit: classes.submitButton
                        }}
                        disableEscapeKeyDown={true}
                        close={(_, reason) => {
                            // Only trigger close if reason is not a backdrop click
                            if (reason !== 'backdropClick') {
                                dispatch(logoutAction.action());
                            }
                        }}
                        onSubmit={() => {
                            const action = setShowInactivityLogoutModal(false);
                            dispatch(action);
                            // Tell other tabs that user stays logged in
                            broadcastChannel.postMessage(JSON.stringify(action));
                        }}
                        cancel={messages.login.inactivityLogout.dialog.cancel}>
            <Alert severity={'warning'}>
                <Typography variant={'body1'} className={classes.description}>
                    {messages.formatString(messages.login.inactivityLogout.dialog.description, secondsToLogout)}
                </Typography>
            </Alert>

        </Dialog>);
    }
    return null;
}

export default InactivityLogoutDialog;