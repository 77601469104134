import {ActionReducerMapBuilder} from '@reduxjs/toolkit';
import {VideoState} from './types';
import {
    deleteVideoCommentAction,
    loadVideoCommentsAction,
    submitVideoCommentAction,
    videoCommentCreated,
    videoCommentRemoved
} from './actions';
import {FetchStatus, normalize} from '@software/reactcommons';

export const commentsReducers = {
}

export const extraCommentsReducers = (builder: ActionReducerMapBuilder<VideoState>) =>
    builder.addCase(loadVideoCommentsAction.startAction, (state, action) => {
        const element = state.videos.elements[action.payload.videoID];
        if (element) {
            element.commentFetchStatus = FetchStatus.Active;
        }
    }).addCase(loadVideoCommentsAction.successAction, (state, action) => {
        const element = state.videos.elements[action.payload.id];
        if (element) {
            element.commentFetchStatus = FetchStatus.Success;
            element.comments = normalize(action.payload.comments, {deleteFetchStatus: FetchStatus.Default}, 'id');
        }
    }).addCase(submitVideoCommentAction.startAction, (state, action) => {
        const element = state.videos.elements[action.payload.id];
        if (element) {
            element.submitCommentFetchStatus = FetchStatus.Active;
        }
    }).addCase(submitVideoCommentAction.successAction, (state, action) => {
        const element = state.videos.elements[action.payload.id];
        if (element) {
            element.submitCommentFetchStatus = FetchStatus.Success;
            element.latestCreatedComment = action.payload.comment.id;
            // If no comments have been published yet, the object could be undefined
            element.comments = element.comments || {};
            element.comments[action.payload.comment.id] = {...action.payload.comment, deleteFetchStatus: FetchStatus.Default};
            element.numberOfComments++;
        }
    }).addCase(submitVideoCommentAction.errorAction, (state, action) => {
        const element = state.videos.elements[action.payload.id];
        if (element) {
            element.submitCommentFetchStatus = FetchStatus.Error;
        }
    }).addCase(submitVideoCommentAction.resetAction, (state, action) => {
        const element = state.videos.elements[action.payload];
        if (element) {
            element.submitCommentFetchStatus = FetchStatus.Default;
        }
    }).addCase(deleteVideoCommentAction.startAction, (state, action) => {
        const comment = state.videos.elements[action.payload.id]?.comments?.[action.payload.commentID];
        if (comment) {
            comment.deleteFetchStatus = FetchStatus.Active;
        }
    }).addCase(deleteVideoCommentAction.successAction, (state, action) => {
        let video = state.videos.elements[action.payload.id];
        if (video) {
            let comment = video.comments?.[action.payload.commentID];
            if (comment) {
                video.numberOfComments--;
                comment.comment = '';
                comment.deletedBy = action.payload.deletedBy;
                comment.deletedTimestamp = action.payload.deletedTimestamp;
                comment.deleteFetchStatus = FetchStatus.Success;
            }
        }
    }).addCase(deleteVideoCommentAction.errorAction, (state, action) => {
        const comment = state.videos.elements[action.payload.id]?.comments?.[action.payload.commentID];
        if (comment) {
            comment.deleteFetchStatus = FetchStatus.Error;
        }
    }).addCase(videoCommentCreated, (state, action) => {
        const video = state.videos.elements[action.payload.videoID];
        if (video) {
            video.comments = video.comments || {};
            if (!video.comments[action.payload.commentDTO.id] && video.submitCommentFetchStatus !== FetchStatus.Active) {
                video.comments[action.payload.commentDTO.id] = {
                    ...action.payload.commentDTO,
                    deleteFetchStatus: FetchStatus.Default
                };
                video.numberOfComments++;
            }
        }
    }).addCase(videoCommentRemoved, (state, action) => {
        const video = state.videos.elements[action.payload.videoID];
        if (video) {
            video.comments = video.comments || {};
            if (video.comments[action.payload.commentDTO.id]?.deleteFetchStatus !== FetchStatus.Active && video.comments[action.payload.commentDTO.id]?.deleteFetchStatus !== FetchStatus.Success) {
                video.comments[action.payload.commentDTO.id] = {
                    ...action.payload.commentDTO,
                    deleteFetchStatus: FetchStatus.Default
                };
                video.numberOfComments--;
            }
        }
    });