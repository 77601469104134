import {CompiledMessage, FetchStatus} from '@software/reactcommons';
import {PaginationState} from '../../../types/Types';
import {LandingPageCdnFileDTO} from './actions';
import {
    LandingPage, LandingPageAppStore,
    LandingPageConfigType, LandingPageFontStyle,
    LandingPageFontWeightClass, LandingPageFontWidthClass,
    LandingPageIcon,
    LandingPagePadding, LandingPageTextSize
} from '@software/landingpageui';



export enum LandingPageLayoutTextButtonConfigurationTab {
    DefaultState = 'DefaultState',
    HoverState = 'HoverState'
}

export enum LandingPageLinkType {
    Explicit = 'Explicit',
    Feedback = 'Feedback'
}

export interface LandingPageLink {
    type: LandingPageLinkType;
}

export interface LandingPageExplicitLink extends LandingPageLink {
    type: LandingPageLinkType.Explicit;
    uri: CompiledMessage;
}

export interface LandingPageFeedbackQuestionPrefill {
    questionID: number;
    value: number;
}

export interface LandingPageFeedbackPrefill {
    answers: LandingPageFeedbackQuestionPrefill[];
}

export interface LandingPageFeedbackLink extends LandingPageLink {
    type: LandingPageLinkType.Feedback;
    feedbackCollectionPointOpaqueId: string;
    prefill?: LandingPageFeedbackPrefill;
    parameters?: Record<string, string>;
}

export interface LandingPageConfig {
    type: LandingPageConfigType;
}

export interface LandingPageRedirectConfig extends LandingPageConfig {
    type: LandingPageConfigType.Redirect;
    link: LandingPageLink;
}

export enum LandingPageLayoutBackgroundType {
    BackgroundColor = 'BackgroundColor',
    BackgroundImage = 'BackgroundImage'
}

export interface LandingPageLayoutBackground {
    type: LandingPageLayoutBackgroundType;
}

export interface LandingPageLayoutBackgroundColor extends LandingPageLayoutBackground {
    type: LandingPageLayoutBackgroundType.BackgroundColor;
    color: LandingPageColor;
}

export interface LandingPageLayoutBackgroundImage extends LandingPageLayoutBackground {
    type: LandingPageLayoutBackgroundType.BackgroundImage;
    imageFile: LandingPageCdnFileReference;
    overlayText?: CompiledMessage;
}

export enum LandingPageColorType {
    Primary = 'Primary',
    Secondary = 'Secondary',
    Custom = 'Custom',
    Content = 'Content'
}

export interface LandingPageColor {
    type: LandingPageColorType;
}

export interface LandingPagePrimaryColor extends LandingPageColor {
    type: LandingPageColorType.Primary;
}

export interface LandingPageSecondaryColor extends LandingPageColor {
    type: LandingPageColorType.Secondary;
}

export interface LandingPageContentColor extends LandingPageColor {
    type: LandingPageColorType.Content;
}

export interface LandingPageCustomColor extends LandingPageColor {
    type: LandingPageColorType.Custom;
    hexColor: string;
}

export enum LandingPageLayoutRowType {
    Text = 'Text',
    Image = 'Image',
    TextButton = 'TextButton',
    ComplexButton = 'ComplexButton',
    AppStores = 'AppStores',
    CircleButtons = 'CircleButtons'
}

export interface LandingPageLayoutRow {
    type: LandingPageLayoutRowType;
    spacingBottom: number;
    id?: string;
    orderNumber?: number;
}


export interface LandingPageLayoutTextRow extends LandingPageLayoutRow {
    type: LandingPageLayoutRowType.Text;
    text: CompiledMessage;
    size: LandingPageTextSize;
    textColor: LandingPageColor;
    background?: LandingPageLayoutBackground;
    padding?: LandingPagePadding;
    fontWeight?: LandingPageFontWeightClass;
    fontWidth?: LandingPageFontWidthClass;
    fontStyle?: LandingPageFontStyle;
}

export enum LandingPageCdnFileReferenceType {
    SimpleCdnFile = 'SimpleCdnFile',
    LocalizedCdnFile = 'LocalizedCdnFile'
}

export interface LandingPageCdnFileReference {
    type: LandingPageCdnFileReferenceType;
}

export interface LandingPageSimpleCdnFileReference extends LandingPageCdnFileReference {
    type: LandingPageCdnFileReferenceType.SimpleCdnFile;
    cdnFile: number;
}

export interface LandingPageCdnFileLocalization {
    cdnFile: number;
    locale: string;
}

export interface LandingPageLocalizedCdnFileReference extends LandingPageCdnFileReference {
    type: LandingPageCdnFileReferenceType.LocalizedCdnFile;
    localizedFiles: LandingPageCdnFileLocalization[];
}

export interface LandingPageLayoutImageRow extends LandingPageLayoutRow {
    type: LandingPageLayoutRowType.Image;
    imageFile: LandingPageCdnFileReference,
    height?: number;
}

export interface LandingPageLayoutButtonRow extends LandingPageLayoutRow {
    type: LandingPageLayoutRowType.TextButton | LandingPageLayoutRowType.ComplexButton;
    link: LandingPageLink;
    textColor: LandingPageColor;
    background?: LandingPageLayoutBackground;
    borderColor?: LandingPageColor;
    borderRadius?: number;
    borderWidth: number;
    fontWeight?: LandingPageFontWeightClass;
    fontWidth?: LandingPageFontWidthClass;
    fontStyle?: LandingPageFontStyle;
    height?: number;
}

export enum LandingPageLayoutTextButtonHoverType {
    None = 'None',
    OverwriteStyling = 'OverwriteStyling',
    InvertStyling = 'InvertStyling',
    Darken = 'Darken',
    Lighten = 'Lighten'
}

export interface LandingPageLayoutTextButtonHover {
    type: LandingPageLayoutTextButtonHoverType;
}

export interface LandingPageLayoutTextButtonNoneHover extends LandingPageLayoutTextButtonHover {
    type: LandingPageLayoutTextButtonHoverType.None;
}

export interface LandingPageLayoutTextButtonOverwriteStylingHover extends LandingPageLayoutTextButtonHover {
    type: LandingPageLayoutTextButtonHoverType.OverwriteStyling;
    textColor: LandingPageColor;
    background?: LandingPageLayoutBackground;
    borderColor?: LandingPageColor;
    fontWeight?: LandingPageFontWeightClass;
    fontStyle?: LandingPageFontStyle;
}

export interface LandingPageLayoutTextButtonInvertStylingHover extends LandingPageLayoutTextButtonHover {
    type: LandingPageLayoutTextButtonHoverType.InvertStyling;
}

export interface LandingPageLayoutTextButtonDarkenHover extends LandingPageLayoutTextButtonHover {
    type: LandingPageLayoutTextButtonHoverType.Darken;
    percent: number;
}

export interface LandingPageLayoutTextButtonLightenHover extends LandingPageLayoutTextButtonHover {
    type: LandingPageLayoutTextButtonHoverType.Lighten;
    percent: number;
}

export interface LandingPageLayoutTextButtonRow extends LandingPageLayoutButtonRow {
    type: LandingPageLayoutRowType.TextButton;
    text: CompiledMessage;
    hover: LandingPageLayoutTextButtonHover;
}

export enum LandingPageLayoutComplexButtonContentType {
    TextContent = 'TextContent',
    IconContent = 'IconContent',
    ImageContent = 'ImageContent'
}

export interface LandingPageLayoutComplexButtonContent {
    type: LandingPageLayoutComplexButtonContentType;
    spacingLeft: number;
    spacingRight: number;
}

export interface LandingPageLayoutComplexButtonTextContent extends LandingPageLayoutComplexButtonContent {
    type: LandingPageLayoutComplexButtonContentType.TextContent;
    text: CompiledMessage;
    textColor?: LandingPageColor;
    fontWeight?: LandingPageFontWeightClass;
    fontWidth?: LandingPageFontWidthClass;
    fontStyle?: LandingPageFontStyle;
    textSize?: LandingPageTextSize;
}

export interface LandingPageLayoutComplexButtonIconContent extends LandingPageLayoutComplexButtonContent {
    type: LandingPageLayoutComplexButtonContentType.IconContent;
    icon: LandingPageIcon;
    color?: LandingPageColor;
}

export interface LandingPageLayoutComplexButtonImageContent extends LandingPageLayoutComplexButtonContent {
    type: LandingPageLayoutComplexButtonContentType.ImageContent;
    imageFile: LandingPageCdnFileReference;
    height?: number;
}

export enum LandingPageLayoutComplexButtonHoverType {
    None = 'None',
    OverwriteContent = 'OverwriteContent'
}

export interface LandingPageLayoutComplexButtonHover {
    type: LandingPageLayoutComplexButtonHoverType;
}

export interface LandingPageLayoutComplexButtonNoneHover extends LandingPageLayoutComplexButtonHover {
    type: LandingPageLayoutComplexButtonHoverType.None;
}

export interface LandingPageLayoutComplexButtonOverwriteContentHover extends LandingPageLayoutComplexButtonHover {
    type: LandingPageLayoutComplexButtonHoverType.OverwriteContent;
    textColor?: LandingPageColor;
    background?: LandingPageLayoutBackground;
    borderColor?: LandingPageColor;
    content: LandingPageLayoutComplexButtonContent[];
    fontWeight?: LandingPageFontWeightClass;
    fontStyle?: LandingPageFontStyle;
}

export interface LandingPageLayoutComplexButtonRow extends LandingPageLayoutButtonRow {
    type: LandingPageLayoutRowType.ComplexButton;
    content: LandingPageLayoutComplexButtonContent[];
    hover: LandingPageLayoutComplexButtonHover;
}

export interface LandingPageLayoutAppStoresRow extends LandingPageLayoutRow {
    type: LandingPageLayoutRowType.AppStores;
    appStores: LandingPageAppStore[];
}

export enum LandingPageIconThemeType {
    Primary = 'Primary',
    Secondary = 'Secondary',
    Custom = 'Custom'
}

export interface LandingPageIconTheme {
    type: LandingPageIconThemeType;
}

export interface LandingPageIconPrimaryTheme extends LandingPageIconTheme {
    type: LandingPageIconThemeType.Primary;
}

export interface LandingPageIconSecondaryTheme extends LandingPageIconTheme {
    type: LandingPageIconThemeType.Secondary;
}

export interface LandingPageIconCustomTheme extends LandingPageIconTheme {
    type: LandingPageIconThemeType.Custom;
    textColor: string;
    backgroundColor: string;
}

export interface LandingPageCircleButton {
    theme: LandingPageIconTheme;
    icon: LandingPageIcon;
    link: LandingPageLink;
}

export interface LandingPageLayoutCircleButtonsRow extends LandingPageLayoutRow {
    type: LandingPageLayoutRowType.CircleButtons;
    buttons: LandingPageCircleButton[];
}

export enum LandingPageFontFamilyType {
    Preset = 'Preset',
    Custom = 'Custom'
}

export interface LandingPageFontFamily {
    type: LandingPageFontFamilyType;
}

export enum LandingPageFont {
    Barlow = 'Barlow',
    Arial = 'Arial',
    OpenSans = 'OpenSans',
    Roboto = 'Roboto'
}

export interface LandingPagePresetFontFamily extends LandingPageFontFamily {
    type: LandingPageFontFamilyType.Preset;
    presetFontFamily: LandingPageFont;
}


export interface LandingPageCustomFontFamily extends LandingPageFontFamily {
    type: LandingPageFontFamilyType.Custom;
    cdnFiles: number[];
}

export interface LandingPageLayoutConfig extends LandingPageConfig {
    type: LandingPageConfigType.Layout;
    browserTitle?: CompiledMessage;
    displayLanguageSelectionIfPossible: boolean;
    background?: LandingPageLayoutBackground;
    rows: LandingPageLayoutRow[];
    primaryColor: string;
    primaryContrastColor: string;
    secondaryColor: string;
    secondaryContrastColor: string;
    contentTextColor: string;
    fontFamily: LandingPageFontFamily;
    locales: string[];
}

export enum LandingPageReleaseStateType {
    PreRelease = 'PreRelease',
    Active = 'Active',
    Inactive = 'Inactive',
    ServeHistoricVersion = 'ServeHistoricVersion'
}

export interface LandingPageReleaseState {
    type: LandingPageReleaseStateType;
}

export interface LandingPageReleaseServeHistoricVersionState extends LandingPageReleaseState {
    type: LandingPageReleaseStateType.ServeHistoricVersion;
    historyId: number;
}

export interface ConfigurationLandingPage<ConfigType extends LandingPageConfig = LandingPageConfig> {
    opaqueId: string;
    name: CompiledMessage;
    hierarchyNode: number;
    description: CompiledMessage;
    // String below QR Code
    displayText: CompiledMessage;
    config: ConfigType;
    state: LandingPageReleaseState;
    forTesting?: boolean;
    referencedCdnFiles: number[];
    createdTimestamp: string;
    modifiedTimestamp: string;
}


export enum LandingPageQRCodeFileType {
    PNG = 'PNG',
    SVG = 'SVG',
    EPS = 'EPS'
}

export enum LandingPageCdnFileTypeSpecificInformationType {
    None = 'None',
    Image = 'Image',
    Font = 'Font'
}

export interface LandingPageCdnFileTypeSpecificInformation {
    type: LandingPageCdnFileTypeSpecificInformationType;
}

export interface LandingPageCdnNoneFileTypeSpecificInformation extends LandingPageCdnFileTypeSpecificInformation {
    type: LandingPageCdnFileTypeSpecificInformationType.None;
}

export interface LandingPageCdnFileScaledImage {
    fileName: string;
    width: number;
}

export interface LandingPageCdnImageFileTypeSpecificInformation extends LandingPageCdnFileTypeSpecificInformation {
    type: LandingPageCdnFileTypeSpecificInformationType.Image;
    originalWidth: number;
    scaledImages: LandingPageCdnFileScaledImage[];
}

export enum ImageFileExtension {
    PNG = '.png',
    JPG = '.jpg',
    JPEG = '.jpeg',
    SVG = '.svg'
}

export enum FontFileType {
    TTF = 'TTF',
    WOFF = 'WOFF',
    WOFF2 = 'WOFF2',
    EOT = 'EOT'
}

export interface LandingPageCdnFontFileTypeSpecificInformation extends LandingPageCdnFileTypeSpecificInformation {
    type: LandingPageCdnFileTypeSpecificInformationType.Font;
    baseFontFamily: string;
    fontFamily: string;
    fontName: string;
    weight: LandingPageFontWeightClass;
    widthClass: LandingPageFontWidthClass;
    style: LandingPageFontStyle;
    fileType: FontFileType;
}

export interface LandingPageState {
    landingPages: PaginationState<ConfigurationLandingPage<LandingPageConfig>>;
    fetchStatus: {
        load: FetchStatus;
        create: FetchStatus;
        update: Record<string, FetchStatus | undefined>;
        downloadQRCode: Record<string, FetchStatus | undefined>;
        loadLandingPage: Record<string, FetchStatus | undefined>;
        cdnFiles: FetchStatus;
        fileUpload: FetchStatus;
        preview: Record<string, FetchStatus | undefined>;
        clone: Record<string, FetchStatus | undefined>;
    };
    created: {
        id?: string;
    };
    cdnFiles: {
        files: Record<number, LandingPageCdnFileDTO | undefined>
        images: Record<number, LandingPageCdnFileDTO<LandingPageCdnImageFileTypeSpecificInformation | LandingPageCdnNoneFileTypeSpecificInformation> | undefined>;
        // Record from family name to file ids
        fontFamilies: Record<string, number[] | undefined>;
    };
    fileUpload: {
        progress: number;
        files: LandingPageCdnFileDTO[];
    };
    preview: Record<string, LandingPage | undefined>;
}
