import {FetchStatus, Language, CompiledMessage} from '@software/reactcommons';
import {
    DashboardChartConfiguration,
    TableColumn,
    TableColumnType, TableRow,
    TableType, Sentiment, Threshold
} from '../../../types/charts/chartTypes';
import {TypographyVariant} from '@mui/material';
import {PodiumVariant} from '@software/chartlibrary';
import {FilterQuestion, RankingType} from '../../../components/sites/feedback/dashboard/elements/types';


/************************************************************************************************************************
 * Responses
 *************************************************************************************************************************/

export interface LoadFilterDashboardResponse {
    questions: Question[];
    feedbackCollectionPoints: FeedbackCollectionPoint[];
    filter: Record<string, Filter[]>;
}

export interface DashboardFilterResponse {
    name: string;
    filterType: string;
    selected?: string[];
    mode: string;
    availableFilter: FilterElement[];
    scope: DashboardFilterScope;
}

export enum DashboardResponseType {
    Group = 'Group',
    Dashboard = 'Dashboard'
}

export interface DashboardResponse {
    type: DashboardResponseType;
}

export interface ResponseDashboard {
    id: number;
    name: string;
    orderNumber: number;
    elements: DashboardElement[];
    selectedFilter: Record<string, string[]>;
    selectedRestrictions: Record<string, DashboardFilter>;
    availableFilter: Record<string, DashboardFilter>;
    refreshTimestamp: number;
    parameterFilter?: Filter[];
    configuration?: {
        availableFilter: DashboardFilterResponse[];
        refreshInterval?: number;
        customPeriodFilter: CustomPeriodFilter[];
    }
}

export interface SingleDashboardResponse extends DashboardResponse{
    type: DashboardResponseType.Dashboard;
    dashboard: ResponseDashboard;
}

export interface DashboardGroupDTO {
    id: number;
    name: string;
    textColor?: string;
    backgroundColor?: string;
    order: number;
    dashboards: ResponseDashboard[];
}

export interface DashboardGroupResponse extends DashboardResponse {
    type: DashboardResponseType.Dashboard;
    group: DashboardGroupDTO;
}

export interface DashboardGroup {
    id: number;
    name: string;
    backgroundColor?: string;
    textColor?: string;
    order: number;
    dashboards: number[];
}

export interface SelectValue {
    id?: string;
    value: string | number;
    label?: string;
}

/************************************************************************************************************************
 * Periods
 *************************************************************************************************************************/


export interface CustomPeriod {
    startTimestamp: number;
    endTimestamp?: number;
}

export enum Period {
    LastDay,
    LastWeek,
    LastMonth,
    CalendarWeek,
    Total,
    Custom,
    Today,
    Day,
    LastQuarter,
    YearToDate,
    LastYear
}

export enum ComparisonPeriod {
    PreviousDay = 'PREVIOUS_DAY',
    PreviousWeek = 'PREVIOUS_WEEK',
    PreviousMonth = 'PREVIOUS_MONTH',
    PreviousQuarter = 'PREVIOUS_QUARTER',
    PreviousYear = 'PREVIOUS_YEAR',
    Total = 'TOTAL',
    Custom = 'CUSTOM',
    Day = 'DAY',
    YearToDate = 'YEAR_TO_DATE'
}

export const PeriodToDefaultComparisonPeriod: Partial<Record<Period, ComparisonPeriod>> = {
    [Period.Day]: ComparisonPeriod.PreviousDay,
    [Period.LastWeek]: ComparisonPeriod.PreviousWeek,
    [Period.LastMonth]: ComparisonPeriod.PreviousMonth,
    [Period.LastQuarter]: ComparisonPeriod.PreviousQuarter,
    [Period.Total]: ComparisonPeriod.PreviousYear,
    [Period.YearToDate]: ComparisonPeriod.PreviousYear,
    [Period.LastYear]: ComparisonPeriod.YearToDate,
    [Period.Custom]: ComparisonPeriod.YearToDate,
}

/************************************************************************************************************************
 * Feedback collection points
 *************************************************************************************************************************/


export interface FeedbackCollectionPoint {
    id: number;
    name: string;
}

/************************************************************************************************************************
 * Questions
 *************************************************************************************************************************/

export interface Question {
    id: number;
    name: string;
}

/************************************************************************************************************************
 * VideoDashboard configuration
 *************************************************************************************************************************/

export enum IconType {
    IMAGE,
    MATERIAL_ICON
}

export interface Icon {
    type: IconType;
    name: string;
}


export interface DashboardElementExplanation {
    elements: Record<string, {
        title: string
        description: string
    }>;
}

export interface DashboardElementFilter {
    name: string;
    selectedOptions: string[];
}


/*************************
 * Element configuration
 *************************/


export enum ElementMode {
    Default,
    Compact
}

export enum DashboardElementDataSource {
    Survey = 'SURVEY',
    Operations = 'INTRA',
}

export enum ChartConfigType {
    Divider = 'divider',
    LineChart = 'lineChart',
    Gauge = 'gauge',
    BarChart = 'barChart',
    Podium = 'podium',
    Text = 'text',
    WordCloud = 'wordCloud',
    HeatMap = 'heatMap',
    PodiumDataSourceComparison = 'podiumDataSourceComparison',
    MapDataSourceComparison = 'mapDataSourceComparison',
    RankingDataSourceComparison = 'rankingDataSourceComparison',
    QuestionComparison = 'questionComparison',
    Stat = 'stat',
    TopLevel = 'topLevel',
    Table = 'table',
    Image = 'image',
    OperationsProgress = 'operationsProgress'
}

export interface LoadDashboardElementResponse {
    chart: DashboardChartConfiguration;
    additionalExplanations?: DashboardElementExplanation[];
    n: number;
    elementConfig: DashboardElementConfiguration;
    tooFewRespondents: boolean;
}

export interface DashboardElementConfiguration {
    comparisonPeriodHandling: ComparisonPeriodHandling;
    dataSource: DashboardElementDataSource;
    type: ChartConfigType;
    subChartType: number;
    questions: number[];
    configurationQuestions: FilterQuestion[];
    feedbackCollectionPointIDs: number[];
    periods: CustomPeriod[];
    comparisonPeriods?: CustomPeriod[];
    selectedPeriod: Period;
    filter: Record<string, string[]>;
    historicQuestionGroupIds: number[];
    parameterConfiguration?: { name: string, prefix?: string }[];
    explanations?: DashboardElementExplanation[];
    additionalExplanations?: DashboardElementExplanation[];
    showAvailableFilter?: boolean;
    chart: DashboardChartConfiguration;
    n: number;
    tooFewRespondents: boolean;
    minimumNumberOfRespondents: number;
    frozen?: boolean;
    directFilter: string[];
    thresholds: Record<string, Threshold[]>;
    icon?: Icon;
    mode?: ElementMode;
    restrictions: Record<string, ElementRestriction>;
    width?: number;
    height?: number;
    comparison?: {
        threshold?: number;
        useAbsoluteValue?: boolean;
        period?: ComparisonPeriod;
    };
    showQuestionConfiguration?: boolean;
    availableElementFilter: string[];
}

export interface DashboardDividerElementConfiguration extends DashboardElementConfiguration {
    text: Record<string, string>;
    typographyVariant?: TypographyVariant;
    color?: string;
}

export enum FileDashboardElementDisplayType {
    Slider = 'Slider',
    Grid = 'Grid'
}

export interface FileDashboardElementConfiguration extends DashboardElementConfiguration {
    maxImages?: number;
    displayType?: FileDashboardElementDisplayType;
}

export interface ElementRestriction {
    name: string;
    filterType: string;
    selected?: (string | number)[];
    mode: string;
    availableFilter?: FilterOption[];
}

export interface StatElementConfiguration extends AggregatingFeedbackElementConfiguration {
    type: ChartConfigType.Stat;
    threshold: number;
    showThresholds: boolean;
    useAbsoluteValue: boolean;
}

export interface PeriodGroupingOption {
    minDays?: number;
    maxDays?: number;
    periodGrouping: PeriodGrouping;
}

export interface LineChartElementConfiguration extends AggregatingFeedbackElementConfiguration {
    type: ChartConfigType.LineChart;
    selectedPeriodGrouping?: PeriodGrouping;
    periodGroupingOptions?: PeriodGroupingOption[];
}

export interface TableElementConfiguration extends DashboardElementConfiguration {
    tableType: TableType;
    columns: TableColumn[];
    columnType: TableColumnType;
    rows: TableRow[];
    transposed: boolean;
    numberOfRoundedDigits: number;
    groupedColumnType: TableColumnType;
    showThresholds: boolean;
    groupTotalThreshold?: number;
    rowToColumnQuestions: Record<string, number[]>;
    showNumberOfFeedback: boolean;
    useNullValuesAsZero: boolean;
    showIcons: boolean;
    includeOnlyFinishedFeedback?: boolean;
}

export interface TextElementCategory {
    id: number;
    name: string;
}

export interface TextElementConfiguration extends DashboardElementConfiguration {
    ratingFilter: Sentiment[];
    categoryOptions?: TextElementCategory[];
    categoriesFilter?: number[];
    textSearchHistory?: string[][];
    allowTextSearch: boolean;
    showDate: boolean;
    showTime: boolean;
}

export interface ComparisonElementAvailableRankingType {
    allowedRankingTypes: RankingType[];
    primaryRankingType: RankingType;
}

export enum ComparisonPeriodHandling {
    AllowComparisonPeriod = 'AllowComparisonPeriod',
    RequireComparisonPeriod = 'RequireComparisonPeriod',
    IgnoreComparisonPeriod = 'IgnoreComparisonPeriod'
}

export enum ComparisonGrouping {
    Location = 'Location',
    DataSource = 'DataSource',
    Region = 'Region',
}

export interface AggregatingFeedbackElementConfiguration extends DashboardElementConfiguration {
    selectedRankingType: RankingType;
    questionTypesRankingTypes: ComparisonElementAvailableRankingType[];
}

export interface DataSourceComparisonElementConfiguration extends AggregatingFeedbackElementConfiguration {
    allowRankingOrderSelection?: boolean;
    rankingOrder: RankingOrder;
    allowedGroupings: ComparisonGrouping[];
    selectedGrouping: ComparisonGrouping;
}

export interface DataSourceComparisonPodiumElementConfiguration extends DataSourceComparisonElementConfiguration {
    podiumVariant: PodiumVariant;

}

export enum RankingOrder {
    BestFirst = 'BestFirst',
    WorstFirst = 'WorstFirst'
}

export interface QuestionComparisonConfiguration extends AggregatingFeedbackElementConfiguration {
    type: ChartConfigType.QuestionComparison;
    rankingOrder: RankingOrder;
}

export enum PeriodGrouping {
    Day = 'DAY',
    Week = 'WEEK',
    Month = 'MONTH',
    Quarter = 'QUARTER',
    HalfYear = 'HALF_YEAR',
    Year = 'YEAR'
}

/********************************************************************************************************************
 * Filter
 *********************************************************************************************************************/


export interface Filter {
    name: string;
    options: string[];
}

export interface FilterOption {
    id: number;
    name: string;
    groupName?: string;
    groupID?: number;
}

export interface FilterElement {
    id: number;
    name: string;
    description: string;
    groupID: number;
    groupName: string;
}

export enum DashboardFilterScope {
    Dashboard = 'Dashboard',
    Element = 'Element'
}

export interface DashboardFilter {
    name: string;
    filterType: string;
    selected?: (string | number)[];
    mode: string;
    scope: DashboardFilterScope;
    availableFilter?: Record<string, FilterOption>;
}

/********************************************************************************************************************
 * Dashboard configuration
 *********************************************************************************************************************/

export interface LayoutElement {
    i: string;
    w: number;
    h: number;
    y: number;
    x: number;
}

export enum DashboardElementPreviewConfigType {
    Default = 'default',
    OriginalElement = 'originalElement'
}

export enum DashboardElementPreviewScope {
    Feedback = 'Feedback',
    Operations = 'Operations'
}

export interface DashboardElementPreviewConfig {
    type: DashboardElementPreviewConfigType;
    scopes: DashboardElementPreviewScope[];
    order: number;
}

export interface DefaultDashboardElementPreviewConfig extends DashboardElementPreviewConfig {
    lastDays: number;
}

export interface OriginalElementDashboardElementPreviewConfig extends DashboardElementPreviewConfig {

}

export enum DashboardElementExportStrategy {
    Download = 'Download',
    Email = 'Email'
}

export interface DashboardElementExport {
    id: number;
    name: string;
    exportStrategy: DashboardElementExportStrategy;
}

export interface DashboardElement {
    id: number;
    row: number;
    col: number;
    width: number;
    height: number;
    page?: number;
    pageSize?: number;
    isDragged: boolean;
    showConfig: boolean;
    fetchStatus: FetchStatus;
    config: DashboardElementConfiguration;
    chartConfig: DashboardChartConfiguration;
    selectedPeriod: Period;
    exports?: DashboardElementExport[];
    additionalExplanations?: DashboardElementExplanation[];
    n: number;
    tooFewRespondents: boolean;
    minimumNumberOfRespondents: number;
    name: string;
    downloadFetchStatus?: FetchStatus;
    // Map of export id => fetch status
    exportFetchStatus?: Record<string, FetchStatus>;
    visible: boolean;
    dashboardId: number;
    previewConfig?: DashboardElementPreviewConfig;
}

export interface Translation {
    sourceLanguage: string;
    text: string;
    language: Language;
}

export interface TextAnswer {
    original: boolean;
    answer: string;
    language: Language;
}

export interface ChartTextElement extends DashboardElement {
    text: string;
    time: number;
    parameters: Record<string, string>;
    questionAnswers: Record<string, string>;
    textQuestionAnswers: TextAnswer[];
    color?: string;
    categories?: string[];
}

export interface DashboardSearch {
    id: number;
    search: string;
}

export interface CustomPeriodFilter {
    name: CompiledMessage;
    startTimestamp: number;
    endTimestamp: number;
}

export interface Dashboard {
    id: number;
    name: string;
    orderNumber: number;
    elements: Record<string, DashboardElement | undefined>;
    selectedFilter: Record<string, string[]>;
    selectedRestrictions: Record<string, FilterOption[] | undefined>;
    availableFilter: Record<string, DashboardFilter | undefined>;
    refreshTimestamp: number;
    search?: Record<string, DashboardSearch>;
    refreshInterval?: number;
    filter?: Filter[];
    hasComparison?: boolean;
    customPeriodFilter: CustomPeriodFilter[];
}



/**
 * For some chart types, not all period should be applicable. This map provides the blacklisted periods for the chart types.
 */
export const PeriodBlackList: Partial<Record<ChartConfigType, number[]>> = {
    [ChartConfigType.LineChart]: [Period.LastDay, Period.CalendarWeek, Period.Day, Period.Today]
};


/********************************************************************************************************************
 * State
 *********************************************************************************************************************/


export interface DashboardState {
    fetchStatus: FetchStatus;
    dashboardFetchStatus: Record<number, FetchStatus | undefined>;
    groups: Record<string, DashboardGroup | undefined>;
    dashboards: Record<string, Dashboard | undefined>;
    selectedDashboard: number;
    questions: Record<string, Question>;
    feedbackCollectionPoints: Record<string, FeedbackCollectionPoint | undefined>;
    live: {
        enabled: boolean;
        interval: number;
    },
    inline360Enabled: boolean;
}