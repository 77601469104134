/** @jsxRuntime classic */
import 'react-app-polyfill/stable';
import './polyfill/polyfill';
import React from 'react';
import connection, {initialConnectionState} from './redux/reducer/connection/connection';
import application, {initialApplicationState} from './redux/reducer/application/application';
import dashboard from './redux/reducer/dashboard/dashboard';
import 'swiper/css/bundle';
import 'plyr/dist/plyr.css';
import './index.css';
import DOMPurify from 'dompurify';
import user, {initialUserState} from './redux/reducer/user/user';
import videos, {initialVideoState} from './redux/reducer/videos/videos';
import admin, {initialAdminState} from './redux/reducer/admin/admin';
import operations, {initialOperationsState} from './redux/reducer/operations/operations';
import administration, {initialAdministrationState} from './redux/reducer/administration/administration';
import reports, {initialReportState} from './redux/reducer/reports/reports';
import webSocketMiddleware from './redux/middleware/webSocketMiddleware';
import {getEnv, webApp} from '@software/reactcommons';
import Structure from './components/skeleton/Structure';
import DashboardSaga from './redux/sagas/feedback/DashboardSaga';
import VideoSaga from './redux/sagas/VideoSaga';
import AdminSaga from './redux/sagas/VideoAdminSaga';
import UserSaga from './redux/sagas/UserSaga';
import ReportSaga from './redux/sagas/feedback/ReportSaga';
import {EnvironmentVariables} from './types/Types';
import OperationsSaga from './redux/sagas/operations/OperationsSaga';
import AdministrationSaga from './redux/sagas/AdministrationSaga';
import * as serviceWorker from './serviceWorker';
import {CancelAllSagasViaForcedTabLogoutActionKey, logoutAction} from '@software/reactcommons-security';
import ApplicationSaga from './redux/sagas/ApplicationSaga';
import {LicenseInfo} from '@mui/x-license';
import landingPages, {initialLandingPageState} from './redux/reducer/landingPages/landingPages';
import LandingPageSaga from './redux/sagas/LandingPageSaga';
import login, {initialLoginState} from './redux/reducer/login/login';


LicenseInfo.setLicenseKey(getEnv<EnvironmentVariables>()?.REACT_APP_MUI_LICENSE_KEY || '');

// Globally prevent target attribute to be sanitized
DOMPurify.setConfig({ADD_ATTR: ['target']});

webApp({
    component: (<Structure/>),
    reducers: [{
        key: 'application',
        reducer: application,
        initialState: initialApplicationState
    }, {
        key: 'connection',
        reducer: connection,
        initialState: initialConnectionState
    }, {
        key: 'user',
        reducer: user,
        initialState: initialUserState
    }, {
        key: 'videos',
        reducer: videos,
        initialState: initialVideoState
    }, {
        key: 'admin',
        reducer: admin,
        initialState: initialAdminState
    }, {
        key: 'operations',
        reducer: operations,
        initialState: initialOperationsState
    }, {
        key: 'administration',
        reducer: administration,
        initialState: initialAdministrationState
    }, {
        key: 'reports',
        reducer: reports,
        initialState: initialReportState
    }, {
        key: 'landingPages',
        reducer: landingPages,
        initialState: initialLandingPageState
    }, {
        key: 'login',
        reducer: login,
        initialState: initialLoginState
    }],
    resetActionKeys: [logoutAction.actionKey, CancelAllSagasViaForcedTabLogoutActionKey],
    persist: {
        enabled: true,
        blacklist: ['connection', 'dashboard', 'application', 'user', 'videos', 'operations', 'administration', 'reports', 'landingPages'],
        database: getEnv<EnvironmentVariables>().REACT_APP_DATABASE || '',
        reducers: [{
            key: 'dashboard',
            persistConfig: (storage) => ({
                key: 'dashboard',
                storage,
                whitelist: ['selectedDashboard']
            }),
            reducer: dashboard
        }, {
            key: 'login',
            persistConfig: (storage) => ({
                key: 'login',
                storage
            }),
            reducer: login
        }]
    },
    ignoredActionKeys: ['landingPages/uploadLandingPageFiles', 'landingPages/fetchUploadLandingPageFiles'],
    sagas: [
        {generator: DashboardSaga},
        {generator: VideoSaga},
        {generator: AdminSaga},
        {generator: UserSaga},
        {generator: OperationsSaga},
        {generator: AdministrationSaga},
        {generator: ReportSaga},
        {generator: ApplicationSaga},
        {generator: LandingPageSaga}
    ],
    middlewares: [webSocketMiddleware]
});

serviceWorker.unregister();
