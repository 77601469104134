import React from 'react';
import {Navigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {AppState} from '../../types/Types';
import Routes from '../../constants/routes';
import {FetchStatus} from '@software/reactcommons';

/**
 * Component that handles user redirection based on availability of sites. This can be used as fallback component if
 * no other component matches.
 *
 * @component
 * @category Navigation
 *
 * @returns The rendered navigate component or null if there are no available sites.
 */
const UserRedirection: React.FC = () => {

    const availableSites = useSelector((state: AppState) => state.user.availableSites[0]);
    const fetchStatus = useSelector((state: AppState) => state.user.fetchStatus.availableSites);

    // Redirect to the first route which is found.
    if (availableSites) {
        return (<Navigate to={availableSites.route}/>);
    }
    // If sites have been successfully fetched but no sites are available return to the login site.
    if (fetchStatus === FetchStatus.Success) {
        return (<Navigate to={Routes.Login.Base('')}/>);
    }
    // Sites have not been fetch yet, do nothing
    return null;
}

export default UserRedirection;