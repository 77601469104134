import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {LoginState} from './types';


export const initialLoginState: LoginState = {
    username: '',
    staySignedIn: false,
};

const login = createSlice({
    name: 'login',
    initialState: initialLoginState,
    reducers: {
        setUsername: (state, action: PayloadAction<string>) => {
            state.username = action.payload;
        },
        setStaySignedIn: (state, action: PayloadAction<boolean>) => {
            state.staySignedIn = action.payload;
        },
        clearLoginInformation: (state) => {
            state.username = initialLoginState.username;
            state.staySignedIn = initialLoginState.staySignedIn;
        }
    },
    extraReducers: builder => builder
});

export const {setUsername, setStaySignedIn, clearLoginInformation} = login.actions;

export default login.reducer;