import {ActionReducerMapBuilder, PayloadAction} from '@reduxjs/toolkit';
import {VideoCategory, VideoState, VideoWish} from './types';
import {
    saveUploadedVideoAction,
    setUploadVideoProperty,
    SetUploadVideoTagsForTagTypeActionPayload,
    uploadVideoAction
} from './actions';
import {FetchStatus, notUndefined} from '@software/reactcommons';
import {initialVideoState, mergeNewVideoIntoState} from './videos';
import {SelectValue} from '../dashboard/types';

export const uploadReducers = {
    resetUpload: (state: VideoState) => {
        state.upload = {...initialVideoState.upload}
    },
    setUploadVideoTags: (state: VideoState, action: PayloadAction<SelectValue[]>) => {
        state.upload.tags = action.payload ? action.payload.filter((tag: SelectValue) => {
            return tag.value !== tag.label
        }).map((tag: SelectValue) => state.tags.elements[tag.value]).filter(notUndefined) : [];
        state.upload.createdTags = action.payload ? action.payload.filter((tag) => tag.value === tag.label).map((tag) => ({
            id: tag.label || '',
            name: tag.label || ''
        })) : [];
    },
    setUploadVideoWishes: (state: VideoState, action: PayloadAction<VideoWish[]>) => {
        state.upload.wishIds = action.payload.map(it => it.id);
    },
    setUploadVideoTagsForTagType: (state: VideoState, action: PayloadAction<SetUploadVideoTagsForTagTypeActionPayload>) => {
        state.upload.selectedTagsByTagType[action.payload.tagType] = action.payload.tags || [];
    },
    setUploadVideoCategories: (state: VideoState, action: PayloadAction<VideoCategory[]>) => {
        state.upload.categories = action.payload || [];
    },
    setUploadVideoProgress: (state: VideoState, action: PayloadAction<number>) => {
        state.upload.progress = action.payload;
    }
}

export const extraUploadReducers = (builder: ActionReducerMapBuilder<VideoState>) =>
    builder.addCase(setUploadVideoProperty, (state, action) => {
        // TODO Find out why TypeScript fails here
        // @ts-ignore
        state.upload[action.payload.key] = action.payload.value;
    }).addCase(uploadVideoAction.startAction, state => {
        state.upload.fetchStatus = FetchStatus.Active;
        state.upload.progress = 0;
    }).addCase(uploadVideoAction.errorAction, state => {
        state.upload.fetchStatus = FetchStatus.Error;
        state.upload.progress = 0;
    }).addCase(uploadVideoAction.successAction, (state, action) => {
        state.upload.fetchStatus = FetchStatus.Success;
        state.upload.previewImagePath = action.payload.previewImagePath;
        state.upload.previewVideoPath = action.payload.videoPath;
    }).addCase(saveUploadedVideoAction.startAction, state => {
        state.upload.saveFetchStatus = FetchStatus.Active;
    }).addCase(saveUploadedVideoAction.errorAction, state => {
        state.upload.saveFetchStatus = FetchStatus.Error;
    }).addCase(saveUploadedVideoAction.resetAction, state => {
        state.upload.saveFetchStatus = FetchStatus.Default;
    }).addCase(saveUploadedVideoAction.successAction, (state, action) => {
        state.upload.saveFetchStatus = FetchStatus.Success;
        state.upload.videoID = action.payload.videoID;
        mergeNewVideoIntoState(state, action.payload, true)
    });
