import {createAuthenticatedSagaFetchAction, SecurityErrorCode} from '@software/reactcommons-security';
import {BaseErrorCode, createBasicSagaFetchAction, getRequest, postRequest} from '@software/reactcommons';
import {Route} from '../../../api/Api';
import {
    PdfOutline,
    Report,
    ReportFilterScope,
    ReportSearch,
    ReportTimeSpanType
} from './types';
import {ApiListResponse} from '../administration/actions';
import {createAction} from '@reduxjs/toolkit';
import {PDFDocumentProxy} from 'pdfjs-dist/types/src/display/api';

export const setReportSearchText = createAction<string>('reports/setReportsSearchText');

export const loadReportsActionAndSaga = createAuthenticatedSagaFetchAction<ReportSearch, ApiListResponse<Report>, never>({
    actionGroup: 'reports',
    actionName: 'loadReports',
    networkCall: (jwt, selectedLanguage, requestBody) => {
        return postRequest(Route.Reports.Load, {
            body: {
                ...(requestBody as ReportSearch),
                replace: undefined
            }, jwt, selectedLanguage
        });
    },
    debounceDelay: 500
});

export const loadReportScopesActionAndSaga = createAuthenticatedSagaFetchAction<void, ApiListResponse<ReportFilterScope>, never>({
    actionGroup: 'reports',
    actionName: 'loadReportScopes',
    networkCall: (jwt, selectedLanguage) => {
        return getRequest(Route.Reports.LoadScopes, {jwt, selectedLanguage});
    }
});

export const loadReportTimeSpansActionAndSaga = createAuthenticatedSagaFetchAction<void, ApiListResponse<ReportTimeSpanType>, never>({
    actionGroup: 'reports',
    actionName: 'loadReportTimeSpans',
    networkCall: (jwt, selectedLanguage) => {
        return getRequest(Route.Reports.LoadTimeSpans, {jwt, selectedLanguage});
    }
});

export const loadLatestReportsActionAndSaga = createAuthenticatedSagaFetchAction<ReportSearch, ApiListResponse<Report>, never>({
    actionGroup: 'reports',
    actionName: 'loadLatest',
    networkCall: (jwt, selectedLanguage, requestBody) => {
        return postRequest(Route.Reports.Load, {body: requestBody, jwt, selectedLanguage});
    }
});

export const loadReportsByReportScopeActionAndSaga = createAuthenticatedSagaFetchAction<ReportSearch, ApiListResponse<Report>, SecurityErrorCode>({
    actionGroup: 'reports',
    actionName: 'loadReportsByReportScope',
    networkCall: (jwt, selectedLanguage, requestBody) => {
        return postRequest(Route.Reports.Load, {body: requestBody, jwt, selectedLanguage});
    }
});

export interface LoadReportPayload {
    opaqueId: string;
}

export const loadReportActionAndSaga = createAuthenticatedSagaFetchAction<LoadReportPayload, Report, SecurityErrorCode>({
    actionGroup: 'reports',
    actionName: 'loadReport',
    networkCall: (jwt, selectedLanguage, requestBody) => {
        return getRequest(Route.Reports.LoadReport(requestBody?.opaqueId || ''), {jwt, selectedLanguage});
    }
});

export const checkDownloadReportAccessActionSaga = createBasicSagaFetchAction<string, void, BaseErrorCode>({
    actionGroup: 'reports',
    actionName: 'checkDownloadReportAccess',
    networkCall: (selectedLanguage, opaqueId) => {
        return getRequest(Route.Reports.CheckDownloadAccess(opaqueId || ''), {selectedLanguage});
    }
});

export interface LoadPdfOutlinePayload {
    filePath: string;
    pdf: PDFDocumentProxy;
}

export const loadPdfOutline = createAction<LoadPdfOutlinePayload>('reports/loadPdfOutline');

export interface SetPdfOutlinePayload {
    filePath: string;
    outline: PdfOutline[];
}

export interface SetActivePdfReportSearchResultsPayload {
    usedSearch: string;
    pages: number[];
}
