import {
    createFetchAction,
    deleteRequest,
    getRequest,
    postRequest,
    putRequest,
    setRedirect,
    UploadFile
} from '@software/reactcommons';
import {
    AnswerAttachment,
    Editor,
    EditToDo, Person, PreFinishToDoResult,
    Questionnaire, ReviewToDo, ReviewToDoRemark,
    TaskAnswer,
    ToDoDTO, ToDoReview, ToDoReviewConfiguration,
    ToDoTaskType
} from './types';
import {createAuthenticatedSagaFetchAction, SecurityErrorCode} from '@software/reactcommons-security';
import {
    ApiListResponse
} from '../administration/actions';
import {Route} from '../../../api/Api';
import {createAction} from '@reduxjs/toolkit';
import {IdParams} from '../../../types/Types';
import {call, put} from 'redux-saga/effects';
import {
    addPreviousToDosToState, declineToDoReviewSuccess,
    setOperationsSaveReviewerSuccess, setToDoPreFinishSuccess,
    updateReviewDeadlines,
    updateToDoDeadlines
} from './operations';
import Routes from '../../../constants/routes';
import {CompiledMessage} from '@software/reactcommons/dist/localization/message/message.types';
import {handlePreFinishResults} from '../../sagas/operations/OperationsSaga';

export const loadOpenToDosAction = createFetchAction<{}, ApiListResponse<ToDoDTO>, 'UNKNOWN', {}>('operations', 'loadOpenToDos');
export const loadFinishedToDosAction = createFetchAction<{
    replace?: boolean;
}, ApiListResponse<ToDoDTO>, 'UNKNOWN', {}>('operations', 'loadFinishedToDos');

export const loadReviewToDosAction = createFetchAction<{}, ApiListResponse<ToDoDTO>, 'UNKNOWN', {}>('operations', 'loadReviewToDos');
export const loadReviewRequestsAction = createFetchAction<{
    replace: boolean;
}, ApiListResponse<ToDoDTO>, 'UNKNOWN', {}>('operations', 'loadReviewRequests');

export const loadUserReviewToDosAction = createFetchAction<{
    replace?: boolean;
}, ApiListResponse<ToDoDTO>, 'UNKNOWN', {}>('operations', 'loadUserReviewToDos');

export const loadUserReviewRequestsAction = createFetchAction<{
    replace?: boolean;
}, ApiListResponse<ToDoDTO>, 'UNKNOWN', {}>('operations', 'loadUserReviewRequests');

export const loadSubordinatedToDosAction = createFetchAction<{
    replace?: boolean;
}, ApiListResponse<ToDoDTO>, 'UNKNOWN', {}>('operations', 'loadSubordinatedToDos');

export const loadSubordinatedUserReviewToDosAction = createFetchAction<{
    replace?: boolean;
}, ApiListResponse<ToDoDTO>, 'UNKNOWN', {}>('operations', 'loadSubordinatedUserReviewToDos');

export const loadSubordinatedUserReviewRequestsAction = createFetchAction<{
    replace?: boolean;
}, ApiListResponse<ToDoDTO>, 'UNKNOWN', {}>('operations', 'loadSubordinatedUserReviewRequests');

export const loadRequestsToDosAction = createFetchAction<{
    replace?: boolean;
}, ApiListResponse<ToDoDTO>, 'UNKNOWN', {}>('operations', 'loadRequestToDos');

export const loadApprovedRequestsAction = createFetchAction<{
    replace?: boolean;
}, ApiListResponse<ToDoDTO>, 'UNKNOWN', {}>('operations', 'loadApprovedRequests');

export const loadDeclinedRequestsAction = createFetchAction<{
    replace?: boolean;
}, ApiListResponse<ToDoDTO>, 'UNKNOWN', {}>('operations', 'loadDeclinedRequests');

export const loadExpiredRequestsAction = createFetchAction<{
    replace?: boolean;
}, ApiListResponse<ToDoDTO>, 'UNKNOWN', {}>('operations', 'loadExpiredRequests');

export const loadOperationsEditorsAction = createFetchAction<{
    search: string
}, Editor[], 'UNKNOWN'>('operations', 'loadOperationsEditors');

export interface DownloadToDoActionPayload {
    toDos: { id: number; name: string; }[];
}

export const downloadToDosAction = createFetchAction<DownloadToDoActionPayload, DownloadToDoActionPayload, 'UNKNOWN', DownloadToDoActionPayload>('operations', 'downloadToDo')

export type SetEditorStatePart = 'overview' | 'subordinatedToDos' | 'requestOverview';
export type SetReviewerStatePart =
    'userReviewToDos'
    | 'subordinatedUserReviewToDos'
    | 'userReviewRequests'
    | 'subordinatedUserReviewRequests';

export interface SaveOperationsEditorsPayload {
    toDoId: number;
    userIds: number[];
    statePart: SetEditorStatePart;
}

export interface SaveOperationsEditorsSuccessPayload {
    toDoId: number;
    emails: string[];
    statePart: SetEditorStatePart;
}

export const saveOperationsEditorsAction = createFetchAction<SaveOperationsEditorsPayload, SaveOperationsEditorsSuccessPayload, 'UNKNOWN', {
    toDoId: number;
    statePart: SetEditorStatePart;
}>('operations', 'saveOperationsEditors');

export interface SendOperationsToEmailPayload {
    ids: number[];
    text: string;
    subject: string;
    recipients: number[];
}

export const sendOperationsToDoEmailAction = createFetchAction<SendOperationsToEmailPayload, number[], 'UNKNOWN', number[]>('operations', 'sendToDoEmail');

export const loadManualToDosActionAndSaga = createAuthenticatedSagaFetchAction<void, ApiListResponse<Questionnaire>, never>({
    actionGroup: 'operations',
    actionName: 'loadManualToDos',
    networkCall: (jwt, selectedLanguage) => {
        return getRequest(Route.Operations.LoadManualToDos, {jwt, selectedLanguage});
    }
});

export const loadAvailableToDosActionAndSaga = createAuthenticatedSagaFetchAction<void, ApiListResponse<Questionnaire>, never>({
    actionGroup: 'operations',
    actionName: 'loadAvailableToDos',
    networkCall: (jwt, selectedLanguage) => {
        return getRequest(Route.Operations.LoadAvailableToDos, {jwt, selectedLanguage});
    }
});

export interface CreateToDoPayload {
    toDoId: number;
    locationId: number;
}

export const createManualToDoActionAndSaga = createAuthenticatedSagaFetchAction<CreateToDoPayload, ToDoDTO, never>({
    actionGroup: 'operations',
    actionName: 'createManualToDo',
    networkCall: (jwt, selectedLanguage, body) => {
        return postRequest(Route.Operations.CreateToDo, {body, jwt, selectedLanguage});
    }
});


export interface SendContinueEmailPayload {
    id: number;
    continueUrl: string;
}

export const sendContinueEmailActionAndSaga = createAuthenticatedSagaFetchAction<SendContinueEmailPayload, void, SecurityErrorCode>({
    actionGroup: 'operations',
    actionName: 'sendContinueEmail',
    networkCall: (jwt, selectedLanguage, body) => {
        return postRequest(Route.Operations.SendContinueEmail, {body, jwt, selectedLanguage})
    }
});

export interface LoadToDoPayload {
    id: number;
}

export const loadToDoActionAndSaga = createAuthenticatedSagaFetchAction<LoadToDoPayload, EditToDo, SecurityErrorCode>({
    actionGroup: 'operations',
    actionName: 'loadToDo',
    networkCall: (jwt, selectedLanguage, body) => {
        return getRequest(Route.Operations.LoadToDo(body?.id || ''), {jwt, selectedLanguage});
    }
});

export interface UploadOperationsFilesPayload {
    files: UploadFile[];
    toDoId: number;
    questionId: number;
}

export interface UploadOperationsFilesSuccessPayload {
    file: AnswerAttachment;
    toDoId: number;
    questionId: number;
    fileId: string;
    uploadedFile: File;
}

export const uploadOperationsFilesAction = createFetchAction<UploadOperationsFilesPayload, UploadOperationsFilesSuccessPayload, never>('operations', 'uploadOperationsFiles');

export interface FinishOperationsFileUploadPayload {
    toDoId: number;
    questionId: number;
    fileIds: string[];
}

export interface SetOperationsFileUploadProgressPayload {
    progress: number;
    toDoId: number;
    fileId: string;
    questionId: number;
}


export interface DeleteFailedOperationsFileUploadPayload {
    toDoId: number;
    fileId: string;
    questionId: number;
}

export interface SetOperationsQuestionAnswerPayload<AnswerType extends TaskAnswer> {
    answer?: AnswerType;
    toDoId: number;
    questionId: number;
    questionType: ToDoTaskType;
}

export const setOperationsTaskAnswer = <AnswerType extends TaskAnswer>() => createAction<SetOperationsQuestionAnswerPayload<AnswerType>>('operations/setOperationsTaskAnswer');

export interface SyncOperationsToDoActionPayload {
    toDoId: number;
    questionId: number;
    timestamp: number;
}

export interface SyncOperationsToDoSuccessActionPayload extends SyncOperationsToDoActionPayload {
    complete: boolean;
    serverTimestamp: string;
}

export interface SyncOperationsTaskResponse {
    questionId: number;
    complete: boolean;
    serverTimestamp: string;
}

export interface SyncOperationsToDoResponse {
    tasks: SyncOperationsTaskResponse[];
    timestamp: number;
}

export const syncOperationsToDoAction = createFetchAction<SyncOperationsToDoActionPayload, SyncOperationsToDoSuccessActionPayload, 'UNKNOWN', SyncOperationsToDoActionPayload>('operation', 'syncOperationsToDoAction');

export interface DeleteOperationsFilePayload {
    toDoId: number;
    questionId: number;
    path: string;
}

export const deleteOperationsFileActionAndSaga = createAuthenticatedSagaFetchAction<DeleteOperationsFilePayload, DeleteOperationsFilePayload, never>({
    actionGroup: 'operations',
    actionName: 'deleteOperationsFile',
    networkCall: (jwt, selectedLanguage, params) => {
        return deleteRequest(Route.Operations.DeleteFile(params!.toDoId, params!.questionId, params!.path), {
            jwt,
            selectedLanguage
        });
    }
});

export interface LockToDoTaskPayload {
    toDoId: number;
    taskId: number;
    timestamp: number;
}

export interface WebSocketUpdateToDoAnswerPayload {
    toDoId: number;
    serverTimestamp: number;
    answers: TaskAnswer[];
    questionState: Record<string, boolean>;
}

export interface WebSocketRemoveToDoAnswerPayload {
    toDoId: number;
    serverTimestamp: number;
    answerIds: number[];
}

export interface FinishToDoPayload {
    toDoId: number;
}

export const finishToDoActionAndSaga = createAuthenticatedSagaFetchAction<FinishToDoPayload, FinishToDoPayload, SecurityErrorCode>({
    actionGroup: 'operations',
    actionName: 'finishToDo',
    networkCall: (jwt, selectedLanguage, body) => {
        return postRequest(Route.Operations.FinishToDo(body!.toDoId), {jwt, selectedLanguage})
    }
});

export interface SetToDoUpdatedTimestampPayload {
    toDoId: number;
    timestamp: number;
}

export const enablePeriodicToDoSync = createAction<number>('operations/enablePeriodicToDoSync');
export const disabledPeriodicToDoSync = createAction<number>('operations/disablePeriodicToDoSync');

export interface CheckPeriodicToDoSyncResponse {
    id: number;
    lastUpdatedTimestamp: number;
}

export const reloadToDoActionAndSaga = createAuthenticatedSagaFetchAction<LoadToDoPayload, EditToDo, SecurityErrorCode>({
    actionGroup: 'operations',
    actionName: 'reloadToDo',
    networkCall: (jwt, selectedLanguage, body) => {
        return getRequest(Route.Operations.LoadToDo(body?.id || ''), {jwt, selectedLanguage});
    }
});


export const loadArchiveToDoActionAndSaga = createAuthenticatedSagaFetchAction<LoadToDoPayload, EditToDo, SecurityErrorCode>({
    actionGroup: 'operations',
    actionName: 'loadArchiveToDo',
    networkCall: (jwt, selectedLanguage, body) => {
        return getRequest(Route.Operations.LoadArchiveToDo(body?.id || ''), {jwt, selectedLanguage});
    }
});

export const reSyncToDoTasks = createAction<number>('operations/reSyncToDoTasks');


export const loadToDoReviewActionAndSaga = createAuthenticatedSagaFetchAction<IdParams, ToDoReviewConfiguration, SecurityErrorCode>({
    actionGroup: 'operations',
    actionName: 'loadToDoReview',
    networkCall: (jwt, selectedLanguage, body) => {
        return getRequest(Route.Operations.LoadToDoReview(body?.id || ''), {jwt, selectedLanguage});
    }
});


export const loadFinishedToDoReviewActionAndSaga = createAuthenticatedSagaFetchAction<IdParams, ToDoReviewConfiguration, SecurityErrorCode>({
    actionGroup: 'operations',
    actionName: 'loadFinishedToDoReview',
    networkCall: (jwt, selectedLanguage, body) => {
        return getRequest(Route.Operations.LoadFinishedToDoReview(body?.id || ''), {jwt, selectedLanguage});
    }
});

export interface SaveToDoReviewRemarkBody {
    reviewId: number;
    toDoId: number;
    remark: {
        id?: number;
        taskId: number;
        remark?: string;
        approved?: boolean;
    };
}

export const saveToDoReviewRemarkAction = createFetchAction<SaveToDoReviewRemarkBody, ReviewToDoRemark, SecurityErrorCode>('operations', 'saveToDoReviewRemark');

export interface FinishToDoReviewPayload {
    id: number;
    toDoId: number;
    deadline?: number;
    timeBasedRestrictedApprovalDate?: number;
}

export const finishToDoReviewActionAndSaga = createAuthenticatedSagaFetchAction<FinishToDoReviewPayload, ReviewToDo, SecurityErrorCode>({
    actionGroup: 'operations',
    actionName: 'finishToDoReview',
    networkCall: (jwt, selectedLanguage, body) => {
        return postRequest(Route.Operations.FinishToDoReview(body!.id), {
            jwt,
            selectedLanguage,
            body: {
                deadline: body!.deadline,
                timeBasedRestrictedApprovalDate: body!.timeBasedRestrictedApprovalDate
            }
        });
    }
});

export interface ChangeToDoDeadlineRequestBody {
    deadline: number;
    ids: number[];
    resetFinishedDate: boolean;
    statePart: SetEditorStatePart;
}

export const changeToDoDeadlineActionAndSaga = createAuthenticatedSagaFetchAction<ChangeToDoDeadlineRequestBody, ToDoDTO[], SecurityErrorCode>({
    actionGroup: 'operations',
    actionName: 'changeToDoDeadline',
    networkCall: (jwt, selectedLanguage, body) => {
        return putRequest(Route.Operations.ChangeDeadline, {
            jwt,
            selectedLanguage,
            body: {
                deadline: body!.deadline,
                ids: body!.ids,
                resetFinishedDate: body!.resetFinishedDate
            }
        });
    },
    successGenerator: [function* (request, response): Generator<any, any, any> {
        yield put(updateToDoDeadlines({
            ...request,
            ids: response.map(it => it.id)
        }))
    }]
});

export interface SaveOperationsReviewerPayload {
    toDoId: number;
    reviewId: number;
    userIds: number[];
    statePart: SetReviewerStatePart;
}

export interface SaveOperationsReviewerSuccessPayload extends SaveOperationsReviewerPayload {
    users: Person[];
}

export const saveOperationsToDoReviewerActionAndSaga = createAuthenticatedSagaFetchAction<SaveOperationsReviewerPayload, Person[], SecurityErrorCode>({
    actionGroup: 'operations',
    actionName: 'saveOperationsToDoReviewer',
    networkCall: (jwt, selectedLanguage, body) => {
        return putRequest(Route.Operations.AddReviewer, {
            jwt,
            selectedLanguage,
            body: {
                reviewId: body!.reviewId,
                userIds: body!.userIds
            }
        });
    },
    successGenerator: [function* (request, response): Generator<any, any, any> {
        yield put(setOperationsSaveReviewerSuccess({
            ...request,
            users: response
        }))
    }]
});

export interface ChangeReviewDeadline {
    id: number;
    toDoId: number;
}

export interface ChangeReviewDeadlineRequestBody {
    reviews: ChangeReviewDeadline[];
    deadline: number;
    statePart: SetReviewerStatePart;
}

export const changeReviewDeadlineActionAndSaga = createAuthenticatedSagaFetchAction<ChangeReviewDeadlineRequestBody, void, SecurityErrorCode>({
    actionGroup: 'operations',
    actionName: 'changeReviewDeadline',
    networkCall: (jwt, selectedLanguage, body) => {
        return putRequest(Route.Operations.ChangeReviewDeadline, {
            jwt,
            selectedLanguage,
            body: {
                deadline: body!.deadline,
                ids: body!.reviews.map(it => it.id)
            }
        });
    },
    successGenerator: [function* (request, response): Generator<any, any, any> {
        yield put(updateReviewDeadlines(request))
    }]
});


export interface ReOpenToDoRequestBody {
    deadline: number;
    ids: number[];
    resetFinishedDate: boolean;
}

export const reOpenToDoActionAndSaga = createAuthenticatedSagaFetchAction<ReOpenToDoRequestBody, ToDoDTO[], SecurityErrorCode>({
    actionGroup: 'operations',
    actionName: 'reOpenToDo',
    networkCall: (jwt, selectedLanguage, body) => {
        return putRequest(Route.Operations.ReOpenToDo, {
            jwt,
            selectedLanguage,
            body: {
                deadline: body!.deadline,
                ids: body!.ids,
                resetFinishedDate: true
            }
        });
    },
    successGenerator: [function* (request, response): Generator<any, any, any> {
        // On success, reload the archive, the subordinated to dos and the finished to dos to display the updated to do
        yield put(loadSubordinatedToDosAction.action({replace: false}));
        yield put(loadOpenToDosAction.action({replace: false}));
    }]
});

export interface ReOpenToDoReviewRequestBody extends IdParams {
    deadline?: number;
}

export interface ReOpenToDoReviewResponse {
    toDoId: number;
    userIsReviewer: boolean;
}

export const reOpenReviewActionAndSaga = createAuthenticatedSagaFetchAction<ReOpenToDoReviewRequestBody, ReOpenToDoReviewResponse, SecurityErrorCode>({
    actionGroup: 'operations',
    actionName: 'reOpenReview',
    networkCall: (jwt, selectedLanguage, body) => {
        return postRequest(Route.Operations.ReOpenToDoReview(body!.id), {
            jwt,
            selectedLanguage,
            body: {
                deadline: body!.deadline
            }
        });
    },
    successGenerator: [function* (request, response): Generator<any, any, any> {
        // On success, reload the archive, the subordinated to dos and the finished to dos to display the updated to do
        if (response.userIsReviewer) {
            yield put(loadUserReviewToDosAction.action({replace: false}));
            // Redirect the user to the review page
            yield put(setRedirect(Routes.OperationsBase(Routes.Operations.Review.Base(Routes.Operations.Review.ToDo(response.toDoId)))));
        } else {
            yield put(loadSubordinatedUserReviewToDosAction.action({replace: false}));
            // Redirect the user to the overview page
            yield put(setRedirect(Routes.OperationsBase('')));
        }
    }]
});

export interface LoadOpenToDosForToDoDefinitionAndLocationRequest {
    locationId: number;
    toDoDefinitionId: number;
}

export const loadOpenToDosForToDoDefinitionAndLocationActionAndSaga = createAuthenticatedSagaFetchAction<LoadOpenToDosForToDoDefinitionAndLocationRequest, ApiListResponse<ToDoDTO>, SecurityErrorCode>({
    actionGroup: 'operations',
    actionName: 'loadOpenToDosForToDoDefinitionAndLocation',
    networkCall: (jwt, selectedLanguage, params) => {
        return getRequest(Route.Operations.LoadOpenToDosForToDoDefinitionAndLocation, {
            jwt,
            selectedLanguage,
            params
        });
    }
});

export interface LoadPreviousToDosForToDoPayload {
    toDoId: number;
}

export const loadPreviousToDosForToDoActionAndSaga = createAuthenticatedSagaFetchAction<LoadPreviousToDosForToDoPayload, ApiListResponse<EditToDo>, SecurityErrorCode>({
    actionGroup: 'operations',
    actionName: 'loadPreviousToDosForToDo',
    networkCall: (jwt, selectedLanguage, params) => {
        return getRequest(Route.Operations.LoadPreviousToDosForToDo(params!.toDoId), {
            jwt,
            selectedLanguage,
        });
    },
    successGenerator: [function* (request, response): Generator<any, any, any> {
        yield put(addPreviousToDosToState({toDoId: request.toDoId, previousToDos: response.elements}));
    }]
});

export interface DeclineToDoReviewPayload {
    toDoId: number;
    reviewId: number;
    reason?: CompiledMessage;
}

export const declineToDoReviewActionAndSaga = createAuthenticatedSagaFetchAction<DeclineToDoReviewPayload, ToDoReview, SecurityErrorCode>({
    actionGroup: 'operations',
    actionName: 'declineToDoReview',
    networkCall: (jwt, selectedLanguage, body) => {
        return postRequest(Route.Operations.DeclineToDoReview, {
            jwt,
            selectedLanguage,
            body: {
                id: body?.reviewId,
                reason: body?.reason
            }
        });
    },
    successGenerator: [function* (request, response): Generator<any, any, any> {
        yield put(declineToDoReviewSuccess({toDoId: request.toDoId, review: response}));
    }]
});

export interface PreFinishToDoResponse {
    results: PreFinishToDoResult[];
}

export const preFinishToDoActionAndSaga = createAuthenticatedSagaFetchAction<IdParams, PreFinishToDoResponse, SecurityErrorCode>({
    actionGroup: 'operations',
    actionName: 'preFinishToDo',
    networkCall: (jwt, selectedLanguage, body) => {
        return postRequest(Route.Operations.PreFinishToDo(body!.id), {
            jwt,
            selectedLanguage
        });
    },
    successGenerator: [function* (request, response): Generator<any, any, any> {
        yield put(setToDoPreFinishSuccess({id: request.id, results: response.results}));
        yield call(handlePreFinishResults, request.id, response.results);
    }]
});


export const finishAutoApproveToDoActionAndSaga = createAuthenticatedSagaFetchAction<IdParams, FinishToDoPayload, SecurityErrorCode>({
    actionGroup: 'operations',
    actionName: 'finishAutoApprove',
    networkCall: (jwt, selectedLanguage, body) => {
        return postRequest(Route.Operations.AutoApproveToDo(body!.id), {
            jwt,
            selectedLanguage
        });
    }
});


export const finishAutoDeclineToDoActionAndSaga = createAuthenticatedSagaFetchAction<IdParams, FinishToDoPayload, SecurityErrorCode>({
    actionGroup: 'operations',
    actionName: 'finishAutoDecline',
    networkCall: (jwt, selectedLanguage, body) => {
        return postRequest(Route.Operations.AutoDeclineToDo(body!.id), {
            jwt,
            selectedLanguage
        });
    }
});