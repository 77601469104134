import {ActionReducerMapBuilder, Draft} from '@reduxjs/toolkit';
import {VideoState} from './types';
import {setVideoReactionAction, videoReactionCreated, VideoReactionMessage, videoReactionRemoved} from './actions';
import {FetchStatus} from '@software/reactcommons';

const updateReactionCount = (state: Draft<VideoState>, action: VideoReactionMessage, increase: boolean) => {
    let videoReaction = state.videos.elements[action.reaction.videoID]?.reactionsPerType[action.reaction.reactionTypeID];
    if (videoReaction) {
        if (action.reaction.user.id !== action.user.info.videoUserID) {
            videoReaction.count = (videoReaction.count || 0) + (increase ? 1 : -1);
        } else if (increase && !videoReaction.users.map(it => it.id).includes(action.reaction.user.id)) {
            videoReaction.count = (videoReaction.count || 0) + 1;
            videoReaction.users.push(action.reaction.user);
        } else if (!increase && videoReaction.users.map(it => it.id).includes(action.reaction.user.id)) {
            videoReaction.count = (videoReaction.count || 0) - 1;
            videoReaction.users = videoReaction.users.filter(it => it.id !== action.reaction.user.id);
        }
    }
}

export const reactionReducers = {}

export const extraReactionReducers = (builder: ActionReducerMapBuilder<VideoState>) =>
    builder.addCase(setVideoReactionAction.startAction, (state, action) => {
        const reactionsPerType = state.videos.elements[action.payload.videoID]?.reactionsPerType[action.payload.reactionTypeID];
        if (reactionsPerType) {
            reactionsPerType.fetchStatus = FetchStatus.Active;
        }
    }).addCase(setVideoReactionAction.successAction, (state, action) => {
        const reactionsPerType = state.videos.elements[action.payload.videoID]?.reactionsPerType[action.payload.reactionTypeID];
        if (reactionsPerType) {
            if (reactionsPerType.fetchStatus === FetchStatus.Active) {
                let increment = action.payload.active ? 1 : -1;
                reactionsPerType.users = reactionsPerType.users || [];
                if (action.payload.active && !reactionsPerType.users.map(it => it.id).includes(action.payload.user.id)) {
                    reactionsPerType.users.push(action.payload.user);
                } else if (!action.payload.active && reactionsPerType.users.map(it => it.id).includes(action.payload.user.id)) {
                    reactionsPerType.users = reactionsPerType.users.filter(it => it.id !== action.payload.user.id);
                } else {
                    // Reaction has already been updated by websocket, do not increase the count
                    increment = 0;
                }
                reactionsPerType.fetchStatus = FetchStatus.Success;
                reactionsPerType.count += increment;
            }
        }
    }).addCase(videoReactionCreated, (state, action) => {
        updateReactionCount(state, action.payload, true);
    }).addCase(videoReactionRemoved, (state, action) => {
        updateReactionCount(state, action.payload, false)
    });