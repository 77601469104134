import {createFetchAction} from '@software/reactcommons';
import {EditedVideo} from './types';

export interface SetEditVideoPropertyActionPayload<Key extends keyof EditedVideo, Value extends EditedVideo[Key]> {
    key: Key;
    value: Value;
}



/***************************************************************
 * Redux action creator
 **************************************************************/

export const saveEditedVideoAction = createFetchAction<{}, undefined, 'UNKNOWN'>('admin', 'saveEditedVideo');

// Custom action creator which correctly types the action dependent on the property of the EditedVideo object
export function setEditVideoProperty<Key extends keyof EditedVideo, Value extends EditedVideo[Key]>(payload: SetEditVideoPropertyActionPayload<Key, Value>) {
    return {
        type: 'admin/setEditVideoProperty',
        payload
    }
}
setEditVideoProperty.type = 'admin/setEditVideoProperty';
