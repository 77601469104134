if (typeof HTMLElement !== 'undefined' && !
    HTMLElement.prototype.insertAdjacentElement) {
    HTMLElement.prototype.insertAdjacentElement = function (where, parsedNode) {
        let element = null;
        switch (where) {
            case 'beforebegin':
                element = this.parentNode?.insertBefore(parsedNode, this) || null
                break;
            case 'afterbegin':
                element = this.insertBefore(parsedNode, this.firstChild);
                break;
            case 'beforeend':
                element = this.appendChild(parsedNode);
                break;
            case 'afterend':
                if (this.nextSibling) {
                    element = this.parentNode?.insertBefore(parsedNode, this.nextSibling) || null;
                } else {
                    element = this.parentNode?.appendChild(parsedNode) || null;
                }
                break;
        }
        return element;
    }

    HTMLElement.prototype.insertAdjacentHTML = function (where, htmlStr) {
        const r = this.ownerDocument.createRange();
        r.setStartBefore(this);
        const parsedHTML = r.createContextualFragment(htmlStr) as any;
        this.insertAdjacentElement(where, parsedHTML)
    }


    HTMLElement.prototype.insertAdjacentText = function (where, txtStr) {
        const parsedText = document.createTextNode(txtStr) as any;
        this.insertAdjacentElement(where, parsedText);
    }
}
export {};