import React, {useEffect, useRef} from 'react';
import {
    addApplicationMessage,
    dismissApplicationMessage,
    SnackbarVariant,
    useLocalization
} from '@software/reactcommons';
import {Messages} from '../../localization/localization';
import {OfflineReason, OnlineState} from '../../redux/reducer/connection/types';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from '../../types/Types';
import {DateTime} from 'luxon';

interface OnlineStateNotificationProps {
    url: string;
}

const OnlineStateNotification: React.FC<OnlineStateNotificationProps> = ({url}) => {

    const dispatch = useDispatch();

    const onlineState = useSelector((state: AppState) => state.connection.online[url]?.state || OnlineState.Online);
    const offlineReason = useSelector((state: AppState) => state.connection.online[url]?.offlineReason);

    const initialOnlineState = useRef(onlineState);
    const messages = useLocalization<Messages>();

    useEffect(() => {
        if (initialOnlineState.current !== onlineState) {
            if (onlineState === OnlineState.Online) {
                // Dismiss the offline message
                dispatch(dismissApplicationMessage('application-offline'));
                // Wait until the offline message is hidden and trigger the online message
                window.setTimeout(() => {
                    dispatch(addApplicationMessage({
                        variant: SnackbarVariant.Success,
                        text: messages.connection.notifications.online,
                        key: `application-online-${DateTime.now().toMillis()}`
                    }));
                }, 500);
            } else {
                dispatch(addApplicationMessage({
                    variant: SnackbarVariant.Error,
                    text: offlineReason === OfflineReason.BackendNotReachable ? messages.connection.notifications.backendOffline : messages.connection.notifications.offline,
                    key: 'application-offline',
                    autoHideDuration: -1
                }));
            }
            initialOnlineState.current = onlineState;
        }
    }, [onlineState, dispatch, messages.connection.notifications, offlineReason]);

    return null;

}

export default OnlineStateNotification;