import {ErrorMessage, FetchStatus, normalize, notUndefined} from '@software/reactcommons';
import {
    ErrorCode,
    ClientLoginMethodType,
    LoginUser,
    ClientLoginMethod,
    Role,
    UserInfoResponse,
    UserState,
    WebAppRoute
} from './types';
import {
    authenticateViaLoginTokenAction,
    getEmailViaLoginTokenActionAndSaga,
    invalidatePasswordLessLoginTokenActionAndSaga,
    loadAccountActionAndSaga,
    loadAvailableLoginMethodsActionAndSaga,
    loadCustomerListActionAndSaga,
    LoadResolvedHierarchyNodeHierarchyActionAndSaga,
    loadPersonAccountActionAndSaga,
    loadSelectedCustomerIdActionAndSaga,
    loadUserAccessActionAndSaga,
    loadUserInfoActionAndSaga,
    requestLoginTokenActionAndSaga,
    requestPasswordMailActionAndSaga,
    resetPasswordActionAndSaga,
    setPasswordViaRegistrationTokenActionAndSaga,
    setSelectedCustomerIdAction,
    UpdatePersonalDataActionPayload,
    validateLoginTokenAction,
    validateRegistrationTokenActionAndSaga,
    validateResetPasswordTokenActionAndSaga,
    LoadHierarchyNodeFeedbackCollectionPointsActionAndSaga, ValidateOIDCLoginActionAndSaga
} from './actions';
import {createUserReducer, Gender, getInitialBaseUserState} from '@software/reactcommons-security';
import {PayloadAction} from '@reduxjs/toolkit';
import {loadDeepLinkActionAndSaga} from '../application/actions';
import {
    HierarchyNode,
    HierarchyNodeWithChildren
} from './hierarchyNodeTypes';

let baseInitialState = getInitialBaseUserState<Role, LoginUser>();

export const initialUserState: UserState = {
    ...baseInitialState,
    isBlocked: false,
    info: {
        ...baseInitialState.info,
        videoUserID: null,
        gender: Gender.MALE,
        phone: null,
        groups: [],
        initials: ''
    },
    lastActivity: 0,
    forcedLogout: false,
    invalidTokenRefreshCount: 0,
    availableSites: [],
    restrictedDomains: [],
    availableCustomers: {},
    selectedCustomerId: -1,
    locations: {},
    locationArray: [],
    regions: {},
    regionArray: [],
    dataSources: {},
    dataSourcesArray: [],
    errors: {},
    userHierarchy: {
        hierarchyNodes: {},
        hierarchies: [],
        hierarchyNodeFeedbackCollectionPoints: {},
        feedbackCollectionPoints: {}
    },
    login: {
        methods: [],
        hasPassword: false
    },
    fetchStatus: {
        ...baseInitialState.fetchStatus,
        legacy: FetchStatus.Default,
        fetchStatus: FetchStatus.Default,
        requestPasswordMail: FetchStatus.Default,
        resetPassword: FetchStatus.Default,
        registration: FetchStatus.Default,
        invalidateResetPasswordToken: FetchStatus.Default,
        // After mounting of the app it is checked if the refresh token can be used to get a jwt, so set the initial state to fetch status active
        refreshToken: FetchStatus.Active,
        update: FetchStatus.Default,
        updatePassword: FetchStatus.Default,
        account: FetchStatus.Default,
        availableSites: FetchStatus.Default,
        loadInfo: FetchStatus.Default,
        checkResetPasswordLink: FetchStatus.Default,
        validateRegistrationToken: FetchStatus.Default,
        customerList: FetchStatus.Default,
        changeCustomer: FetchStatus.Default,
        loadUserAccess: FetchStatus.Default,
        requestLoginToken: FetchStatus.Default,
        validateLoginToken: FetchStatus.Default,
        invalidateLoginToken: FetchStatus.Default,
        authenticateViaLoginToken: FetchStatus.Default,
        loginMethods: FetchStatus.Default,
        loadEmailViaLoginToken: FetchStatus.Default,
        loadPersonAccount: FetchStatus.Default,
        loadHierarchyNodeHierarchy: FetchStatus.Default,
        loadHierarchyNodeFeedbackCollectionPoints: FetchStatus.Default,
        validateOIDCCallback: FetchStatus.Default
    }
};

const flattenHierarchy = (hierarchies: HierarchyNodeWithChildren[]) => {
    return hierarchies.reduce((acc: HierarchyNode[], hierarchy) => {
        acc.push(hierarchy.hierarchyNode);
        if (hierarchy.children) {
            acc.push(...flattenHierarchy(hierarchy.children));
        }
        return acc;
    }, []);
}

const user = createUserReducer({
    name: 'user',
    initialState: initialUserState,
    reducers: {
        fetchUpdatePersonalData: state => {
            state.fetchStatus.update = FetchStatus.Active;
        },
        fetchUpdatePersonalDataSuccess: (state, action: PayloadAction<UpdatePersonalDataActionPayload>) => {
            state.info = {
                ...state.info,
                ...action.payload
            }
            state.fetchStatus.update = FetchStatus.Success;
        },
        fetchUpdatePersonalDataError: state => {
            state.fetchStatus.update = FetchStatus.Error;
        },
        fetchUpdatePassword: state => {
            state.fetchStatus.updatePassword = FetchStatus.Active;
        },
        fetchUpdatePasswordSuccess: state => {
            state.fetchStatus.updatePassword = FetchStatus.Success;
            // User has updated his password, so at least now user has a password
            state.login.hasPassword = true;
        },
        fetchUpdatePasswordError: (state, action: PayloadAction<ErrorMessage | undefined>) => {
            state.passwordUpdateError = action.payload;
            state.fetchStatus.updatePassword = FetchStatus.Error;
        },
        fetchLoadAvailableSites: state => {
            state.fetchStatus.availableSites = FetchStatus.Active;
        },
        fetchLoadAvailableSitesSuccess: (state, action: PayloadAction<WebAppRoute[]>) => {
            state.availableSites = action.payload;
            state.fetchStatus.availableSites = FetchStatus.Success;
        },
        fetchLoadAvailableSitesError: state => {
            state.availableSites = [];
            state.fetchStatus.availableSites = FetchStatus.Error;
        },
        resetAvailableSitesFetchStatus: state => {
            state.fetchStatus.availableSites = FetchStatus.Default;
        },
        setCustomerLogoPath: (state, action: PayloadAction<string>) => {
            state.logoPath = action.payload;
        },
        setUserFullName: (state, action: PayloadAction<string>) => {
            state.fullName = action.payload;
        },
        setPasswordLessValidateTokenError: (state, action: PayloadAction<ErrorCode>) => {
            state.errors.invalidPasswordLessTokenError = action.payload;
        },
        setLoginUsername: (state, action: PayloadAction<string | undefined>) => {
            state.login.username = action.payload;
        },
        resetLoginMethods: (state) => {
            state.login.methods = [];
            state.fetchStatus.loginMethods = FetchStatus.Default;
        },
        setLoginMethods: (state, action: PayloadAction<ClientLoginMethod[]>) => {
            state.login.methods = action.payload;
        },
        setUpdatePasswordError: (state, action: PayloadAction<ErrorMessage | undefined>) => {
            state.passwordResetError = action.payload;
        },
        resetUserState: (state, action: PayloadAction<string | undefined>) => {
            return {
                ...initialUserState,
                login: {
                    ...initialUserState.login,
                    username: action.payload || ''
                }
            };
        },
        setLastActivityTimestamp: (state, action: PayloadAction<number>) => {
            state.lastActivity = action.payload;
        },
        setUserBlocked: (state, action: PayloadAction<boolean>) => {
            state.isBlocked = action.payload;
        }
    },
    extraReducersCallback: builder => builder.addCase(loadAccountActionAndSaga.successAction, (state, action) => {
        const splitFullName = `${action.payload.firstName} ${action.payload.lastName}`.split(' ');
        state.info = {
            ...state.info,
            ...action.payload,
            initials: splitFullName.map((n, i) => {
                if (i === 0 || i === splitFullName.length - 1) {
                    return n[0];
                }
                return undefined;
            }).filter(notUndefined).join('')
        }
    }).addCase(loadUserInfoActionAndSaga.startAction, state => {
        state.fetchStatus.loadInfo = FetchStatus.Active;
    }).addCase(loadUserInfoActionAndSaga.successAction, (state, action: PayloadAction<UserInfoResponse>) => {
        state.fetchStatus.loadInfo = FetchStatus.Success;
        state.info.videoUserID = action.payload.id;
        state.info.groups = action.payload.groups;
    }).addCase(loadUserInfoActionAndSaga.errorAction, state => {
        state.fetchStatus.loadInfo = FetchStatus.Error;
    }).addCase(validateResetPasswordTokenActionAndSaga.startAction, state => {
        state.fetchStatus.checkResetPasswordLink = FetchStatus.Active;
    }).addCase(validateResetPasswordTokenActionAndSaga.successAction, state => {
        state.fetchStatus.checkResetPasswordLink = FetchStatus.Success;
    }).addCase(validateResetPasswordTokenActionAndSaga.errorAction, (state, action) => {
        state.fetchStatus.checkResetPasswordLink = FetchStatus.Error;
        state.errors.invalidPasswordResetTokenError = action.payload.errorCode;
    }).addCase(loadCustomerListActionAndSaga.startAction, state => {
        state.fetchStatus.customerList = FetchStatus.Active;
    }).addCase(loadCustomerListActionAndSaga.errorAction, state => {
        state.fetchStatus.customerList = FetchStatus.Error;
    }).addCase(loadCustomerListActionAndSaga.successAction, (state, action) => {
        state.fetchStatus.customerList = FetchStatus.Success;
        state.availableCustomers = normalize(action.payload);
    }).addCase(setSelectedCustomerIdAction.startAction, (state, action) => {
        state.prepared = false;
        state.logoPath = undefined;
        state.headquarters = undefined;
        state.regions = {};
        state.regionArray = [];
        state.locations = {};
        state.locationArray = [];
        state.dataSources = {};
        state.dataSourcesArray = [];
        state.fetchStatus.changeCustomer = FetchStatus.Active;
    }).addCase(setSelectedCustomerIdAction.errorAction, state => {
        state.fetchStatus.changeCustomer = FetchStatus.Error;
    }).addCase(setSelectedCustomerIdAction.successAction, (state, action) => {
        state.fetchStatus.changeCustomer = FetchStatus.Success;
        state.selectedCustomerId = action.payload.id;
    }).addCase(loadUserAccessActionAndSaga.startAction, state => {
        state.fetchStatus.loadUserAccess = FetchStatus.Active;
    }).addCase(loadUserAccessActionAndSaga.errorAction, state => {
        state.fetchStatus.loadUserAccess = FetchStatus.Error;
    }).addCase(loadUserAccessActionAndSaga.successAction, (state, action) => {
        state.locations = normalize(action.payload.locations);
        state.locationArray = action.payload.locations.sort((t1, t2) => t1.name.localeCompare(t2.name));
        state.regions = normalize(action.payload.regions);
        state.regionArray = action.payload.regions.sort((t1, t2) => t1.name.localeCompare(t2.name));
        state.headquarters = action.payload.headquarters;
        state.dataSources = normalize(action.payload.dataSources);
        state.dataSourcesArray = action.payload.dataSources.sort((t1, t2) => t1.name.localeCompare(t2.name));
        state.fetchStatus.loadUserAccess = FetchStatus.Success;
    }).addCase(loadSelectedCustomerIdActionAndSaga.successAction, (state, action) => {
        state.selectedCustomerId = action.payload.id;
    }).addCase(requestLoginTokenActionAndSaga.startAction, state => {
        state.fetchStatus.requestLoginToken = FetchStatus.Active;
    }).addCase(requestLoginTokenActionAndSaga.errorAction, (state, action) => {
        state.fetchStatus.requestLoginToken = FetchStatus.Error;
        state.errors.passwordLessTokenRequestError = action.payload.errorCode;
    }).addCase(requestLoginTokenActionAndSaga.successAction, (state) => {
        state.fetchStatus.requestLoginToken = FetchStatus.Success;
    }).addCase(requestLoginTokenActionAndSaga.resetAction, (state) => {
        state.fetchStatus.requestLoginToken = FetchStatus.Default;
    }).addCase(validateLoginTokenAction.startAction, state => {
        state.fetchStatus.validateLoginToken = FetchStatus.Active;
    }).addCase(validateLoginTokenAction.errorAction, state => {
        state.fetchStatus.validateLoginToken = FetchStatus.Error;
    }).addCase(validateLoginTokenAction.successAction, state => {
        state.fetchStatus.validateLoginToken = FetchStatus.Success;
    }).addCase(validateLoginTokenAction.resetAction, state => {
        state.fetchStatus.validateLoginToken = FetchStatus.Default;
    }).addCase(loadAvailableLoginMethodsActionAndSaga.startAction, state => {
        state.fetchStatus.loginMethods = FetchStatus.Active;
    }).addCase(loadAvailableLoginMethodsActionAndSaga.errorAction, state => {
        state.fetchStatus.loginMethods = FetchStatus.Error;
        state.login.methods = [{type: ClientLoginMethodType.Password, priority: true}, {
            type: ClientLoginMethodType.LoginToken,
            priority: false
        }];
    }).addCase(loadAvailableLoginMethodsActionAndSaga.successAction, (state, action) => {
        state.fetchStatus.loginMethods = FetchStatus.Success;
        state.login.methods = action.payload.methods;
        state.login.preAuthenticationSecurityRules = action.payload.securityRules;
    }).addCase(loadAvailableLoginMethodsActionAndSaga.resetAction, state => {
        state.fetchStatus.loginMethods = FetchStatus.Default;
    }).addCase(invalidatePasswordLessLoginTokenActionAndSaga.startAction, state => {
        state.fetchStatus.invalidateLoginToken = FetchStatus.Active;
    }).addCase(invalidatePasswordLessLoginTokenActionAndSaga.errorAction, state => {
        state.fetchStatus.invalidateLoginToken = FetchStatus.Error;
    }).addCase(invalidatePasswordLessLoginTokenActionAndSaga.successAction, (state) => {
        state.fetchStatus.invalidateLoginToken = FetchStatus.Success;
    }).addCase(invalidatePasswordLessLoginTokenActionAndSaga.resetAction, state => {
        state.fetchStatus.invalidateLoginToken = FetchStatus.Default;
    }).addCase(loadDeepLinkActionAndSaga.successAction, (state, action) => {
        state.login.username = action.payload.username;
    }).addCase(authenticateViaLoginTokenAction.startAction, state => {
        state.fetchStatus.authenticateViaLoginToken = FetchStatus.Active;
    }).addCase(authenticateViaLoginTokenAction.errorAction, state => {
        state.fetchStatus.authenticateViaLoginToken = FetchStatus.Error;
    }).addCase(authenticateViaLoginTokenAction.successAction, state => {
        state.fetchStatus.authenticateViaLoginToken = FetchStatus.Success;
    }).addCase(authenticateViaLoginTokenAction.resetAction, state => {
        state.fetchStatus.authenticateViaLoginToken = FetchStatus.Default;
    }).addCase(validateRegistrationTokenActionAndSaga.startAction, state => {
        state.fetchStatus.validateLoginToken = FetchStatus.Active;
    }).addCase(validateRegistrationTokenActionAndSaga.errorAction, state => {
        state.fetchStatus.validateLoginToken = FetchStatus.Error;
    }).addCase(validateRegistrationTokenActionAndSaga.successAction, state => {
        state.fetchStatus.validateLoginToken = FetchStatus.Success;
    }).addCase(validateRegistrationTokenActionAndSaga.resetAction, state => {
        state.fetchStatus.validateLoginToken = FetchStatus.Default;
    }).addCase(setPasswordViaRegistrationTokenActionAndSaga.startAction, state => {
        state.fetchStatus.registration = FetchStatus.Active;
    }).addCase(setPasswordViaRegistrationTokenActionAndSaga.errorAction, state => {
        state.fetchStatus.registration = FetchStatus.Error;
    }).addCase(setPasswordViaRegistrationTokenActionAndSaga.successAction, state => {
        state.fetchStatus.registration = FetchStatus.Success;
    }).addCase(setPasswordViaRegistrationTokenActionAndSaga.resetAction, state => {
        state.fetchStatus.registration = FetchStatus.Default;
    }).addCase(resetPasswordActionAndSaga.startAction, state => {
        state.fetchStatus.resetPassword = FetchStatus.Active;
    }).addCase(resetPasswordActionAndSaga.successAction, state => {
        state.fetchStatus.resetPassword = FetchStatus.Success;
        state.passwordResetError = undefined;
    }).addCase(resetPasswordActionAndSaga.errorAction, state => {
        state.fetchStatus.resetPassword = FetchStatus.Error;
    }).addCase(resetPasswordActionAndSaga.resetAction, state => {
        state.fetchStatus.resetPassword = FetchStatus.Default;
    }).addCase(requestPasswordMailActionAndSaga.startAction, state => {
        state.fetchStatus.requestPasswordMail = FetchStatus.Active;
    }).addCase(requestPasswordMailActionAndSaga.successAction, state => {
        state.fetchStatus.requestPasswordMail = FetchStatus.Success;
        state.passwordResetError = undefined;
    }).addCase(requestPasswordMailActionAndSaga.errorAction, state => {
        state.fetchStatus.requestPasswordMail = FetchStatus.Error;
    }).addCase(requestPasswordMailActionAndSaga.resetAction, state => {
        state.fetchStatus.requestPasswordMail = FetchStatus.Default;
    }).addCase(getEmailViaLoginTokenActionAndSaga.startAction, state => {
        state.fetchStatus.loadEmailViaLoginToken = FetchStatus.Active;
    }).addCase(getEmailViaLoginTokenActionAndSaga.successAction, (state, action) => {
        state.fetchStatus.loadEmailViaLoginToken = FetchStatus.Success;
        state.login.username = action.payload.email;
    }).addCase(getEmailViaLoginTokenActionAndSaga.errorAction, state => {
        state.fetchStatus.loadEmailViaLoginToken = FetchStatus.Error;
    }).addCase(getEmailViaLoginTokenActionAndSaga.resetAction, state => {
        state.fetchStatus.loadEmailViaLoginToken = FetchStatus.Default;
    }).addCase(loadPersonAccountActionAndSaga.startAction, state => {
        state.fetchStatus.loadPersonAccount = FetchStatus.Active;
    }).addCase(loadPersonAccountActionAndSaga.successAction, (state, action) => {
        state.fetchStatus.loadPersonAccount = FetchStatus.Success;
        state.login.hasPassword = action.payload.hasPassword;
        state.login.methods = action.payload.loginMethods.map(it => ({
            type: it.loginMethod.type,
            priority: it.priority
        }));
    }).addCase(loadPersonAccountActionAndSaga.errorAction, state => {
        state.fetchStatus.loadPersonAccount = FetchStatus.Error;
    }).addCase(loadPersonAccountActionAndSaga.resetAction, state => {
        state.fetchStatus.loadPersonAccount = FetchStatus.Default;
    }).addCase(LoadResolvedHierarchyNodeHierarchyActionAndSaga.startAction, state => {
        state.fetchStatus.loadHierarchyNodeHierarchy = FetchStatus.Active;
    }).addCase(LoadResolvedHierarchyNodeHierarchyActionAndSaga.successAction, (state, action) => {
        state.fetchStatus.loadHierarchyNodeHierarchy = FetchStatus.Success;
        state.userHierarchy.hierarchies = action.payload.hierarchies;
        state.userHierarchy.hierarchyNodes = normalize(flattenHierarchy(action.payload.hierarchies));
    }).addCase(LoadResolvedHierarchyNodeHierarchyActionAndSaga.errorAction, state => {
        state.fetchStatus.loadHierarchyNodeHierarchy = FetchStatus.Error;
    }).addCase(LoadResolvedHierarchyNodeHierarchyActionAndSaga.resetAction, state => {
        state.fetchStatus.loadHierarchyNodeHierarchy = FetchStatus.Default;
    }).addCase(LoadHierarchyNodeFeedbackCollectionPointsActionAndSaga.startAction, state => {
        state.fetchStatus.loadHierarchyNodeFeedbackCollectionPoints = FetchStatus.Active;
    }).addCase(LoadHierarchyNodeFeedbackCollectionPointsActionAndSaga.successAction, (state, action) => {
        state.fetchStatus.loadHierarchyNodeFeedbackCollectionPoints = FetchStatus.Success;
        state.userHierarchy.feedbackCollectionPoints = {
            ...state.userHierarchy.hierarchyNodeFeedbackCollectionPoints,
            ...normalize(action.payload.feedbackCollectionPoints, undefined, 'feedbackCollectionPointOpaqueId')
        };
        action.payload.feedbackCollectionPoints.forEach(it => {
            // Sort feedback collection points by their hierarchy node in the state
            if (!state.userHierarchy.hierarchyNodeFeedbackCollectionPoints[it.hierarchyNodeId]) {
                state.userHierarchy.hierarchyNodeFeedbackCollectionPoints[it.hierarchyNodeId] = [];
            }
            if (!state.userHierarchy.hierarchyNodeFeedbackCollectionPoints[it.hierarchyNodeId]!.includes(it.feedbackCollectionPointOpaqueId)) {
                state.userHierarchy.hierarchyNodeFeedbackCollectionPoints[it.hierarchyNodeId]!.push(it.feedbackCollectionPointOpaqueId);
            }
        })
    }).addCase(LoadHierarchyNodeFeedbackCollectionPointsActionAndSaga.errorAction, state => {
        state.fetchStatus.loadHierarchyNodeFeedbackCollectionPoints = FetchStatus.Error;
    }).addCase(LoadHierarchyNodeFeedbackCollectionPointsActionAndSaga.resetAction, state => {
        state.fetchStatus.loadHierarchyNodeFeedbackCollectionPoints = FetchStatus.Default;
    }).addCase(ValidateOIDCLoginActionAndSaga.startAction, state => {
        state.fetchStatus.validateOIDCCallback = FetchStatus.Active;
    }).addCase(ValidateOIDCLoginActionAndSaga.successAction, (state) => {
        state.fetchStatus.validateOIDCCallback = FetchStatus.Success;
    }).addCase(ValidateOIDCLoginActionAndSaga.errorAction, state => {
        state.fetchStatus.validateOIDCCallback = FetchStatus.Error;
    }).addCase(ValidateOIDCLoginActionAndSaga.resetAction, state => {
        state.fetchStatus.validateOIDCCallback = FetchStatus.Default;
    })
});

export const {
    fetchLoadAvailableSites,
    fetchLoadAvailableSitesError,
    fetchLoadAvailableSitesSuccess,
    fetchUpdatePassword,
    fetchUpdatePasswordError,
    fetchUpdatePasswordSuccess,
    fetchUpdatePersonalData,
    fetchUpdatePersonalDataError,
    fetchUpdatePersonalDataSuccess,
    setCustomerLogoPath,
    setUserFullName,
    setPasswordLessValidateTokenError,
    setLoginUsername,
    resetLoginMethods,
    setLoginMethods,
    setUpdatePasswordError,
    resetUserState,
    setLastActivityTimestamp,
    setUserBlocked,
    resetAvailableSitesFetchStatus
} = user.actions;

export default user.reducer;