import {BaseErrorCodes, ErrorMessage, FetchStatus} from '@software/reactcommons';
import {BaseUserState, ClientPreAuthenticationSecurityRule, Gender, User} from '@software/reactcommons-security';
import {HierarchyNode, HierarchyNodeWithChildren} from './hierarchyNodeTypes';
import {CompiledMessage} from '@software/reactcommons/dist/localization/message/message.types';

export enum Role {
    VideoUser = 'VIDEO_USER',
    VideoCreator = 'VIDEO_CREATOR',
    VideoAdmin = 'VIDEO_ADMIN',
    QualitizeAdmin = 'Q_ADMIN',
    VideoPlaylistCreator = 'VIDEO_PLAYLIST_CREATOR',
    Operations = 'legacy_qop.INTRA',
    Dashboard2_0 = 'legacy_qop.DASHBOARD_2_0',
    OperationsDashboard = 'legacy_qop.OPERATIONS_DASHBOARD',
    OpeningHours = 'legacy_qop.MANAGE_OPENING_HOURS',
    LegacyStickies = 'legacy_qop.MANAGE_POSTITS',
    LegacyDepartments = 'legacy_qop.MANAGE_DEPARTMENTS',
    Terminals = 'QO_TERMINALS',
    TerminalsOnlineStatus = 'QO_TERMINALS_ONLINE_STATUS',
    TerminalsPinCode = 'QO_TERMINALS_PIN_CODE',
    TerminalsReportIncident = 'QO_TERMINALS_REPORT_INCIDENT',
    TerminalsConnectionMethod = 'QO_TERMINALS_CONNECTION_METHOD',
    FeedbackReportArchive = 'legacy_qop.ARCHIVE',
    CreateManualOperationsToDo = 'QOS_CREATE_MANUAL_OPERATIONS_TO_DOS',
    ReviewOperationsToDos = 'QOS_REVIEW_OPERATIONS_TO_DOS',
    AddOperationsToDoEditor = 'QOS_ADD_OPERATIONS_TO_DO_EDITOR',
    ChangeOperationsToDoDeadline = 'QOS_OPERATIONS_CHANGE_TO_DO_DEADLINE',
    AddOperationsToDoReviewer = 'QOS_ADD_OPERATIONS_TO_DO_REVIEWER',
    ChangeOperationsReviewDeadline = 'QOS_OPERATIONS_CHANGE_REVIEW_DEADLINE',
    ReOpenOperationsToDo = 'QOS_OPERATIONS_RE_OPEN_TO_DO',
    OperationsExportReports = 'QOS_OPERATIONS_EXPORT_REPORTS',
    LandingPageRead = 'LPS_LANDING_PAGES_READ',
    LandingPageWrite = 'LPS_LANDING_PAGES_WRITE',
    AccountAdmin = 'AS_ACCOUNT_ADMIN'
}


export interface UserGroupType {
    id: number;
    name: string;
}

export interface UserGroup {
    id: number;
    type: UserGroupType;
    name: string;
}

export interface LoginUser extends User<Role> {
    customer: number;
    groups: UserGroup[];
    videoUserID: number | null;
    initials: string;
}

export interface WebAppSubRoute {
    route: string;
    name?: string;
}

export interface WebAppRoute {
    route: string;
    subRoutes: WebAppSubRoute[];
}

export interface Customer {
    id: number;
    name: string;
}

export interface Location {
    id: number;
    name: string;
    active: boolean;
    hasDataSources: boolean;
    regionIds: number[];
}

export interface Region {
    id: number;
    name: string;
    hasDataSources: boolean;
}

export interface Headquarters {
    id: number;
    name: string;
}

export interface UserAccessDataSource {
    id: number;
    name: string;
    locationId: number;
}

export enum LoginTokenErrorCode {
    BrowserTokenMissing = 'UNAUTHORIZED_BROWSER_TOKEN_MISSING',
    TooManyTokensActive = 'BAD_REQUEST_TOO_MANY_TOKENS_ACTIVE',
    CredentialsExpired = 'UNAUTHORIZED_CREDENTIALS_EXPIRED',
    AlreadyTokenActive = 'BAD_REQUEST_WARNING_ALREADY_TOKEN_ACTIVE'
}

export type ErrorCode =
    LoginTokenErrorCode.TooManyTokensActive
    | LoginTokenErrorCode.BrowserTokenMissing
    | LoginTokenErrorCode.CredentialsExpired
    | LoginTokenErrorCode.AlreadyTokenActive
    | 'UNKNOWN';

export const ErrorCodes = {
    ...BaseErrorCodes,
    BAD_REQUEST_TOO_MANY_TOKENS_ACTIVE: 'BAD_REQUEST_TOO_MANY_TOKENS_ACTIVE',
    UNAUTHORIZED_BROWSER_TOKEN_MISSING: 'UNAUTHORIZED_BROWSER_TOKEN_MISSING',
    UNAUTHORIZED_CREDENTIALS_EXPIRED: 'UNAUTHORIZED_CREDENTIALS_EXPIRED',
    UNAUTHORIZED_ACCOUNT_DISABLED_TOO_MANY_FAILED_LOGIN_ATTEMPTS : 'UNAUTHORIZED_ACCOUNT_DISABLED_TOO_MANY_FAILED_LOGIN_ATTEMPTS'
}

export interface HierarchyFeedbackCollectionPoint {
    feedbackCollectionPointOpaqueId: string;
    name: string;
    hierarchyNodeId: number;
}

export interface UserState extends BaseUserState<Role, LoginUser> {
    isBlocked: boolean;
    invalidTokenRefreshCount: number;
    passwordUpdateError?: ErrorMessage;
    passwordResetError?: ErrorMessage;
    availableSites: WebAppRoute[];
    restrictedDomains: string[];
    availableCustomers: Record<number, Customer>;
    locations: Record<number, Location>;
    locationArray: Location[];
    regions: Record<number, Region>;
    regionArray: Region[];
    headquarters?: Headquarters;
    dataSources: Record<number, UserAccessDataSource | undefined>;
    dataSourcesArray: UserAccessDataSource[];
    selectedCustomerId: number;
    logoPath?: string;
    fullName?: string;
    lastActivity: number;
    userHierarchy: {
        hierarchies: HierarchyNodeWithChildren[];
        hierarchyNodes: Record<number, HierarchyNode | undefined>;
        hierarchyNodeFeedbackCollectionPoints: Record<number, string[]>;
        feedbackCollectionPoints: Record<string, HierarchyFeedbackCollectionPoint | undefined>;
    };
    login: {
        methods: ClientLoginMethod[];
        preAuthenticationSecurityRules?: ClientPreAuthenticationSecurityRule[];
        username?: string;
        hasPassword: boolean;
    };
    errors: {
        passwordLessTokenRequestError?: ErrorCode;
        invalidPasswordLessTokenError?: ErrorCode;
        invalidPasswordResetTokenError?: ErrorCode;
    };
    fetchStatus: {
        jwt: FetchStatus;
        legacy: FetchStatus;
        refreshToken: FetchStatus;
        logout: FetchStatus;
        fetchStatus: FetchStatus;
        requestPasswordMail: FetchStatus;
        resetPassword: FetchStatus;
        registration: FetchStatus;
        invalidateResetPasswordToken: FetchStatus;
        update: FetchStatus;
        updatePassword: FetchStatus;
        account: FetchStatus;
        availableSites: FetchStatus;
        loadInfo: FetchStatus;
        checkResetPasswordLink: FetchStatus;
        validateRegistrationToken: FetchStatus;
        customerList: FetchStatus;
        changeCustomer: FetchStatus;
        loadUserAccess: FetchStatus;
        requestLoginToken: FetchStatus;
        validateLoginToken: FetchStatus;
        invalidateLoginToken: FetchStatus;
        authenticateViaLoginToken: FetchStatus;
        loginMethods: FetchStatus;
        loadEmailViaLoginToken: FetchStatus;
        loadPersonAccount: FetchStatus;
        loadHierarchyNodeHierarchy: FetchStatus;
        loadHierarchyNodeFeedbackCollectionPoints: FetchStatus;
        validateOIDCCallback: FetchStatus;
    };
}


/********************************************************************************
 * Responses
 ********************************************************************************/

export enum HttpErrorResponse {
    UNAUTHORIZED_JWT_INVALID = 'UNAUTHORIZED_JWT_INVALID',
    UNAUTHORIZED_JWT_EXPIRED = 'UNAUTHORIZED_JWT_EXPIRED',
    UNAUTHORIZED_REFRESH_TOKEN_EXPIRED = 'UNAUTHORIZED_REFRESH_TOKEN_EXPIRED',
    UNKNOWN = 'UNKNOWN',
}

export interface LegacyAuthenticationResponse {
    redirect: string;
}

export interface ErrorResponse {
    message: string;
    errorCode?: HttpErrorResponse;
}

export interface AccountResponse {
    email: string;
    firstName: string;
    lastName: string;
    gender: Gender;
    phone: string;
    restrictedDomains: string[];
}

export interface AvailableSitesResponse {
    showCustomerSelect: boolean;
    pages: WebAppRoute[];
    fullName: string;
    redirect: string;
    customerLogoPath?: string;
}

export interface UserInfoResponse {
    id: number;
    firstName: string | null;
    lastName: string | null;
    groups: UserGroup[];
}


export enum ClientLoginMethodType {
    Password = 'Password',
    LoginToken = 'LoginToken',
    OpenIdConnectAuthorizationCodeFlow = 'OpenIdConnectAuthorizationCodeFlow',
}

export interface ClientLoginMethod {
    type: ClientLoginMethodType;
    priority: boolean;
}

export interface PasswordLoginMethod extends ClientLoginMethod {
    type: ClientLoginMethodType.Password;
}

export interface LoginTokenLoginMethod extends ClientLoginMethod {
    type: ClientLoginMethodType.LoginToken;
}

export enum LoginButtonType {
    TextButton = 'TextButton'
}

export interface LoginButton {
    type: LoginButtonType;
}

export interface LoginTextButton extends LoginButton {
    type: LoginButtonType.TextButton;
    text: CompiledMessage;
}

export interface OpenIDConnectLoginMethod extends ClientLoginMethod {
    type: ClientLoginMethodType.OpenIdConnectAuthorizationCodeFlow;
    authorizationEndpointUrl: string;
    loginButton: LoginButton;
    redirectInstantly: boolean;
    labelText?: CompiledMessage;
    explanationText?: CompiledMessage;
}

/********************************************************************************
 * Errors
 ********************************************************************************/

export interface UpdateResetPasswordError {
    message: string;
}

export enum OIDCLoginError {
    InvalidRequest = 'invalid_request',
    UnauthorizedClient = 'unauthorized_client',
    AccessDenied = 'access_denied',
    UnsupportedResponseType = 'unsupported_response_type',
    InvalidScope = 'invalid_scope',
    ServerError = 'server_error',
    TemporarilyUnavailable = 'temporarily_unavailable'
}